import { Link } from "react-router-dom";
import Login_Form from "../components/Forms/Formulaires/LoginForm";
import { Icon } from "../components/Icons/Icon";
import appico from "../assets/hopteo_manager.webp";

const Login = () => {
	return (
		<div className="home-page page-container loginpage auth-page">
			<img src={appico} className={"logo-container"} />
			<div className="left home">
				<main>
					<Link to={"/"}>
						<Icon size={30} name={"arrow-left"} color={"var(--orange)"} />
						Retour à l'accueil
					</Link>

					<h1 className="bigtitle">Connectez-vous.</h1>
					<p>Commencer à utiliser vos avantages dès maintenant !</p>
				</main>
			</div>
			<div className="form-container">
				<Login_Form />
			</div>
		</div>
	);
};

export default Login;

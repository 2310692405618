import { StructureInRow } from "../../Client/Structure/StructureInRow";
import { TrainingInRow } from "../../Client/Trainings/TrainingInRow";
import adminService from "../../../services/admin/adminService";
import { CircularLoader } from "../../LoadingComponents/CircularLoader";

export const Structure = ({ selectedStructure }) => {
	const {
		data: structure,
		isLoading,
		error,
		refetch
	} = adminService.useFetchStructureDetails(selectedStructure);

	if (isLoading) {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
				<CircularLoader width={50} height={50} />
			</div>
		);
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<div className={"etablissement structure"} style={{ marginTop: 40, maxWidth: 800 }}>
			<StructureInRow structure={structure} trainings={structure?.trainings || []} />

			<div style={{ marginTop: 30 }}>
				<h2>Pages de formations de la structure :</h2>

				<div className={"trainings-container"}>
					{structure?.trainings?.map((training, index) => {
						return (
							<TrainingInRow
								training={training}
								index={index}
								key={training.trainingId}
								refetch={refetch}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

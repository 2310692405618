import { DefaultRoutes } from "./routes/DefaultRoutes";
import { LoggedRoutes } from "./routes/LoggedRoutes";
import { useSelector } from "react-redux";
import Header from "./components/navigation/app/Header";
import NavBar from "./components/navigation/app/navbar";
import trainingsService from "./services/trainings/trainingsService";
import LoadingPage from "./components/LoadingComponents/loadingpage/loadingPage";

export const Wrapper = ({ appRef }) => {
	const logged = useSelector(state => state.client.logged);
	const loaded = useSelector(state => state.client.loaded);

	const { isLoading } = trainingsService.useFetch(logged);

	if (!loaded || isLoading) {
		return <LoadingPage />;
	}

	return (
		<>
			{loaded && !logged ? (
				<DefaultRoutes />
			) : (
				<div className="app-container">
					<Header />
					<NavBar />
					<div className="app-page" ref={appRef}>
						<LoggedRoutes />
					</div>
				</div>
			)}
		</>
	);
};

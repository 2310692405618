import { useDispatch, useSelector } from "react-redux";
import adminService from "../../services/admin/adminService"; // Ton service avec useFetch
import { Table } from "../Table/Table";
import { adminMethods } from "../../models/admin";
import { useState } from "react";
import { getColumnKeys, getColumnValues } from "../Leads/TableDecorator";
import { NewClientForm } from "./Create/NewClientForm";
import { Structure } from "./Structure/Structure";
import { RemoveTreated } from "./Treated/RemoveTreated/RemoveTreated";
import { CircularLoader } from "../LoadingComponents/CircularLoader";

export const TableAdminDecorator = () => {
	const dispatch = useDispatch();
	const selectedType = useSelector(state => state.admin.clients.selected);
	const data = useSelector(state => state.admin.clients[selectedType]);
	const filters = useSelector(state => state.admin.clients.filters);
	const columnKeys = getColumnKeys(data?.columns);
	const columnValues = getColumnValues(data?.columns);

	const treatedList = useSelector(state => state.admin.clients.treatedList);

	// Hook pour récupérer les données paginées avec une liste infinie
	const {
		data: queryData,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage
	} = adminService.useFetch(selectedType); // Type de données sélectionné (clients, avis, leads, etc.)

	// Fonction pour sélectionner un sous-filtre
	const onSelect = filter => {
		dispatch(adminMethods.setSelectedSubFilter(filter));
	};

	// Génération des filtres formatés avec les totaux
	const formattedFilters = () => {
		return filters.map(filter => {
			const totalLabel =
				filter.key === "actives"
					? queryData?.pages[0]?.totalActives || 0 // Utilisation du state local pour totalActives
					: queryData?.pages[0]?.totalPendings || 0; // Utilisation du state local pour totalPendings
			return {
				key: filter.key,
				value: filter.value + ` (${totalLabel})`
			};
		});
	};

	// Gestion de l'affichage du formulaire de création de client
	const [displayForm, setDisplayForm] = useState(false);

	// Fonction pour prétraiter les lignes de données
	const preprocessRows = rows => {
		return rows.map(row => {
			const newRow = { ...row };
			columnKeys.forEach(key => {
				if (key.includes("request.phone")) {
					newRow["request.phone"] = newRow.request ? newRow.request.phone : "";
				}
				if (key.includes("request.fileId")) {
					newRow["request.fileId"] = newRow.request ? newRow.request.fileId : "";
				}
				if (key.includes("createdAt")) {
					newRow["createdAt"] = new Date(newRow.createdAt).toLocaleDateString();
				}
			});
			return newRow;
		});
	};

	// Sélection de la structure
	const selectedStructure = useSelector(state => state.admin.clients.selectedStructure);

	// Gestion du clic sur une ligne pour sélectionner une structure
	const handleRowClick = row => {
		let structure = row?.structureId;
		if (!structure) return;

		dispatch(adminMethods.setSelectedStructure(structure));
	};

	// Total affiché en fonction du type sélectionné
	const total =
		selectedType === "actives"
			? queryData?.pages[0]?.totalActives || 0
			: queryData?.pages[0]?.totalPendings || 0;

	return (
		<>
			{!selectedStructure ? (
				<>
					<div>
						<div className={"row"} style={{ marginBottom: 20 }}>
							<button onClick={() => setDisplayForm(!displayForm)}>
								{!displayForm ? "+ Créer un compte client" : "Annuler"}
							</button>
							{treatedList.length > 0 && <RemoveTreated />}
						</div>

						{displayForm && <NewClientForm />}

						<Table
							columns={columnValues}
							// Combine toutes les pages de données récupérées
							rows={preprocessRows(queryData?.pages.flatMap(page => page.clients) ?? [])}
							total={total} // Affichage du total depuis le state local
							loading={isLoading}
							filters={formattedFilters()}
							onSelect={onSelect}
							rowsFormat={columnKeys}
							selected={selectedType}
							onRowClick={handleRowClick}
						/>

						{/* Bouton pour charger plus de données */}
						{hasNextPage && (
							<button
								className={"btntab"}
								onClick={fetchNextPage}
								disabled={isFetchingNextPage}>
								{isFetchingNextPage ? <CircularLoader /> : "Voir plus"}
							</button>
						)}
					</div>
				</>
			) : (
				<>
					<button onClick={() => dispatch(adminMethods.setSelectedStructure(undefined))}>
						{"<-"} Retour
					</button>
					<Structure selectedStructure={selectedStructure} />
				</>
			)}
		</>
	);
};

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./style.css";
import clientService from "../../../services/client/clientService";
import { GraphLoader } from "../../LoadingComponents/GraphLoader";

// Définir le schéma de validation avec Yup
const schema = yup
	.object({
		oldPassword: yup.string().required("Vous devez entrer votre mot de passe actuel."),
		new_password: yup
			.string()
			.required("Vous devez entrer un nouveau mot de passe.")
			.min(8, "Le mot de passe doit contenir au moins 8 caractères."),
		new_password_retype: yup
			.string()
			.oneOf([yup.ref("new_password"), null], "Les mots de passe ne correspondent pas.")
	})
	.required();

export const PasswordField = ({ value }) => {
	const [edit, setEdit] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, errors }
	} = useForm({
		resolver: yupResolver(schema) // Utilisez Yup pour la validation
	});

	const service = clientService.useChangePassword();

	const onSubmit = async data => {
		try {
			await service.mutateAsync(data);
			reset(); // Réinitialiser le formulaire aux valeurs initiales
			setEdit(false);
		} catch (e) {
			console.log(e);
		}
	};

	const handleEditClick = event => {
		event.preventDefault();
		setEdit(!edit);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="params-field hover"
			style={{ position: "relative" }}>
			{service.isPending && <GraphLoader />}
			<h3>{edit ? "Changer votre mot de passe" : "Connexion"}</h3>

			{edit && (
				<div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 20 }}>
					<p style={{ margin: "10px 0" }}>
						Votre mot de passe doit contenir au moins 8 caractères, 1 lettre minuscule, 1 lettre
						majuscule et 1 chiffre.
					</p>
					<div className="field">
						<label htmlFor="oldPassword">Mot de passe actuel</label>
						<input type="password" {...register("oldPassword")} id="oldPassword" />
						{errors.oldPassword?.message && (
							<p className="error-message">{errors.oldPassword.message}</p>
						)}
					</div>
					<div className="field">
						<label htmlFor="new_password">Nouveau mot de passe</label>
						<input type="password" {...register("new_password")} id="new_password" />
						{errors.new_password?.message && (
							<p className="error-message">{errors.new_password.message}</p>
						)}
					</div>
					<div className="field">
						<label htmlFor="new_password_retype">Confirmer le nouveau mot de passe</label>
						<input
							type="password"
							{...register("new_password_retype")}
							id="new_password_retype"
						/>
						{errors.new_password_retype?.message && (
							<p className="error-message">{errors.new_password_retype.message}</p>
						)}
					</div>
					{service.error && (
						<p className="error" style={{ position: "relative", margin: "auto" }}>
							{service.error.message}
						</p>
					)}
				</div>
			)}
			<div className="row" style={{ width: "100%", flexWrap: "nowrap", gap: 20 }}>
				{!edit && <p>{value}</p>}
				{edit ? (
					<div className="row2" style={{ marginTop: 10, marginLeft: "auto" }}>
						<button type="button" onClick={handleEditClick}>
							Annuler
						</button>
						<button type="submit" disabled={service.isPending}>
							Enregistrer
						</button>
					</div>
				) : (
					<button
						type="button"
						onClick={handleEditClick}
						style={{
							width: "fit-content"
						}}>
						Changer le mot de passe
					</button>
				)}
			</div>
		</form>
	);
};

export const Bug = () => {
	return (
		<>
			<h1>Vous avez rencontré un problème ?</h1>
			<p style={{ marginTop: 10 }}>
				Remplissez ce formulaire si vous avez rencontré un bug ou si vous avez une suggestion
				d'amélioration, merci pour vos précieux retours !
			</p>
			<a
				style={{
					background: "var(--orange)",
					color: "white",
					marginTop: 10,
					textDecoration: "none",
					display: "block",
					width: "fit-content"
				}}
				className={"hover"}
				target={"__blank"}
				href={
					"https://docs.google.com/forms/d/e/1FAIpQLSdei4RQOyxSbY_m12vQ4_kLKx0Betr4wHu7bNbjChbdjvU7Xg/viewform?usp=sf_link"
				}>
				Remplir le formulaire
			</a>
		</>
	);
};

import { processPieChartData } from "../Visites/utils";
import moment from "moment";

export const processStrengthData = (aggregatedData, selectedTraining = null) => {
	// Vérifie si une formation spécifique est sélectionnée et qu'elle existe dans les données
	if (selectedTraining && !aggregatedData[selectedTraining]) {
		return { labels: [], data: [], tableRows: [], totalLikes: 0 };
	}

	// Accéder aux données pour la formation sélectionnée ou par défaut à la première formation si aucune n'est sélectionnée
	const trainingData = selectedTraining
		? aggregatedData[selectedTraining]
		: Object.values(aggregatedData)[0];

	let categoryData = {};

	if (!trainingData) return { labels: [], data: [], tableRows: [], totalLikes: 0 };

	// Parcourt les catégories et les mots-clés pour accumuler les données
	Object.keys(trainingData)?.forEach(category => {
		if (!trainingData[category]) return;
		Object.keys(trainingData[category])?.forEach(keyword => {
			const key = `${category} - ${keyword}`;
			const cardData = trainingData[category][keyword];

			const totalForKeyword = cardData.likes + cardData.dont_know + cardData.super_likes+  cardData.dislikes;

			if (!categoryData[key]) {
				categoryData[key] = {
					totalInteractions: 0,
					likes: 0,
					super_likes : 0,
					category,
					keyword
				};
			}

			// Ajouter les interactions pour ce mot-clé
			categoryData[key].totalInteractions += totalForKeyword;
			categoryData[key].likes +=  (cardData.super_likes + cardData.likes) ;
			categoryData[key].super_likes +=  cardData.super_likes ;

		});
	});

	// Préparer les labels et les données pour le graphique
	const labels = [];
	const data = [];
	const tableRows = [];

	// Trier les données par nombre d'interactions pour la visualisation
	const sortedData = Object.entries(categoryData).sort((a, b) => {
		const rateA = (a[1].likes / a[1].totalInteractions) || 0;
		const rateB = (b[1].likes / b[1].totalInteractions) || 0;
		return rateB - rateA;
	});

	sortedData.forEach(([_, detail]) => {
		const percentage = (detail.likes / detail.totalInteractions * 100).toFixed(2);
		labels.push(`${detail.category} - ${detail.keyword} - ${detail.likes} - ${detail.totalInteractions} - ${percentage}%`);
		data.push(percentage);

		tableRows.push({
			keyword: detail.keyword,
			category: detail.category,
			likes: detail.likes,
			super_likes: detail.super_likes,
			totalInteractions: detail.totalInteractions,
			percentage: `${percentage}%`
		});
	});


	return {
		labels,
		data,
		tableRows,
	};
};

// Function to apply selected filters to the rows
const applyFiltersToRows = (rows, filters) => {
	const selectedRegions = filters.region.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedFilieres = filters.filiere.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLocalisations = filters.localisation.options
		.filter(option => option.selected)
		.map(option => option.label);

	return rows.filter(row => {
		const region = row.dimensionValues[0].value;
		const filiere = row.dimensionValues[1].value;
		const localisation = row.dimensionValues[2].value;

		const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(region);
		const matchesFiliere = selectedFilieres.length === 0 || selectedFilieres.includes(filiere);
		const matchesLocalisation =
			selectedLocalisations.length === 0 || selectedLocalisations.includes(localisation);


		return matchesRegion && matchesFiliere && matchesLocalisation ;
	});
};


export const aggregateDataByKeywordsAndTraining = (rows, cardsByTrainings, filters) => {
	const aggregatedData = {};
	const filteredRows = applyFiltersToRows(rows, filters);

	if (filteredRows.length === 0 || !filteredRows) return undefined;

	const selectedCategories = filters.categories.options
		.filter(option => option.selected)
		.map(option => option.label);
	// Parse each row and construct the data by trainingId and keyword
	filteredRows.forEach(row => {
		// Extract cardId and likes from the row
		const cardId = row.dimensionValues[5].value; // Assuming cardId is always at index 5
		const likes = parseInt(row.metricValues[0].value); // Assuming likes is always the first metric
		const swipeType = row.dimensionValues[4].value;
		// Iterate over each trainingId in cardsByTrainings to check if the current cardId exists within it
		Object.keys(cardsByTrainings).forEach(trainingId => {
			const trainingCards = cardsByTrainings[trainingId];

			if (trainingCards.hasOwnProperty(cardId)) {
				const metadata = trainingCards[cardId].metadata;

				if (metadata) {
					const { cleanSubtopic, cleanMatchList } = metadata;

					if (selectedCategories.length > 0 && !selectedCategories.includes(cleanSubtopic)) return;
					// Initialize the structure for a trainingId if it doesn't already exist
					if (!aggregatedData[trainingId]) {
						aggregatedData[trainingId] = {};
					}

					// Initialize the dictionary for a keyword if it doesn't already exist for this trainingId
					if (!aggregatedData[trainingId][cleanSubtopic]) {
						aggregatedData[trainingId][cleanSubtopic] = {};
					}
					if (!aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]]) {
						aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]] = {
							likes: 0,
							dislikes : 0,
							dont_know:0,
							super_likes:0,
						};
					}

					switch (swipeType) {
						case "like":
							aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]].likes += likes;
							break;
						case "dislike":
							aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]].dislikes += likes;
							break;
						case "dont_know":
							aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]].dont_know += likes;
							break;
						case "super_like":
							aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]].super_likes += likes;
							break;
					}
				}
			}
		});
	});

	return aggregatedData;
};
export const getKeywordData = (rows, keyword, cardsByTrainings, filters, totalLikes, date) => {
	let postFilter = applyFiltersToRows(rows, filters);

	const selectedCategories = filters.categories.options
		.filter(option => option.selected)
		.map(option => option.label);

	const allDates = getDatesArray(moment(date.from), moment(date.to));
	const emptyData = new Array(allDates.length).fill(0);

	// Structure de données pour le graphique
	const keywordData = {
		region: { labels: [], datasets: [{ data: [] }] },
		filiere: { labels: [], datasets: [{ data: [] }] },
		localisation: { labels: [], datasets: [{ data: [] }] },
		inTime: { labels: allDates, datasets: [{ data: emptyData }] }
	};

	if (postFilter.length === 0) return undefined;

	// Filtrer les lignes en fonction du mot-clé correspondant dans les métadonnées des cartes associées aux formations
	const filteredRows = postFilter.filter(row => {
		const cardId = row.dimensionValues[5].value; // Extraire le cardId de la ligne
		return Object.keys(cardsByTrainings).some(trainingId => {
			const trainingCards = cardsByTrainings[trainingId];
			if (!trainingCards[cardId] || !trainingCards[cardId]?.metadata) {
				return false;
			}
			const cardMetadata = trainingCards[cardId].metadata;
			const cleanMatchList = cardMetadata.cleanMatchList;

			return (
				cleanMatchList && cleanMatchList.includes(keyword) &&
				(selectedCategories.length === 0 || selectedCategories.includes(cardMetadata.cleanSubtopic))
			);
		});
	});


	// Agréger les données par catégorie des lignes filtrées
	filteredRows.forEach(row => {
		const region = row.dimensionValues[0].value === "(not set)" ? "Autres" : row.dimensionValues[0].value;
		const filiere = row.dimensionValues[1].value === "(not set)" ? "Autres" : row.dimensionValues[1].value;
		const localisation = row.dimensionValues[2].value === "(not set)" ? "Autres" : row.dimensionValues[2].value;

		const dateValue = row.dimensionValues[3].value;
		const likes = parseInt(row.metricValues[0].value); // Supposons que les likes sont toujours le premier metric

		// Fonction utilitaire pour ajouter ou mettre à jour les entrées des datasets
		const updateDataset = (category, key, value) => {
			const index = keywordData[category].labels.indexOf(key);

			if (index === -1) {
				keywordData[category].labels.push(key);
				keywordData[category].datasets[0].data.push(value);
			} else {
				keywordData[category].datasets[0].data[index] += value;
			}
		};

		updateDataset("region", region, likes);
		updateDataset("filiere", filiere, likes);
		updateDataset("localisation", localisation, likes);
		updateDataset("inTime", dateValue, likes);
	});

	// Traitement des données pour les graphiques en camembert
	keywordData.region = processPieChartData(processLabels(keywordData.region));
	keywordData.filiere = processPieChartData(processLabels(keywordData.filiere));

	return { keywordData,filteredRows };
};


export function getMultilineChartData(type, filteredRows, date) {
	const allDates = getDatesArray(moment(date.from), moment(date.to));
	const datasets = {};

	// Aggregate data by category from the filtered rows
	filteredRows.forEach(row => {
		let value;
		switch (type) {
			case "region":
				value = row.dimensionValues[0].value;
				break;
			case "filiere":
				value = row.dimensionValues[1].value;
				break;
			case "localisation":
				value = row.dimensionValues[2].value;
				break;
			default:
				value = row.dimensionValues[0].value; // Default to region if type is unspecified
		}

		const dateValue = moment(row.dimensionValues[3].value).format("YYYYMMDD");
		const count = parseInt(row.metricValues[0].value, 10);

		if (!datasets[value]) {
			datasets[value] = {
				label: value,
				data: new Array(allDates.length).fill(0)
			};
		}

		const dateIndex = allDates.indexOf(dateValue);
		if (dateIndex !== -1) {
			datasets[value].data[dateIndex] += count;
		}
	});

	// Transform object into array and sort it by the sum of counts, descending
	let datasetsArray = Object.values(datasets);
	datasetsArray.sort((a, b) => {
		const sumA = a.data.reduce((acc, num) => acc + num, 0);
		const sumB = b.data.reduce((acc, num) => acc + num, 0);
		return sumB - sumA;
	});

	// Keep only the top 8 and aggregate the rest into 'Other'
	if (datasetsArray.length > 8) {
		const otherData = new Array(allDates.length).fill(0);
		datasetsArray.slice(8).forEach(dataset => {
			dataset.data.forEach((count, index) => {
				otherData[index] += count;
			});
		});

		datasetsArray = datasetsArray.slice(0, 8);
		datasetsArray.push({
			label: "Autres",
			data: otherData
		});
	}

	return {
		labels: allDates,
		datasets: datasetsArray
	};
}

// Function to process pie chart data and add percentage to labels
export function processLabels(data) {
	let total = data.datasets[0].data.reduce((acc, val) => acc + val, 0);
	data.labels = data.labels.map((label, index) => {
		const percentage = ((data.datasets[0].data[index] / total) * 100).toFixed(1);
		return `${label} - ${percentage}%`;
	});
	return data;
}

export function getDatesArray(startDate, endDate) {
	const dates = [];
	const currentDate = moment(startDate);
	const endDateObj = moment(endDate);

	while (currentDate <= endDateObj) {
		dates.push(currentDate.format("YYYYMMDD"));
		currentDate.add(1, "day");
	}

	return dates;
}

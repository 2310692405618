import "./style.css";
import { useSelector } from "react-redux";
import { getImage } from "../../../../utils/APIRoutes";
import defaultpdp from "../../../../assets/defaultSchool.png";
import { Icon } from "../../../Icons/Icon";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../Images/Images";
import { useEffect, useRef } from "react";

export const FloatingMenu = ({ setMenuOn }) => {
	const client = useSelector(state => state.client);
	const modalRef = useRef(null);

	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem("token");
		window.location.replace("/");
	};

	const handleClickOutside = event => {
		const target = event.target;
		if (target.parentNode.id === "modal-btn" || modalRef.current?.contains(target)) {
			return;
		} else {
			setMenuOn(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleNavigate = path => {
		navigate(path);
		setMenuOn(false);
	};
	return (
		<div className={"floating-menu hover"} ref={modalRef}>
			<div className={"row2"} style={{ gap: 20, marginBottom: 20 }}>
				<Images src={client.profilePicture ? getImage + client.profilePicture : defaultpdp} />
				<h3>{client.acronym}</h3>
			</div>

			<p style={{ marginBottom: 20 }}>{client.email}</p>

			<button onClick={() => handleNavigate("/offres")}>
				<Icon name={"premiumMenu"} size={24} type={"filled"} />
				Mettre à niveau
			</button>

			<button onClick={() => handleNavigate("/params/account")}>
				<Icon name={"params"} size={24} type={"filled"} />
				Paramètres
			</button>

			<button onClick={() => handleNavigate("/params/suggestions")}>
				<Icon name={"idea"} size={24} />
				Suggérer une amélioration
			</button>

			<button onClick={() => handleNavigate("/params/bug")}>
				<Icon name={"bug"} size={24} />
				Signaler un bug
			</button>

			<button onClick={() => handleNavigate("/params/contact")}>
				<Icon name={"contact"} size={24} type={"filled"} />
				Nous contacter
			</button>

			<button onClick={handleLogout}>
				<Icon name={"logout"} size={24} type={"filled"} />
				Déconnexion
			</button>
		</div>
	);
};

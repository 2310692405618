import { apiClient } from "../clients";
import { trainingsRoute } from "../../utils/APIRoutes";

const fetch = async credentials => {
	try {
		const response = await apiClient.get(trainingsRoute, credentials);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

const patch = async credentials => {
	try {
		const response = await apiClient.post(trainingsRoute + "/patch", credentials);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

const fetchPublications = async credentials => {
	try {
		const response = await apiClient.get(`${trainingsRoute}publications/${credentials.trainingId}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

const upgrade = async credentials => {
	try {
		const response = await apiClient.post(`${trainingsRoute}upgrade`, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

const apiCalls = {
	fetch,
	patch,
	fetchPublications,
	upgrade
};

export default apiCalls;

import { useSelector } from "react-redux";
import "./linkstyle.css";
import { useState } from "react";
import trainingsService from "../../../../services/trainings/trainingsService";
import { CircularLoader } from "../../../LoadingComponents/CircularLoader";
import { trackEvent2 } from "../../../../utils/ga";
import { checkIfPremium } from "../../utils";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../Functions";
import { useNavigate } from "react-router-dom";

export const LinkPage = ({ selectedTraining }) => {
	const trainings = useSelector(state => state.trainings.content);
	const selectedTrainingData = trainings.find(training => training.trainingId === selectedTraining);

	const [link, setLink] = useState(selectedTrainingData.link);

	const onChange = e => {
		setLink(e.target.value);
	};

	const { mutateAsync, isPending } = trainingsService.usePatch();

	const validateURL = url => {
		const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
		return regex.test(url);
	};

	const navigate = useNavigate();
	const client = useSelector(state => state.client);

	const save = async () => {
		try {
			if (!checkIfPremium(selectedTrainingData)) {
				toast.info("Mettez à niveau votre formation pour faire cette action.", toastOptions);
				navigate("/offres");
				return;
			}
			await mutateAsync({ trainingId: selectedTrainingData.trainingId, field: "link", value: link });

			if (!client.admin) {
				trackEvent2("update_event", "Client", "update", "link", {
					structureId: client.structureId,
					acronym: client.acronym
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={"row2 linkrow"}>
			<h3>Lien vers votre site internet : </h3>
			<div className={"row2"}>
				<div>
					<input onChange={onChange} value={link} placeholder={"Entrez le lien de votre site..."} />

					{selectedTrainingData.link !== link && link.length > 1 && !validateURL(link) && (
						<span className="error" style={{ position: "absolute" }}>
							Attention, ce lien ne semble pas valide.
						</span>
					)}
				</div>

				{link !== selectedTrainingData.link && (
					<>
						{isPending ? (
							<CircularLoader />
						) : (
							<button disabled={isPending} onClick={() => save()}>
								Sauvegarder
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
};

import { clientMethods } from "../../models/client";
import apiCalls from "./apiCall";
import { toast } from "react-toastify";
import { toastOptions } from "../../Functions";

/**
 * Logique métier pour la gestion des clients.
 */
const clientController = {
	/**
	 * Fonction pour récupérer les données du client.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 * @param {Function} navigate - La fonction de navigation pour rediriger l'utilisateur.
	 * @returns {Promise<Object>} Les données du client.
	 * @throws {Error} Lance une erreur si la récupération échoue.
	 */
	async fetchClient(dispatch, navigate) {
		try {
			const data = await apiCalls.fetch();
			dispatch(clientMethods.setClient(data)); // Mise à jour du client dans le state
			return data; // Retourne les données pour un traitement éventuel
		} catch (error) {
			localStorage.removeItem("token");
			dispatch(clientMethods.setIsLoaded(true)); // Mise à jour de l'état de chargement
			return;
		}
	},

	/**
	 * Logique métier pour la connexion du client.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 * @param {Object} data - Les données de connexion.
	 */
	async loginSuccess(dispatch, data) {
		dispatch(clientMethods.setIsLoaded(true));
		localStorage.setItem("token", data.token);
		window.location.replace("/");
	},

	/**
	 * Logique métier pour gérer les erreurs de connexion.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 */
	async loginError(dispatch) {
		dispatch(clientMethods.setIsLoaded(true));
	},

	/**
	 * Logique métier pour mettre à jour les données du client.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 * @param {Object} data - Les nouvelles données à mettre à jour.
	 */
	async patchSuccess(dispatch, data) {
		dispatch(clientMethods.setField(data)); // Mise à jour des champs du client
	},

	/**
	 * Logique métier pour afficher un toast en cas d'erreur.
	 * @param {Error} error - L'erreur à afficher.
	 */
	async patchError(error) {
		toast.error(error.message, toastOptions);
	},

	/**
	 * Logique métier pour gérer la demande d'inscription.
	 */
	async registerSuccess() {
		toast.success("File d'attente rejointe, surveillez votre boîte mail !", toastOptions);
	},

	/**
	 * Logique métier pour gérer le succès du changement de mot de passe.
	 */
	async changePasswordSuccess() {
		toast.success("Mot de passe changé avec succès !", toastOptions);
	},

	/**
	 * Logique métier pour gérer les erreurs de changement de mot de passe.
	 * @param {Error} error - L'erreur à afficher.
	 */
	async changePasswordError(error) {
		toast.error(error.message, toastOptions);
	},

	/**
	 * Logique métier pour gérer le succès du contact.
	 */
	async contactSuccess() {
		toast.success("Votre message a bien été envoyé !", toastOptions);
	},

	/**
	 * Logique métier pour gérer les erreurs de contact.
	 * @param {Error} error - L'erreur à afficher.
	 */
	async contactError(error) {
		toast.error(error.message, toastOptions);
	},

	async resetRequestSuccess() {
		toast.success("Un email de réinitialisation vous a été envoyé !", toastOptions);
	},

	async resetPasswordSuccess(navigate) {
		toast.success("Mot de passe réinitialisé avec succès, connectez vous !", toastOptions);
		navigate("/login");
	}
};

export default clientController;

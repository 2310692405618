import { ParamField } from "./Fields/Field";
import { useSelector } from "react-redux";
import { PasswordField } from "./Fields/PasswordChange";

export const Account = () => {
	const client = useSelector(state => state.client);

	return (
		<>
			<h2>Votre compte</h2>

			<div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
				<ParamField
					name={"Nom :"}
					value={client.name || "Pas de nom pour le moment."}
					editable={false}
				/>
				<ParamField
					name={"Adresse e-mail :"}
					value={client.email}
					editable={!client.admin}
					field={"email"}
				/>
				<ParamField
					name={"Fonction"}
					value={client.role || "Pas de fonction pour le moment."}
					editable={false}
				/>

				<PasswordField value={client.password} />
			</div>
		</>
	);
};

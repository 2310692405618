import "./selector.css";
import adminService from "../../../services/admin/adminService";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "../../Icons/Icon";

export const StructureSelector = ({ onSelect, searchDefault = false }) => {
	const clientName = useSelector(state => state.client.acronym);
	const service = adminService.useStructures();

	const [search, setSearch] = useState(searchDefault ? "" : clientName);
	const [focus, setFocus] = useState(false);
	const [structures, setStructures] = useState([]);

	// Ref for the entire component
	const componentRef = useRef(null);

	const handleSearch = e => {
		setSearch(e.target.value);
	};

	const searchMethod = async data => {
		if (data?.length > 2) {
			const result = await service.mutateAsync({ search: data });
			setStructures(result);
		}
	};

	const handleKeyDown = e => {
		if (e.key === "Enter") {
			searchMethod(e.target.value);
		}
	};

	const handleSearchClick = () => {
		searchMethod(search);
	};

	// Close the dropdown if clicking outside the component
	const handleClickOutside = e => {
		if (componentRef.current && !componentRef.current.contains(e.target)) {
			setFocus(false);
		}
	};

	// Add event listener to detect clicks outside of the component
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div id="adminsearch" ref={componentRef}>
			<input
				type="search"
				value={search}
				onChange={e => handleSearch(e)}
				onKeyDown={handleKeyDown}
				onFocus={() => setFocus(true)}
			/>

			<Icon
				name="search"
				color={"#F19829"}
				size={12}
				type={"filled"}
				onClick={() => handleSearchClick()} // Ajoute la recherche au clic sur l'icône
			/>

			{focus && (
				<>
					<div className="schoolscontainer hover">
						{structures.length > 0 ? (
							structures.map(school => {
								return (
									<div
										key={school._id}
										onClick={() => {
											setSearch(school.acronym);
											onSelect(school);
											setFocus(false);
										}}>
										{school.acronym}
									</div>
								);
							})
						) : (
							<div>Aucun résultat</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

import html2pdf from 'html2pdf.js';

const sanitizeFileName = (name) => {
	return name.replace(/[^a-zA-Z0-9_-]/g, '_'); // Remplace les caractères spéciaux par des underscores
};
export const handleExportPDF = (selectedMetric) => {
	const element = document.getElementById("report-export");

	// Calcule la hauteur et la largeur de l'élément
	const height = element.scrollHeight; // Utilise la hauteur totale de l'élément à exporter
	const width = element.scrollWidth ; // Utilise la largeur totale de l'élément à exporter
	const sanitizedMetricName = sanitizeFileName(selectedMetric);

	const options = {
		margin: [70, 10, 10, 10], // Marge supérieure augmentée à 30px pour l'espacement du logo
		filename: `Hopteo_${sanitizedMetricName}.pdf`,
		html2canvas: {
			dpi: 192,
			letterRendering: true,
			scale: 2, // Augmente la qualité de l'image pour des grandes pages
			scrollX: 0, // Pour éviter des décalages horizontaux
			scrollY: 0 // Pour éviter des décalages verticaux
		},
		jsPDF: {
			unit: 'px',
			format: [width + 20, height + 90], // Ajuste le format pour inclure les marges
			orientation: 'portrait' // Ajuste selon les besoins
		}
	};

	html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf) => {
		const logoURL = '/logo192.png';

		const img = new Image();
		img.src = logoURL;
		img.onload = () => {
				pdf.addImage(img, 'PNG', 15, 10, 30, 30); // Position (15, 10) et taille (30x30)
				pdf.setFontSize(24);
				pdf.text("Hopteo Manager", 55, 30); // Position ajustée pour être à droite du logo

			pdf.save(`Hopteo_${sanitizedMetricName}.pdf`);


		};
	});};

import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

export function Icon({ name, size = "24", color, type, onClick, className, override = true }) {
	const [iconPath, setIconPath] = useState(null);
	const isDark = useSelector(state => state.app.isDark);

	const fill = color ? color : isDark ? "white" : "black";

	useEffect(() => {
		const loadIcon = async () => {
			try {
				const icon = await import(`./icons/${name}.svg`);
				setIconPath(icon.default);
			} catch (error) {
				console.error(`Icon with name: ${name} not found!`);
			}
		};

		loadIcon();
	}, [name]);

	if (!iconPath) return null;

	return (
		<ReactSVG
			src={iconPath}
			onClick={onClick}
			className={className}
			beforeInjection={svg => {
				if (!override) {
					return;
				}
				if (type === "filled") {
					svg.setAttribute("style", `fill: ${fill}; width: ${size}px; height: ${size}px;`);
				} else {
					svg.setAttribute("style", `stroke: ${fill}; width: ${size}px; height: ${size}px;`);
				}
			}}
		/>
	);
}

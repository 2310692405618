import { useDispatch, useSelector } from "react-redux";
import { Filter } from "./Filter";
import { useMemo } from "react";
import { statsMethods } from "../../../../models/metrics";
import {
	calculateFilterOptionsPublications,
	calculateFilterOptionsStrengths, calculateFilterOptionsStudents,
	calculateFilterOptionsVisits
} from "./utils";
import { checkIfPremium, countPages } from "../../../Client/utils";

export const Filters = ({ setFilters, selectedTraining }) => {
	const dispatch = useDispatch();
	const selectedMetric = useSelector(state => state.stats.metrics.selectedMetric);
	const selectedPublication = useSelector(state => state.stats.selectedPublication);
	const filtersForStrenghts = useSelector(state => state.stats.strengths.filters);
	const filtersForStudents = useSelector(state => state.stats.students.filters);

	const trainings = useSelector(state => state.stats.trainings);

	const filters = useSelector(state => state.stats?.filters);

	const search = useSelector(state => state.trainings.content);
	const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);

	const isAdmin = useSelector(state => state.client).admin;
	const { premiums } = countPages(search);
	const isPremium =
		isAdmin ||
		(selectedTraining && checkIfPremium(selectedTrainingData)) ||
		(!selectedTraining && premiums > 0);

	useMemo(
		() => {
			if (!selectedMetric || !trainings || !isPremium) return;

			let newFilterOptions = {};

			if (selectedMetric === "visits") {
				newFilterOptions = calculateFilterOptionsVisits(trainings, selectedTraining);
			} else if (selectedMetric === "publications") {
				newFilterOptions = calculateFilterOptionsPublications(selectedPublication);
			} else if (selectedMetric === "strengths") {
				newFilterOptions = calculateFilterOptionsStrengths(filtersForStrenghts,selectedTraining);
			}else if(selectedMetric === "students") {
				newFilterOptions = calculateFilterOptionsStudents(filtersForStudents);
			}else {
				newFilterOptions = { region: [], filiere: [], localisation: [] };
			}

			dispatch(
				statsMethods.setFilterOptions({
					filterKey: "region",
					options: newFilterOptions.region
				})
			);
			dispatch(
				statsMethods.setFilterOptions({
					filterKey: "filiere",
					options: newFilterOptions.filiere
				})
			);
			dispatch(
				statsMethods.setFilterOptions({
					filterKey: "localisation",
					options: newFilterOptions.localisation
				})
			);

			if (newFilterOptions.categories) {
				dispatch(
					statsMethods.setFilterOptions({
						filterKey: "categories",
						options: newFilterOptions.categories
					})
				);
			}

			if (selectedMetric === "visits") {
				dispatch(
					statsMethods.setFilterOptions({
						filterKey: "ranks",
						options: newFilterOptions.ranks
					})
				);
			}
		},
		[trainings, selectedTraining, selectedMetric, selectedPublication, filtersForStrenghts, filtersForStudents] // Now also re-calculates when selectedMetric changes
	);

	const noData =
		filters.region.options.length === 0 &&
		filters.filiere.options.length === 0 &&
		filters.localisation.options.length === 0;
	return (
		<div className={"row2"} style={{ marginTop: 20, gap: "10px 90px", flexWrap: "wrap" }}>
			{selectedMetric !== "ranks" &&
				selectedMetric !== "similar" &&
				!noData &&
				Object.keys(filters).map(key => {
					if (
						key === "likes" &&
						(selectedMetric === "strengths" || selectedMetric === "publications" || selectedMetric === "students")
					){
						return;
					}

					if (key === "ranks" && selectedMetric === "visits")
						return <Filter key={key} filter={filters[key]} setFilters={setFilters} />;

					if (key === "categories" && (selectedMetric === "strengths" || selectedMetric === "students"))
						return <Filter key={key} filter={filters[key]} setFilters={setFilters} />;

					if (filters[key].options.length > 0 && key !== "ranks" && key !== "categories") {
						return <Filter key={key} filter={filters[key]} setFilters={setFilters} />;
					}
				})}
		</div>
	);
};

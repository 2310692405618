export const FilterSelector = ({ filters, setFilter, selected, disabled = false }) => {
	return (
		<div className={"row2 typeSelector"} style={{ gap: 50 }}>
			{filters?.map((filter, index) => (
				<h2
					key={index}
					onClick={() => {
						if (disabled) return;
						setFilter(filter.key);
					}}
					className={filter.key === selected ? "selected" : ""}>
					{filter.value}
				</h2>
			))}
		</div>
	);
};

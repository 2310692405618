export const getCroppedImg = async (imageSrc, pixelCrop) => {
	const image = new Image();
	image.src = imageSrc;
	const canvas = document.createElement("canvas");
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;
	const ctx = canvas.getContext("2d");

	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height
	);

	return new Promise((resolve, reject) => {
		canvas.toBlob(blob => {
			if (!blob) {
				reject(new Error("Canvas is empty"));
				return;
			}
			blob.name = "newFile.jpg";
			resolve(blob);
		}, "image/jpeg");
	});
};

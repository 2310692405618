import { Icon } from "../../Icons/Icon";
import { Images } from "../../Images/Images";
import { getImage } from "../../../utils/APIRoutes";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UpgradeTrainingForm } from "../../Admin/Training/UpgradeTrainingForm";
import { UpgradeRequest } from "./UpgradeRequest";
import { DisplaySubscription } from "./Subscription/DisplaySubscription";
import { Link } from "react-router-dom";

export const TrainingInRow = ({ training, index, refetch }) => {
	const isAdmin = useSelector(state => state.client.admin);

	const [subscription, setSubscription] = useState(training.subscription);

	const [upgrade, setUpgrade] = useState(false);

	const handleSuccess = data => {
		setSubscription(data);
		setUpgrade(false);
		refetch();
	};

	const labelBtn = !subscription
		? "Mettre à niveau"
		: subscription?.status === "pending" ||
			  subscription?.status === "cancelled" ||
			  subscription?.status === "expired"
			? "Mettre à niveau"
			: "Modifier l'abonnement";
	const premium = subscription?.status ? subscription?.status === "active" : undefined;

	return (
		<>
			{!isAdmin && upgrade && subscription?.status !== "waiting" && (
				<UpgradeRequest training={training} handleSuccess={handleSuccess} />
			)}

			{isAdmin && subscription && <DisplaySubscription subscription={subscription} />}

			{isAdmin && upgrade && subscription?.status !== "waiting" && (
				<UpgradeTrainingForm
					training={training}
					handleSuccess={handleSuccess}
					subscription={subscription}
				/>
			)}

			<div
				className={"training hover"}
				style={
					upgrade
						? {
								borderColor: "var(--orange)",
								borderWidth: 3
							}
						: {}
				}>
				<div className={"row"} style={{ flexWrap: "nowrap", alignItems: "center", gap: 20 }}>
					<h4>
						Page {index + 1} : {training.trainingLabel}
					</h4>

					<div className={"row2"}>
						{(!subscription ||
							subscription?.status === "canceled" ||
							subscription?.status === "expired") &&
						!isAdmin ? (
							<button
								style={{
									width: "max-content"
								}}
								onClick={() => setUpgrade(!upgrade)}>
								{!upgrade ? labelBtn : "Annuler"}
							</button>
						) : subscription?.status === "pending" && !isAdmin ? (
							<p style={{ width: "max-content" }}>En attente...</p>
						) : (
							subscription?.status === "waiting" &&
							!isAdmin && (
								<Link
									to={subscription.paymentLink}
									className={"hover"}
									style={{
										width: "max-content",
										textDecoration: "none",
										background: "var(--orange)",
										color: "white",
										fontSize: 14
									}}>
									Lien de paiement
								</Link>
							)
						)}

						{isAdmin && subscription && subscription?.status !== "waiting" && (
							<button
								style={{
									width: "max-content"
								}}
								onClick={() => setUpgrade(!upgrade)}>
								{!upgrade ? labelBtn : "Annuler"}
							</button>
						)}

						{premium && <Icon override={false} name={"gold"} />}

						<Icon name={"more"} size={18} />
					</div>
				</div>

				<div className={"bandeau"}>
					{premium && training.bandeau && (
						<Images alt={"training"} src={getImage + training.bandeau} styles={"training"} />
					)}
				</div>
			</div>
		</>
	);
};

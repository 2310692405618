import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import qnaService from "../../../services/qna/qnaService";
import { faqMethods } from "../../../models/Q&A";
import { CircularLoader } from "../../LoadingComponents/CircularLoader";
import { Modal } from "../../Modals/FAQModal";
import "../Create/style.css";
import { Icon } from "../../Icons/Icon";
import { trackEvent2 } from "../../../utils/ga";
// Validation schema with yup
const validationSchema = yup.object().shape({
	trainings: yup.array().min(1, "Au moins une page doit être sélectionnée").required(),
	title: yup.string().required("La question est obligatoire"),
	answer: yup.string().required("La réponse est obligatoire")
});

export const Question = ({ question, trainingIndexes }) => {
	const [isEditing, setIsEditing] = useState((question.firstEdit && !question.answer) ?? false);
	const trainings = useSelector(state => state.trainings.content); // Assuming trainings are stored here
	const dispatch = useDispatch();
	const questionService = qnaService.usePatch();
	const [modal, setModal] = useState(false);

	// Initialize react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		watch
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			trainings: question.trainingIds,
			title: question.title,
			answer: question.answer
		}
	});

	// Watch selected trainings
	const selectedTrainings = watch("trainings", []);

	const handleEditToggle = () => {
		setIsEditing(!isEditing);
		setModal(false);
		if (!isEditing) {
			setValue("title", question.title);
			setValue("answer", question.answer);
			setValue("trainings", question.trainingIds);
		}
	};

	const client = useSelector(state => state.client);

	const onSubmit = async data => {
		if (questionService.isPending) {
			return;
		}
		try {
			await questionService.mutateAsync({
				question: {
					...question,
					...{
						title: data.title,
						answer: data.answer,
						trainingIds: data.trainings
					}
				}
			});

			dispatch(
				faqMethods.patchQuestion({
					id: question._id,
					placement: "displayed",
					updates: { title: data.title, answer: data.answer, trainingIds: data.trainings }
				})
			);

			setIsEditing(false);

			if (!client.admin) {
				trackEvent2("update_event", "FAQ", "update", "", {
					structureId: client.structureId,
					acronym: client.acronym
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={"question"}>
			<div style={{ marginBottom: 10 }}>
				{isEditing ? (
					<form onSubmit={handleSubmit(onSubmit)} className="edit-form">
						<div className="checkboxes row2">
							{trainings.map((training, idx) => (
								<label key={training.trainingId} className="row2" style={{ gap: 10 }}>
									<input
										type="checkbox"
										value={training.trainingId}
										{...register("trainings")}
										checked={selectedTrainings.includes(training.trainingId)}
										onChange={e => {
											const isChecked = e.target.checked;
											setValue(
												"trainings",
												isChecked
													? [...selectedTrainings, training.trainingId]
													: selectedTrainings.filter(
															id => id !== training.trainingId
														)
											);
										}}
									/>
									Page {idx + 1}
								</label>
							))}
						</div>

						{errors.trainings && <p className="error">{errors.trainings.message}</p>}

						<div className="field" style={{ marginTop: 30 }}>
							<input type="text" placeholder="Question" {...register("title")} />
							{errors.title && <p className="error">{errors.title.message}</p>}
						</div>
						<div className="field">
							<textarea placeholder="Réponse" {...register("answer")} />
							{errors.answer && <p className="error">{errors.answer.message}</p>}
						</div>
						{questionService.isPending ? (
							<div style={{ marginTop: 10, width: "fit-content", marginLeft: "auto" }}>
								<CircularLoader />
							</div>
						) : (
							<div
								className={"row2"}
								style={{
									marginLeft: "auto",
									display: "flex",
									marginTop: 10,
									width: "fit-content"
								}}>
								<button onClick={handleEditToggle}>Annuler</button>
								<button
									type="submit"
									style={{
										display: "block",
										borderColor: "var(--orange)",
										color: "var(--orange)"
									}}>
									Enregister
								</button>
							</div>
						)}
					</form>
				) : (
					<div className={"row"}>
						<div className="row2" style={{ marginBottom: 10, maxWidth: "80%" }}>
							{trainingIndexes.map((idx, i) => (
								<p key={i} className="hover">
									Page {idx}
								</p>
							))}
						</div>
						<div className={"row2"} style={{ position: "relative" }}>
							<Icon name="edit" size={21} onClick={() => handleEditToggle()} type={"filled"} />
							<Icon name="more" size={21} onClick={() => setModal(!modal)} />
							{modal && <Modal question={question} service={qnaService} />}
						</div>
					</div>
				)}
			</div>

			{!isEditing ? (
				<div className="colum">
					<p style={{ fontFamily: "Inter-SemiBold", marginBottom: 10 }}>{question.title}</p>
					<p>{question.answer}</p>
				</div>
			) : null}
		</div>
	);
};

import "./style.css";
import { useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import isEmail from "validator/es/lib/isEmail";
import { toastOptions } from "../../../Functions";
import { toast } from "react-toastify";
import { apiClient } from "../../../services/clients";
import { contactLandingRoute } from "../../../utils/APIRoutes";

export const Contact = () => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async event => {
		event.preventDefault();
		const contacted = localStorage.getItem("contacted");

		if (contacted) {
			return;
		}

		if (!isEmail(email)) {
			toast.info("Veuillez saisir une adresse e-mail valide.", toastOptions);
			return;
		}
		setLoading(true);

		try {
			const response = await apiClient.post(contactLandingRoute, { email });
			setEmail("");
			toast.success("Votre message a bien été envoyé !", toastOptions);
			localStorage.setItem("contacted", "true");
		} catch (e) {
			toast.error("Une erreur c'est produite.", toastOptions);
		}
		setLoading(false);
	};
	return (
		<div ref={ref} id={"contact"}>
			<section id={"section7"}>
				<h1>
					Envie de nous
					<br /> rejoindre ?
				</h1>
				<motion.form
					onSubmit={handleSubmit}
					initial={{ scale: 0, opacity: 0 }}
					exit={{ scale: 0, opacity: 0 }}
					transition={{ ease: "easeOut", duration: 0.4, delay: 0.4 }}
					animate={isInView ? { scale: 1, opacity: 1 } : {}}>
					<h4 style={{ marginBottom: 5 }}>Contactez-nous</h4>
					<div style={{ position: "relative" }}>
						<input
							type={"email"}
							placeholder={"Rentrez votre email ici..."}
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
						<button>{!loading ? "->" : "..."}</button>
					</div>
					<p>Notre adresse mail : hopteomanager@hopteo.com</p>
				</motion.form>
			</section>
		</div>
	);
};

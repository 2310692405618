import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./style.css";
import qnaService from "../../../services/qna/qnaService";
import { isPending } from "@reduxjs/toolkit";
import { CircularLoader } from "../../LoadingComponents/CircularLoader";
import { trackEvent2 } from "../../../utils/ga";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
	trainings: yup.array().min(1, "Au moins une page doit être sélectionnée").required(),
	question: yup.string().required("La question est obligatoire"),
	answer: yup.string().required("La réponse est obligatoire")
});

export const FAQForm = ({ trainings, setNewQuestion }) => {
	const client = useSelector(state => state.client);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		reset
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			trainings: []
		}
	});

	const service = qnaService.useCreate();

	const selectedTrainings = useWatch({
		control,
		name: "trainings",
		defaultValue: []
	});

	const onSubmit = async data => {
		try {
			await service.mutateAsync(data);
			reset();
			setNewQuestion(false);

			if (!client.admin) {
				trackEvent2("create_event", "FAQ", "create", "", {
					structureId: client.structureId,
					acronym: client.acronym
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleCheckboxChange = (trainingId, isChecked) => {
		const updatedTrainings = isChecked
			? [...selectedTrainings, trainingId]
			: selectedTrainings.filter(id => id !== trainingId);
		setValue("trainings", updatedTrainings);
	};

	return (
		<form className="question" onSubmit={handleSubmit(onSubmit)}>
			<div className="checkboxes" style={{ marginBottom: 30 }}>
				<div className={"row2"} style={{ overflow: "scroll", paddingBottom: 5 }}>
					{trainings.map((training, index) => (
						<label key={training.trainingId} className="row2" style={{ gap: 10 }}>
							<input
								type="checkbox"
								value={training.trainingId}
								checked={selectedTrainings.includes(training.trainingId)}
								onChange={e => handleCheckboxChange(training.trainingId, e.target.checked)}
							/>
							<p style={{ width: "max-content" }}>Page {index + 1}</p>
						</label>
					))}
				</div>

				{errors.trainings && <p className="error">{errors.trainings.message}</p>}
			</div>

			<div className="field">
				<input type="text" placeholder="Question" {...register("question")} />
				{errors.question && <p className="error">{errors.question.message}</p>}
			</div>

			<div className="field">
				<textarea placeholder="Réponse" {...register("answer")} />
				{errors.answer && <p className="error">{errors.answer.message}</p>}
			</div>

			{service.error && <p className="error">{service.error?.message}</p>}

			<button
				type="submit"
				style={{ marginTop: 10, marginLeft: "auto", display: "block" }}
				disabled={service.isPending}>
				{!isPending ? <CircularLoader /> : "Valider"}
			</button>
		</form>
	);
};

export const DisplaySubscription = ({ subscription }) => {
	const label =
		subscription.status === "pending"
			? "en attente"
			: subscription.status === "active"
				? "en cours"
				: subscription.status === "canceled"
					? "annulé"
					: subscription.status === "waiting"
						? "en attente de paiement"
						: "expiré";

	return (
		<div style={{ marginTop: 30 }}>
			<h3>Abonnement {label} :</h3>

			<div className={"row2 hover"} style={{ marginTop: 10, flexWrap: "wrap" }}>
				<p style={{ width: "max-content" }}>
					<span>Mode de paiement :</span> {subscription.paymentMode} -
				</p>
				<p style={{ width: "max-content" }}>
					<span>Nb étudiants :</span> {subscription.studentsCount} -
				</p>
				{subscription?.endDate && (
					<p style={{ width: "max-content" }}>
						<span>Date de fin : </span>
						{new Date(subscription.endDate).toISOString().slice(0, 10)} -
					</p>
				)}
				<p style={{ width: "max-content" }}>
					<span>Status : </span>
					{subscription.status} -
				</p>
				<p style={{ width: "max-content" }}>
					<span>Prix :</span> {subscription.price} €
				</p>
			</div>
		</div>
	);
};

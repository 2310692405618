import { handleExportPDF } from "../../utils/exportToPdf";
import { Icon } from "../Icons/Icon";
import { useDispatch, useSelector } from "react-redux";
import { appMethods } from "../../models/app";


export const ExportButton = () => {
	const dispatch = useDispatch();
	const metrics = useSelector(state => state.stats.metrics);
	const selectedMetricLabel = metrics.options.find(option => option.key === metrics.selectedMetric)?.value || metrics.selectedMetric;

	const handleClick = () => {
		dispatch(appMethods.setNavBar(false));
		handleExportPDF(selectedMetricLabel);
	}

	return (
		<button onClick={() => handleClick()} style={{
			marginBottom: "20px",
			fontFamily:"Inter-SemiBold",
			display:"flex",
			alignItems:"center",
			gap:5,
			width:"fit-content"
		}}><Icon size={"25"} name={"pdf"} color={"var(--orange)"} type={"filled"}/> Exporter les statistiques au format PDF</button>
	);
}

import moment from "moment/moment";

export const getGlobalVisits = (trainings, filters) => {
	const globalData = {
		region: { labels: [], datasets: [{ label: "Régions des visiteurs", data: [] }] },
		filiere: { labels: [], datasets: [{ label: "Filières des visiteurs", data: [] }] },
		localisation: { labels: [], datasets: [{ label: "Localisation des visiteurs", data: [] }] },
		all: { labels: [], datasets: [{ label: "Nombre de visites au cours du temps", data: [] }] }
	};

	// Collect all dates from trainings
	let allDates = new Set();
	trainings.forEach(training => {
		Object.keys(training.visits).forEach(date => {
			if (date !== "metadata") allDates.add(date);
		});
	});

	// Sort dates and initialize with zeros
	allDates = Array.from(allDates).sort();
	allDates.forEach(date => {
		globalData.all.labels.push(date);
		globalData.all.datasets[0].data.push(0); // Initialize with 0 visits
	});

	let totalVisits = 0;

	trainings.forEach(training => {
		Object.entries(training.visits).forEach(([date, visits]) => {
			if (date === "metadata") return;
			const filteredVisits = applyFiltersToVisits(visits, filters);

			filteredVisits.forEach(visit => {
				totalVisits += visit.eventCount;
				if (visit.region) {
					aggregateData(globalData.region, visit.region, visit.eventCount);
				}
				if (visit.filiere) {
					aggregateData(globalData.filiere, visit.filiere, visit.eventCount);
				}
				if (visit.localisation) {
					aggregateData(globalData.localisation, visit.localisation, visit.eventCount);
				}

				const index = globalData.all.labels.indexOf(date);
				globalData.all.datasets[0].data[index] += visit.eventCount;
			});
		});
	});

	// Calculate percentages for labels
	globalData.region = addPercentages(globalData.region, totalVisits);
	globalData.filiere = addPercentages(globalData.filiere, totalVisits);

	return globalData;
};
export const aggregateData = (globalMetric, label, eventCount) => {
	const labelIndex = globalMetric.labels.indexOf(label);
	if (labelIndex === -1) {
		globalMetric.labels.push(label);
		globalMetric.datasets[0].data.push(eventCount);
	} else {
		globalMetric.datasets[0].data[labelIndex] += eventCount;
	}
};

const addPercentages = (metric, total) => {
	return {
		labels: metric.labels.map(
			(label, index) => `${label} - ${((metric.datasets[0].data[index] / total) * 100).toFixed(2)}%`
		),
		datasets: metric.datasets
	};
};

export const applyFiltersToVisits = (visits, filters) => {
	const selectedRegions = filters.region.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedFilieres = filters.filiere.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLocalisations = filters.localisation.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLikes = filters.likes.options.filter(option => option.selected).map(option => option.label);
	const { max, min } = filters.ranks.current;
	return visits.filter(visit => {
		const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(visit.region);
		const matchesFiliere = selectedFilieres.length === 0 || selectedFilieres.includes(visit.filiere);
		const matchesLocalisation =
			selectedLocalisations.length === 0 || selectedLocalisations.includes(visit.localisation);
		const matchesLikes =
			selectedLikes.length === 0 ||
			(selectedLikes.includes("Avec likes") && visit.isLiked) ||
			(selectedLikes.includes("Sans likes") && !visit.isLiked);
		const matchesRank =
			!visit.rank || (min === 0 && max === 0) || (visit.rank >= min && visit.rank <= max);
		return matchesRegion && matchesFiliere && matchesLocalisation && matchesLikes && matchesRank;
	});
};

export const sortDataDescending = data => {
	if (!data)
		return {
			labels: [],
			datasets: []
		};

	const sortedData = [...data.datasets[0].data]
		.map((value, index) => ({ label: data.labels[index], value }))
		.sort((a, b) => b.value - a.value);

	return {
		labels: sortedData.map(item => item.label),
		datasets: [
			{
				...data.datasets[0],
				data: sortedData.map(item => item.value)
			}
		]
	};
};

export const getMultiLineChartData = (type, trainings, filters, from, to) => {
	let labels = [];
	let graphName;
	switch (type) {
		case "region":
			graphName = "Nombres de visites par région";
			break;
		case "filiere":
			graphName = "Nombres de visites par filière";
			break;
		case "localisation":
			graphName = "Nombres de visites par ville";
			break;
		default:
			graphName = "Nombres de visites";
	}

	const cumulativeData = {};

	// Initialize labels with dates between 'from' and 'to'
	const startDate = moment(from, "YYYYMMDD");
	const endDate = moment(to, "YYYYMMDD");
	for (let m = startDate; m.isBefore(endDate) || m.isSame(endDate); m.add(1, "days")) {
		labels.push(m.format("YYYYMMDD"));
	}

	trainings.forEach(training => {
		Object.entries(training.visits).forEach(([date, visits]) => {
			if (date === "metadata") return; // Skip metadata
			const filteredVisits = applyFiltersToVisits(visits, filters); // Apply filters

			filteredVisits.forEach(visit => {
				const dataPoint = visit[type];
				if (dataPoint && labels.includes(date)) {
					// Ensure date is within the range
					if (!cumulativeData[dataPoint]) {
						cumulativeData[dataPoint] = {};
					}
					if (!cumulativeData[dataPoint][date]) {
						cumulativeData[dataPoint][date] = 0;
					}
					cumulativeData[dataPoint][date] += visit.eventCount;
				}
			});
		});
	});

	const totalData = new Array(labels.length).fill(0);

	// Prepare datasets
	let datasets = Object.keys(cumulativeData).map(label => {
		const dataByDate = cumulativeData[label];
		const data = labels.map(date => {
			const count = dataByDate[date] || 0;
			totalData[labels.indexOf(date)] += count; // Accumulate total data
			return count;
		});
		return {
			label,
			data
		};
	});

	// Sort datasets by total descending and take top 8
	datasets.sort(
		(a, b) => b.data.reduce((acc, num) => acc + num, 0) - a.data.reduce((acc, num) => acc + num, 0)
	);
	if (datasets.length > 8) {
		const otherData = new Array(labels.length).fill(0);
		datasets.slice(8).forEach(dataset => {
			dataset.data.forEach((count, index) => (otherData[index] += count));
		});

		datasets = datasets.slice(0, 8);
		datasets.push({
			label: "Autres",
			data: otherData,
			borderColor: "#CCCCCC",
			backgroundColor: "#CCCCCC"
		});
	}
	// Add total data set
	const totalLabel =
		type === "region"
			? "Toutes les régions"
			: type === "filiere"
				? "Toutes les filières"
				: "Toutes les villes";
	datasets.push({
		label: totalLabel,
		data: totalData,
		borderColor: "#3784EB",
		backgroundColor: "#3784EB"
	});

	return {
		graphName,
		labels,
		datasets
	};
};

export const processPieChartData = data => {
	let modifiedData = {
		labels: [],
		datasets: [{ label: data.datasets[0].label, data: [] }],
		othersDetails: []
	};

	// Calcul des pourcentages et mapping avec les labels
	let total = data.datasets[0].data.reduce((acc, curr) => acc + curr, 0);
	let percentages = data.datasets[0].data.map((count, index) => ({
		label: data.labels[index],
		count,
		percentage: (count / total) * 100
	}));

	// Trier les pourcentages en ordre décroissant
	percentages.sort((a, b) => b.percentage - a.percentage);

	// Sélection des X premiers et regroupement des autres
	let others = 0;
	percentages.forEach((item, index) => {
		if (index < 8) {
			modifiedData.labels.push(item.label);
			modifiedData.datasets[0].data.push(item.count);
		} else {
			others += item.count;
			modifiedData.othersDetails.push(item); // Correctly gather details
		}
	});

	if (percentages.length > 8) {
		modifiedData.labels.push("Autres");
		modifiedData.datasets[0].data.push(others);
	}

	return modifiedData;
};

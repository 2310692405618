import "./style.css";
import manager from "../../../pages/Landing/icons/manager.svg";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Icon } from "../../Icons/Icon";
import { Link } from "react-router-dom";

export const Communication = () => {
	const ref = useRef(null);
	const isInView = useInView(ref, { margin: "-200px", once: true });

	return (
		<section id={"section5"}>
			<h1>
				Améliorez votre <br /> communication
			</h1>

			<div className={"details-container"} ref={ref}>
				<div className={"row2"} style={{ justifyContent: "space-between" }}>
					<div className={"post-grid"}>
						<div className={"grid"}>
							<Component p={<p>Démarquez vous des écoles semblables</p>} img={"first"} />
							<Component
								p={<p>Identifiez l’origine géographique de vos prospects</p>}
								img={"location"}
							/>
							<Component p={<p>Identifiez les points d’améliorations</p>} img={"search2"} />

							<Component
								p={<p>Comprenez les critères déterminants pour les étudiants</p>}
								img={"studentHat"}
							/>
							<Component
								p={<p>Identifiez les points forts à mettre en avant</p>}
								img={"plus"}
							/>
							<Component p={<p>Adaptez votre marketing suivant la cible</p>} img={"target"} />
						</div>

						<Link
							to={"/register"}
							className={"landing-btn register"}
							style={{ display: "block", margin: "auto" }}>
							Rejoindre la file d'attente
						</Link>
					</div>

					<img alt={"Mockup Hopteo Manager Statistiques"} src={manager} className={"manager"} />
				</div>
			</div>
		</section>
	);
};

const Component = ({ p, img }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { margin: "-80px", once: true });

	return (
		<div ref={ref}>
			<motion.div
				initial={{ opacity: 0, scale: 0 }}
				exit={{ opacity: 0, scale: 0 }}
				transition={{ ease: "easeOut", duration: 0.3 }}
				animate={isInView ? { opacity: 1, scale: 1 } : {}}
				className={"card"}>
				<Icon name={img} type={"filled"} size={50} />
				{p}
			</motion.div>
		</div>
	);
};

import { Route, Routes } from "react-router-dom";
import Account from "../pages/Account";
import { FAQ } from "../pages/FAQ";
import Statistiques from "../pages/Statistiques";
import { Leads } from "../pages/Leads";
import { useSelector } from "react-redux";
import { AdminCenter } from "../pages/Admin";
import { Avis } from "../pages/Avis";
import { ParamsPage } from "../pages/Params";
import { Offres } from "../pages/Offres";

export const LoggedRoutes = () => {
	const client = useSelector(state => state.client);
	return (
		<Routes>
			<Route path="/" element={<Account />} />
			<Route path={"/faq"} element={<FAQ />} />
			<Route path="/statistiques" element={<Statistiques />} />
			<Route path="/leads" element={<Leads />} />

			<Route path="/avis" element={<Avis />} />
			<Route path="/params/*" element={<ParamsPage />} />

			<Route path="/offres" element={<Offres />} />

			{client.admin && <Route path={"/admin"} element={<AdminCenter />} />}
		</Routes>
	);
};

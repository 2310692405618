import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import "../style.css";
import moment from "moment";
import "moment/locale/fr";
import { Filters } from "./Filters";
import { Question } from "./Question";
import { CircularLoader } from "../../LoadingComponents/CircularLoader";
import qnaService from "../../../services/qna/qnaService";

moment.locale("fr");

export const Pending = ({ selectedTraining }) => {
	const totals = useSelector(state => state.qna.pending.totals["Tout"]);

	const pending = useSelector(state => state.qna.pending.content);
	const trainings = useSelector(state => state.trainings.content);
	const activeFilter = useSelector(state => state.qna.activeFilter);
	const currentTotal = useSelector(state => state.qna.pending.totals[activeFilter]);

	const service = qnaService.usePending();

	const fetch = async () => {
		await service.mutateAsync({
			filter: activeFilter,
			max: 15,
			skip: pending[activeFilter].content.length
		});
	};

	useEffect(() => {
		if (activeFilter && pending[activeFilter].loading) {
			fetch();
		}
	}, [activeFilter]);

	const filteredQuestions = useMemo(() => {
		let filtered = pending[activeFilter].content;

		if (selectedTraining) {
			filtered = filtered.filter(question => question.trainingIds.includes(selectedTraining));
		}

		return filtered;
	}, [pending, activeFilter, selectedTraining]);

	return (
		<div>
			<div
				className="row"
				style={{ alignItems: "center", borderBottom: "1px solid var(--orange)", paddingBottom: 10 }}>
				<h3>Questions posées par les étudiants ({totals})</h3>
			</div>

			<Filters />

			<div className="colum-container">
				{filteredQuestions?.map((question, index) => {
					const trainingIndexes = question.trainingIds.map(id => {
						const trainingIndex = trainings.findIndex(t => t.trainingId === id);
						return trainingIndex > -1 ? trainingIndex + 1 : "";
					});
					return (
						<Question
							key={question._id}
							question={question}
							trainingIndexes={trainingIndexes}
							selectedTraining={selectedTraining}
							activeFilter={activeFilter}
						/>
					);
				})}

				{filteredQuestions.length === 0 && !service.isPending && (
					<p>Aucune question pour le moment...</p>
				)}

				{!pending[activeFilter].loading ||
					(service.isPending && (
						<div
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircularLoader />
						</div>
					))}

				{pending[activeFilter].content.length < currentTotal && (
					<div
						style={{
							display: "flex",
							width: "100%",
							alignItems: "center",
							justifyContent: "center"
						}}>
						{!service.isPending && <button onClick={() => fetch()}>Voir plus</button>}
					</div>
				)}
			</div>
		</div>
	);
};

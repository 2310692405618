import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminMethods } from "../../../models/admin";

export const Treated = ({ parentId }) => {
	const dispatch = useDispatch();

	// Récupérer la liste des éléments traités (treatedList) depuis le store
	const treatedList = useSelector(state => state.admin.clients.treatedList);

	// Vérifier si le parentId est déjà dans la treatedList
	const isChecked = treatedList.some(client => client._id === parentId);

	// Fonction pour gérer l'ajout/retrait de l'élément à la liste
	const handleToggle = () => {
		dispatch(adminMethods.toggleFromTreatment({ _id: parentId }));
	};

	return (
		<input
			type="checkbox"
			checked={isChecked}
			onChange={() => handleToggle()} // Appel de la fonction de gestion au changement
		/>
	);
};

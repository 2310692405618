import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import apiCalls from "./apiCalls";
import trainingsController from "./trainingsController";

/**
 * Service pour gérer les opérations liées aux formations.
 */
const trainingsService = {
	/**
	 * Utilise une requête pour récupérer les formations.
	 * @param {boolean} logged - Indique si l'utilisateur est connecté.
	 * @returns {object} - Objet de requête de `react-query`.
	 */
	useFetch(logged) {
		const dispatch = useDispatch();
		return useQuery({
			queryKey: ["trainings"],
			queryFn: () => trainingsController.fetchTrainings(dispatch),
			enabled: !!logged,
			staleTime: Infinity
		});
	},

	/**
	 * Utilise une mutation pour mettre à jour une formation.
	 * @returns {object} - Objet de mutation de `react-query`.
	 */
	usePatch() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.patch,
			onSuccess: async data => trainingsController.patchSuccess(dispatch, data),
			onError: async () => trainingsController.patchError()
		});
	},

	/**
	 * Utilise une requête pour récupérer les publications.
	 * @param {string} trainingId - L'ID de la formation.
	 * @returns {object} - Objet de requête de `react-query`.
	 */
	usePublications(trainingId) {
		const dispatch = useDispatch();
		return useQuery({
			queryKey: ["publications", trainingId],
			queryFn: async () => trainingsController.fetchPublications(dispatch, trainingId),
			onError: async error => trainingsController.handleFetchPublicationsError(error),
			staleTime: Infinity,
			retry: false
		});
	},

	/**
	 * Utilise une mutation pour améliorer une formation.
	 * @returns {object} - Objet de mutation de `react-query`.
	 */
	useUpgradeTraining() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.upgrade,
			onSuccess: async data => trainingsController.updateTrainingSuccess(dispatch, data)
		});
	}
};

export default trainingsService;

import { useEffect, useState } from "react";

import "./auth.css";
import { Icon } from "../../Icons/Icon";

export const AuthInput = ({ label, field, error, type = "", required = false }) => {
	const [isFocused, setIsFocused] = useState(false);

	// On vérifie si le champ est déjà pré-rempli pour appliquer le focus par défaut
	useEffect(() => {
		if (field?.value) {
			setIsFocused(true);
		}
	}, [field?.value]);

	const handleFocus = () => setIsFocused(true);
	const handleBlur = e => {
		if (!e.target.value) {
			setIsFocused(false);
		}
	};

	return (
		<div
			className={`input-wrapper ${isFocused ? "focused" : ""} ${required ? "required" : ""}`}
			style={{ position: "relative", paddingBottom: 20 }}>
			<Label label={label} required={required} isFocused={isFocused} />
			<InputField type={type} field={field} handleFocus={handleFocus} handleBlur={handleBlur} />
			<ErrorMessage error={error} />
		</div>
	);
};

const Label = ({ label, required, isFocused }) => {
	return (
		<label className={`input-label ${isFocused ? "focused" : ""}`}>
			{label} {required && <span className="error">*</span>}
		</label>
	);
};

export const InputField = ({ type, field, handleFocus, handleBlur }) => {
	const [showPassword, setShowPassword] = useState(false);

	// Fonction pour basculer l'affichage du mot de passe
	const togglePasswordVisibility = () => {
		setShowPassword(prevState => !prevState);
	};

	if (type === "textarea") {
		return <textarea {...field} onFocus={handleFocus} onBlur={handleBlur} className="input-field" />;
	}

	return (
		<div style={{ position: "relative" }}>
			<input
				type={type === "password" && showPassword ? "text" : type}
				{...field}
				onFocus={handleFocus}
				onBlur={handleBlur}
				className="input-field"
			/>
			{type === "password" && (
				<span
					className="toggle-password"
					onClick={togglePasswordVisibility}
					style={{
						position: "absolute",
						right: 10,
						top: 10,

						cursor: "pointer",
						color: "#007bff"
					}}>
					{showPassword ? (
						<Icon name={"eyes-on"} size={"18"} />
					) : (
						<Icon name={"eyes-off"} size={"18"} />
					)}
				</span>
			)}
		</div>
	);
};

const ErrorMessage = ({ error }) => {
	return (
		<p className="error" style={{ marginTop: 5, fontSize: "0.8rem" }}>
			{error?.message}
		</p>
	);
};

import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial pour le slice admin
 * @type {Object}
 * @property {string} selectedFilter - Filtre sélectionné
 * @property {Array} filters - Liste des filtres disponibles
 * @property {Object} clients - Informations sur les clients
 * @property {Object} avis - Informations sur les avis
 * @property {Object} leads - Informations sur les leads
 */
const initialState = {
	selectedFilter: "clients",
	filters: [
		{ key: "clients", value: "Gestion des clients" },
		{ key: "avis", value: "Avis" },
		{ key: "leads", value: "Leads" }
	],
	clients: {
		selectedStructure: undefined,
		actives: {
			columns: [
				{ key: "acronym", value: "Nom" },
				{ key: "trainings", value: "Pages formations" },
				{
					key: "premiums",
					value: "Pages premium"
				},
				{ key: "payment", value: "Dernier dernier payment" }
			]
		},
		pendings: {
			columns: [
				{ key: "structure", value: "École" },
				{ key: "email", value: "Email" },
				{ key: "job", value: "Poste" },
				{ key: "phone", value: "Téléphone" },
				{ key: "message", value: "Message" },
				{ key: "createdAt", value: "Demande le" },
				{ key: "treated", value: "Est traitée ?" }
			]
		},
		treatedList: [],
		max: 15,
		filters: [
			{ key: "actives", value: "Liste des clients" },
			{
				key: "pendings",
				value: "Liste d'attente"
			}
		],
		selected: "actives",
		loaded: false
	},
	avis: {
		pending: { total: 0, content: [], loading: true },
		refused: { total: 0, content: [], loading: true },
		verified: { total: 0, content: [], loading: true },
		max: 15,
		filters: [
			{ key: "pending", value: "À modérer" },
			{
				key: "refused",
				value: "Refusés"
			},
			{
				key: "verified",
				value: "Vérifiés"
			}
		],
		forAll: false,
		loaded: false
	},
	leads: {
		leads: { content: [], max: 15, total: 0, loading: true },
		unlocked: { content: [], max: 15, total: 0, loading: true },
		exported: { content: [], max: 15, total: 0, loading: true },
		structuresByLeads: { content: [], max: 15, total: 0, loading: true },
		filters: [
			{ key: "leads", value: "Leads disponibles" },
			{ key: "unlocked", value: "Leads débloqués" },
			{ key: "exported", value: "Leads exportés" }
		],
		columns: [
			{ key: "email", value: "Email" },
			{ value: "Filière", key: "filiere" },
			{ value: "Année", key: "studyYear" },
			{ value: "Rang", key: "rank" },
			{ value: "Pages", key: "trainingIds" }
		],
		loaded: false,
		forAll: true,
		selected: "leads",
		selectedStructure: undefined
	}
};

/**
 * Création du slice pour l'admin
 */
export const adminSlice = createSlice({
	name: "admin",
	initialState,
	reducers: {
		/**
		 * Définir le filtre sélectionné
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - Filtre sélectionné
		 * @returns {Object} - Nouvel état
		 */
		setSelectedFilter: (state, action) => {
			return { ...state, selectedFilter: action.payload };
		},
		/**
		 * Définir le sous-filtre sélectionné
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - Sous-filtre sélectionné
		 * @returns {Object} - Nouvel état
		 */
		setSelectedSubFilter: (state, action) => {
			return {
				...state,
				[state.selectedFilter]: { ...state[state.selectedFilter], selected: action.payload }
			};
		},
		/**
		 * Définir l'état de chargement d'un sous-filtre
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.selectedFilter - Filtre sélectionné
		 * @param {string} action.payload.selected - Sous-filtre sélectionné
		 * @param {boolean} action.payload.loading - État de chargement
		 */
		setSubFilterLoading: (state, action) => {
			const { selectedFilter, selected, loading } = action.payload;
			state[selectedFilter][selected].loading = loading;
		},

		/**
		 * Définir les avis
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {number} action.payload.pendingCount - Nombre d'avis en attente
		 * @param {number} action.payload.refusedCount - Nombre d'avis refusés
		 * @param {number} action.payload.verifiedCount - Nombre d'avis vérifiés
		 * @param {Array} action.payload.content - Contenu des avis
		 * @param {string} action.payload.type - Type d'avis
		 */
		setAvis: (state, action) => {
			const { pendingCount, refusedCount, verifiedCount, content, type } = action.payload;
			state.avis.pending.total = pendingCount;
			state.avis.refused.total = refusedCount;
			state.avis.verified.total = verifiedCount;
			state.avis[type].content = [...state.avis[type].content, ...content];
			state.avis[type].loading = false;
		},
		/**
		 * Définir les leads
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {Array} action.payload.leads - Liste des leads
		 * @param {number} action.payload.soldCount - Nombre de leads vendus
		 * @param {number} action.payload.lockedCount - Nombre de leads verrouillés
		 * @param {number} action.payload.exportedCount - Nombre de leads exportés
		 * @param {string} action.payload.type - Type de leads
		 */
		setLeads: (state, action) => {
			const { leads, soldCount, lockedCount, exportedCount, type } = action.payload;
			state.leads.unlocked.total = soldCount;
			state.leads.exported.total = exportedCount;
			state.leads.leads.total = lockedCount;
			state.leads[type].content = [...state.leads[type].content, ...leads];
			state.leads[type].loading = false;
		},
		/**
		 * Ajouter un client
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {Object} action.payload - Informations du client
		 */
		addClient(state, action) {
			state.clients.actives.content.unshift(action.payload);
			const index = state.clients.pendings.content.findIndex(
				client => client._id === action.payload._id
			);
			if (index !== -1) {
				const client = state.clients.pendings.content.splice(index, 1)[0];
				state.clients.pendings.total -= 1;
			}
			state.clients.actives.total += 1;
		},
		/**
		 * Changer l'état "forAll" des avis
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {boolean} action.payload - Nouvel état "forAll"
		 */
		changeForAll: (state, action) => {
			state.avis = { ...initialState.avis, forAll: action.payload };
		},
		/**
		 * Changer l'état "forAll" des leads
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {boolean} action.payload - Nouvel état "forAll"
		 */
		changeForAllLeads: (state, action) => {
			state.leads = { ...initialState.leads, forAll: action.payload };
		},
		/**
		 * Mettre à jour le statut d'un avis
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.avisId - ID de l'avis
		 * @param {string} action.payload.newStatus - Nouveau statut de l'avis
		 */
		updateAvisStatus(state, action) {
			const { avisId, newStatus } = action.payload;
			const statuses = ["pending", "refused", "verified"];
			let avis;

			// Trouver et retirer l'avis de sa liste actuelle
			statuses.forEach(status => {
				const index = state.avis[status].content.findIndex(item => item._id === avisId);
				if (index !== -1) {
					avis = state.avis[status].content.splice(index, 1)[0];
					state.avis[status].total -= 1;
				}
			});

			if (avis) {
				// Mettre à jour le statut de l'avis
				avis.status = newStatus;
				// Ajouter l'avis à la nouvelle liste
				state.avis[newStatus].content.unshift(avis);
				state.avis[newStatus].total += 1;
			}
		},
		/**
		 * Déplacer des leads vers la liste exportée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {number} action.payload.skip - Nombre de leads à sauter
		 * @param {number} action.payload.max - Nombre maximum de leads à déplacer
		 * @param {string} action.payload.from - Liste d'origine des leads
		 */
		moveToExported(state, action) {
			const { skip, max, from } = action.payload;
			const leadsToMove = state.leads[from].content.slice(skip, skip + max);
			state.leads.exported.content = [...leadsToMove, ...state.leads.exported.content];
			state.leads.exported.total += leadsToMove.length;

			// Retirer les leads de la liste d'origine
			state.leads[from].content = state.leads[from].content.filter(
				(_, index) => index < skip || index >= skip + max
			);
			state.leads[from].total -= leadsToMove.length;
		},
		/**
		 * Définir la structure sélectionnée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - Structure sélectionnée
		 */
		setSelectedStructure: (state, action) => {
			state.clients.selectedStructure = action.payload;
		},
		/**
		 * Définir les structures par leads
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {Array} action.payload.content - Liste des structures par leads
		 */
		setStructuresByLeads: (state, action) => {
			const { content } = action.payload;
			state.leads.structuresByLeads.content = [...state.leads.structuresByLeads.content, ...content];
			state.leads.structuresByLeads.loading = false;
		},
		/**
		 * Définir les leads de la structure sélectionnée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - Structure sélectionnée
		 */
		setSelectedStructureLeads: (state, action) => {
			state.leads.selectedStructure = action.payload;
			state.leads.leads = { content: [], max: 15, total: 0, loading: true };
			state.leads.unlocked = { content: [], max: 15, total: 0, loading: true };
			state.leads.exported = { content: [], max: 15, total: 0, loading: true };
		},

		toggleFromTreatment: (state, action) => {
			const { _id } = action.payload;
			const index = state.clients.treatedList.findIndex(client => client._id === _id);
			if (index === -1) {
				state.clients.treatedList.push(action.payload);
			} else {
				state.clients.treatedList.splice(index, 1);
			}
		},
		clearTreated: (state, action) => {
			state.clients.treatedList = [];
		}
	}
});

/**
 * Actions exportées pour utilisation dans l'application
 */
export const adminMethods = adminSlice.actions;

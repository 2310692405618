import { Link } from "react-router-dom";
import { Icon } from "../components/Icons/Icon";
import appico from "../assets/hopteo_manager.webp";
import ForgotForm from "../components/Forms/Formulaires/ForgotForm";

const ForgotPassword = () => {
	return (
		<div className="home-page page-container loginpage auth-page">
			<img src={appico} className={"logo-container"} />
			<div className="left home">
				<main>
					<Link to={"/"}>
						<Icon size={30} name={"arrow-left"} color={"var(--orange)"} />
						Retour à l'accueil
					</Link>

					<h1 className="bigtitle">Mot de passe oublié ?</h1>
					<p>Pas de soucis, vous pouvez le modifier.</p>
				</main>
			</div>
			<div className="form-container">
				<ForgotForm />
			</div>
		</div>
	);
};

export default ForgotPassword;

import { StatComponent } from "./StatComponent";
import "./summary.css";
import { useSelector } from "react-redux";

export const SummaryStats = ({ selectedTraining }) => {
	const trainings = useSelector(state => state.trainings.content);
	const selectedTrainingData = trainings.find(training => training.trainingId === selectedTraining);

	return (
		<div style={{ display: "flex", gap: 0 }}>
			<StatComponent data={selectedTrainingData?.visits || 0} label={"Visites"} />
			<StatComponent
				data={selectedTrainingData?.websiteOpenings || 0}
				label={"Clics sur le lien du site internet"}
			/>
			<StatComponent data={selectedTrainingData?.likes || 0} label={"J’aime"} />
		</div>
	);
};

import "./Footer.css";
import baseline from "../../assets/hopteo_manager.webp";
import base from "./foot.webp";
import insta from "./inst.webp";
import link from "./ln.webp";
import fb from "./fb.webp";
import disc from "./disc.webp";

import { Link } from "react-router-dom";

const Footer = () => {
	const handleGoToContact = () => {
		const item = document.getElementById("contact");
		if (item) {
			const itemPosition = item.getBoundingClientRect().top + window.pageYOffset;
			const offsetPosition = itemPosition - 200; // Adjust -50 to the desired offset

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
			});
		}
	};
	return (
		<footer className="footer">
			<img className="footer-img" src={baseline} alt="Hopteo" />
			<ul className="menu">
				<li className="menu-item">
					<Link className="item" to="https://hopteo.com/">
						Notre application
					</Link>
				</li>

				<li className="menu-item">
					<button
						className="item"
						style={{ border: 0, padding: 0 }}
						onClick={() => handleGoToContact()}>
						Nous contacter
					</button>
				</li>
				<li className="menu-item">
					<Link className="item" to="/register" onClick={e => handleGoToContact(e)}>
						Faire une demande d'inscription
					</Link>
				</li>

				<li className="menu-item">
					<Link className="item" to="/login">
						Se connecter
					</Link>
				</li>

				<li className="menu-item">
					<Link className="item" to="https://app.hopteo.com/api/v2/setting/privacyPolicy">
						Politique de confidentialité
					</Link>
				</li>
			</ul>
			<ul className="list-icon">
				<li className="icon-item">
					<a
						className="icon"
						href="https://www.instagram.com/hopteo_orientation/"
						target="_blank"
						rel="noopener noreferrer">
						<img className="ion-icon" src={insta} alt="instagram" />
						instagram
					</a>
				</li>
				<li className="icon-item">
					<a
						className="icon"
						href="https://www.facebook.com/profile.php?id=100092716956707"
						target="_blank"
						rel="noopener noreferrer">
						<img className="ion-icon" src={fb} alt="facebook" />
						facebook
					</a>
				</li>
				<li className="icon-item">
					<a
						className="icon"
						href="https://discord.gg/GQ6ereZGVW"
						target="_blank"
						rel="noopener noreferrer">
						<img className="ion-icon" src={disc} alt="discord" />
						discord
					</a>
				</li>
				<li className="icon-item">
					<a
						className="icon"
						href="https://www.linkedin.com/company/hopteo/"
						target="_blank"
						rel="noopener noreferrer">
						<img className="ion-icon" src={link} alt="linkedin" />
						linkedin
					</a>
				</li>
			</ul>

			<p className="menu-p">20 chemin de la Teulière, 81000 Albi</p>
			<p className="menu-p">&copy;2024 Hopteo | Tous droits réservés</p>
			<img className="dwn-img" src={base} alt="footer" />
		</footer>
	);
};

export default Footer;

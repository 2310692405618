import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./form.css";
import adminService from "../../../services/admin/adminService";
import { CircularLoader } from "../../LoadingComponents/CircularLoader";
import { StructureSelector } from "../StructureSelector/StructureSelector";

const schema = yup.object().shape({
	email: yup.string().email("Email invalide").required("L'email de connexion est requis."),
	password: yup
		.string()
		.min(8, "Le mot de passe doit contenir au moins 8 caractères")
		.required("Le mot de passe est requis."),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Les mots de passe doivent correspondre"),
	admin: yup.boolean() // Ajout d'une validation simple pour le champ "admin"
});

export const NewClientForm = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset
	} = useForm({
		resolver: yupResolver(schema)
	});

	const service = adminService.useCreate();
	const [selected, setSelected] = useState(undefined);

	// Regarder la valeur de la case "admin" en temps réel
	const isAdmin = watch("admin", false);

	const onSubmit = async data => {
		try {
			const toSend = { ...data, structure: selected?.structureId };
			await service.mutateAsync(toSend);
			reset();
		} catch (error) {}
	};
	const onSelect = structure => {
		setSelected(structure);
	};

	return (
		<form className={"client-form"} onSubmit={handleSubmit(onSubmit)}>
			<div className={"row2"}>
				{/* Cacher le champ StructureSelector si admin est coché */}
				{!isAdmin && (
					<div className={"field"}>
						<label>Structure</label>
						<StructureSelector onSelect={onSelect} searchDefault={true} />
					</div>
				)}

				<div className={"field"}>
					<label>Email de connexion</label>
					<input type="email" placeholder="Tapez l'email de connexion..." {...register("email")} />
					{errors.email && <p className="error">{errors.email.message}</p>}
				</div>
			</div>

			<div className={"row2"}>
				<div className={"field"}>
					<label>Mot de passe</label>
					<input
						type="password"
						placeholder="Rentrez le mot de passe..."
						{...register("password")}
					/>
					{errors.password && <p className="error">{errors.password.message}</p>}
				</div>

				<div className={"field"}>
					<label>Confirmer le mot de passe</label>
					<input
						type="password"
						placeholder="Confirmer le mot de passe..."
						{...register("confirmPassword")}
					/>
					{errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>}
				</div>
			</div>

			<label className={"row2"} style={{ marginLeft: "auto" }}>
				<input type="checkbox" {...register("admin")} />
				Compte Admin ?
			</label>

			{service.error && (
				<p className="error" style={{ position: "relative", margin: "auto" }}>
					{service.error?.message}
				</p>
			)}

			<button
				style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				type="submit"
				disabled={service.isPending}>
				{service.isPending ? <CircularLoader /> : "Créer"}
			</button>
		</form>
	);
};

import React, { useEffect } from "react";
import statsService from "../../../../services/stats/statsService";
import { useSelector } from "react-redux";
import BarChart from "../../Graphs/BarChart";
import { cumulRanks } from "./utils";
import { GraphLoader } from "../../../LoadingComponents/GraphLoader";
import { StatComponent } from "../../../Client/Trainings/summaryStats/StatComponent";
import { checkIfPremium, countPages } from "../../../Client/utils";
import { NotPremium } from "../../Graphs/NotPremiums/Pie";
import { Info } from "../../../Info/Info";

export const Ranks = ({ selectedTraining }) => {
	const service = statsService.useRanks();
	const ranks = useSelector(state => state.stats.ranks);

	const trainingsSize = Object.keys(ranks.datasets).length;

	const search = useSelector(state => state.trainings.content);
	const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);
	const { premiums } = countPages(search);
	const isAdmin = useSelector(state => state.client.admin);
	const isPremium =
		isAdmin ||
		(selectedTraining && checkIfPremium(selectedTrainingData)) ||
		(!selectedTraining && premiums > 0);

	useEffect(() => {
		const fetchRanks = async () => {
			await service.mutateAsync();
		};

		if (ranks.loading && isPremium) {
			fetchRanks();
		}
	}, []);

	const metric =
		selectedTraining && trainingsSize > 1 ? ranks.datasets[selectedTraining] : cumulRanks(ranks);

	const options = {
		title: function (tooltipItems) {
			return `Rang : ${tooltipItems[0].label}`;
		},
		label: function (tooltipItem) {
			const total = metric?.data.reduce((acc, value) => acc + value, 0) || 0;
			const percentage = ((tooltipItem.raw / total) * 100).toFixed(2);
			return `Étudiants : ${tooltipItem.raw} - (${percentage}%)`;
		}
	};

	const complements = {
		title: "Les rangs de votre formation dans le classement personnalisé des étudiants sur Hopteo",
		titlex: "Rangs",
		titley: "Nombres d'étudiants",
		options: options
	};

	return (
		<div
			className={`rank-container ${isPremium ? "" : "locked-graph"}`}
			style={{ marginTop: 20, position: "relative", minHeight: 200 }}>
			{ranks.loading && isPremium ? (
				<GraphLoader />
			) : isPremium ? (
				<>
					<div
						className={"row graph"}
						id={"ranks-row"}
						style={{ justifyContent: "space-evenly", alignItems: "center", marginBottom: 10 }}>
						<StatComponent data={metric?.medianRank || 0} label={"Rang médian sur Hopteo"} />
						<StatComponent data={metric?.averageRank || 0} label={"Rang moyen sur Hopteo"} />
						<div className={"row2"} style={{ gap: 10 }}>
							<span>/ pour {metric?.rankCount || 0} classements</span>{" "}
							<Info
								info={
									"Le nombre de classements correspond au nombre de fois où vous apparaissez dans des classements. Il est important de noter qu'un même élève peut avoir plusieurs classements."
								}
							/>
						</div>
					</div>
					<BarChart labels={metric.labels} data={metric.data} complements={complements} />
				</>
			) : (
				<NotPremium inTime={false} />
			)}
		</div>
	);
};

import "./modal.css";
import { faqMethods } from "../../models/Q&A";
import { useDispatch } from "react-redux";
import { Icon } from "../Icons/Icon";
import { CircularLoader } from "../LoadingComponents/CircularLoader";

export const Modal = ({ question, service }) => {
	const patch = service.usePatch();
	const remove = service.useDelete();

	const dispatch = useDispatch();

	const onArchived = async () => {
		try {
			await patch.mutateAsync({
				question: {
					...question,
					...{
						archived: true
					}
				}
			});

			dispatch(
				faqMethods.archiveQuestion({
					id: question._id,
					topic: "Archive"
				})
			);
		} catch (e) {
			console.log(e);
		}
	};

	const onRemove = async () => {
		if (remove.isPending) {
			return;
		}
		try {
			await remove.mutateAsync({
				questionId: question._id
			});
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={"modal hover"}>
			<div className={"row2"} onClick={() => onRemove()}>
				{remove.isPending ? (
					<>
						<CircularLoader /> <p>Chargement...</p>
					</>
				) : (
					<>
						<Icon name={"trash"} size={15} type={"filled"} />
						<p>Supprimer</p>
					</>
				)}
			</div>
			<div className={"row2"} onClick={() => onArchived()}>
				<Icon name={"archive"} size={15} />
				<p>Archiver</p>
			</div>
		</div>
	);
};

import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial pour le slice de l'application
 * @type {Object}
 * @property {boolean} loading - Indicateur de chargement
 * @property {boolean} isDark - Indicateur du mode sombre
 * @property {Array} notifications - Liste des notifications
 * @property {boolean} activeNavBar - Indicateur de la barre de navigation active
 */
const initialState = {
	loading: false,
	isDark: false,
	notifications: [],
	activeNavBar: true
};

/**
 * Création du slice pour l'application
 */
export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		/**
		 * Définir l'état de chargement
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {boolean} action.payload - Indicateur de chargement
		 */
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		/**
		 * Définir le mode sombre
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {boolean} action.payload - Indicateur du mode sombre
		 */
		setIsDark: (state, action) => {
			state.isDark = action.payload;
		},
		/**
		 * Définir les notifications
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {Array} action.payload - Liste des notifications
		 */
		setNotifications: (state, action) => {
			state.notifications = action.payload;
		},
		/**
		 * Définir l'état du menu
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {boolean} action.payload - Indicateur de l'état du menu
		 */
		setMenu: (state, action) => {
			state.menuOn = action.payload;
		},
		/**
		 * Définir l'état de la barre de navigation
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {boolean} action.payload - Indicateur de la barre de navigation active
		 */
		setNavBar: (state, action) => {
			state.activeNavBar = action.payload;
		}
	}
});

/**
 * Actions exportées pour utilisation dans l'application
 */
export const appMethods = appSlice.actions;

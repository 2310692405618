import { useInfiniteQuery } from "@tanstack/react-query";
import apiCalls from "./apiCalls";

/**
 * Service pour gérer les avis.
 */
const avisService = {
	/**
	 * Utilise une requête pour récupérer les avis avec pagination.
	 *
	 * @param {string} trainingId - L'ID de la formation pour laquelle récupérer les avis.
	 * @param {string} selected - Le type d'avis sélectionné (par exemple, vérifié, non vérifié).
	 * @returns {object} - L'objet de requête de react-query.
	 */
	useFetchAvis(trainingId, selected) {
		return useInfiniteQuery({
			queryKey: ["avis", trainingId, selected],
			queryFn: async ({ pageParam = 0 }) => {
				const response = await apiCalls.fetchAvis({
					trainingId,
					skip: pageParam, // Le nombre d'avis déjà récupérés
					max: 15, // Nombre d'avis à récupérer par requête
					type: selected
				});
				return response;
			},
			getNextPageParam: (lastPage, allPages) => {
				// Calcule le nouveau skip basé sur le nombre total d'avis récupérés
				const totalAvisFetched = allPages.flatMap(page => page.avis).length;

				// Si la dernière page contient moins d'avis que max, cela signifie qu'il n'y a plus d'avis
				if (lastPage.avis.length < 15) {
					return undefined;
				}

				// Retourne le total d'avis récupérés pour être utilisé comme le prochain "skip"
				return totalAvisFetched;
			},
			staleTime: Infinity
		});
	}
};

export default avisService;

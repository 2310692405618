import React from "react";
import "./card.css";
import moment from "moment";
import "moment/locale/fr"; // Importer la localisation française pour moment
import StarRatings from "react-star-ratings";
import { Buttons } from "./Buttons/Buttons";
import capitaine from "../../../assets/capitaine_study_transparent.png";
import { Icon } from "../../Icons/Icon";
import { useSelector } from "react-redux";

moment.locale("fr"); // Définir la localisation française

export const CardAvis = ({ avis, tokenCapitaineStudy }) => {
	// Fonction pour calculer le taux global
	const calculateGlobalRate = rateObj => {
		const { rate1, rate2, rate3, rate4 } = rateObj;
		return ((rate1 || 0) + (rate2 || 0) + (rate3 || 0) + (rate4 || 0)) / 4; // Calcul de la moyenne
	};

	// Fonction pour convertir une note sur 10 à une note sur 5
	const convertToFiveStar = ratingOutOfTen => {
		// Si la note n'est pas un entier, arrondir à l'entier supérieur
		if (!Number.isInteger(ratingOutOfTen)) {
			ratingOutOfTen = Math.ceil(ratingOutOfTen);
		}
		return ratingOutOfTen / 2;
	};

	// Calculer le taux global pour cet avis
	const globalRate = convertToFiveStar(calculateGlobalRate(avis.rateObj));
	const formattedDate = moment(avis.createdAt).format("D MMMM YYYY");

	const isAdmin = useSelector(state => state.client.admin);

	const displayText = avis.reviewText || "";

	// Gestion du statut de l'avis
	const statusDetails = {
		pending: {
			iconName: "clock",
			iconType: "filled",
			text: "Avis en attente"
		},
		verified: {
			iconName: "verified",
			iconType: "filled",
			text: "Avis vérifié"
		},
		refused: {
			iconName: "error",
			iconType: null,
			text: "Avis refusé"
		}
	};

	const statusDetail = statusDetails[avis.status];

	// Détails des notes individuelles
	// Détails des notes individuelles avec vérification de l'existence des valeurs
	const rateDetails = [
		{ rate: avis.rateObj.rate1, label: "Enseignement, cours et professeurs" },
		{ rate: avis.rateObj.rate2, label: "Stages, alternance, insertion professionnelle" },
		{ rate: avis.rateObj.rate3, label: "Locaux, infrastructures et localisation" },
		{ rate: avis.rateObj.rate4, label: "Ambiance, vie étudiante et associative" }
	].filter(item => item.rate !== undefined && item.rate !== null); // Filtrer les taux non définis

	return (
		<div style={{ display: "flex", gap: 10 }}>
			{avis.status === "" && <div className="pending">En attente</div>}
			{avis.status === "pending" && (
				<Buttons
					identifiant={avis._id}
					type={avis.status}
					tokenCapitaineStudy={tokenCapitaineStudy}
				/>
			)}

			<div className="avis hover">
				<div className="row" style={{ marginBottom: 10 }}>
					<div className="row2" style={{ alignItems: "center", flexWrap: "wrap" }}>
						{avis.structureAcronym && (
							<h3 style={{ width: "max-content" }}>{avis.structureAcronym}</h3>
						)}
						<p style={{ padding: 0 }}>
							{avis.structureAcronym && " : "} {avis.page}
						</p>
					</div>
					{statusDetail && (
						<div className="row2">
							<Icon name={statusDetail.iconName} size="14" type={statusDetail.iconType} />
							<span>{statusDetail.text}</span>
							<Icon name="horizontal-more" className="more" size="24" />
						</div>
					)}
				</div>

				<div className="row">
					<div className="row2">
						<StarRatings
							rating={parseFloat(globalRate.toFixed(2))}
							starRatedColor="var(--orange)"
							numberOfStars={5}
							starDimension="25px"
							starSpacing="2px"
						/>
						<h3>
							{avis.publicName}
							{avis.realFirstName &&
								isAdmin &&
								` (privé : ${avis.realFirstName} ${avis.realFamilyName})`}
						</h3>
					</div>

					<span style={{ paddingTop: 5 }}>{formattedDate}</span>
				</div>

				<div className="row" style={{ width: "100%" }}>
					<h4 style={{ paddingTop: 5 }}>Option : {avis.trainingSpeciality ?? "Non défini."}</h4>
				</div>

				<div className="row" style={{ width: "100%" }}>
					<span style={{ paddingTop: 5 }}>
						Avant l'école : {avis.userSchoolCareer ?? "Non défini."}
					</span>
				</div>

				<div style={{ marginTop: 20 }}>
					<p style={{ wordBreak: "break-word" }}>{displayText}</p>
					<div className="rates" style={{ marginTop: 20 }}>
						{rateDetails.map((item, index) => (
							<div className="row2" key={index}>
								<StarRatings
									rating={parseFloat(convertToFiveStar(item.rate).toFixed(2))}
									starRatedColor="var(--orange)"
									numberOfStars={5}
									starDimension="20px"
									starSpacing="2px"
								/>
								<span>{item.label}</span>
							</div>
						))}
					</div>
				</div>
				{avis.isCapitaineStudy && <img src={capitaine} alt="Capitaine Study" />}
			</div>
		</div>
	);
};

import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial pour le slice des statistiques
 * @type {Object}
 * @property {Object} globalsCount - Compteurs globaux
 * @property {Object} metrics - Options de métriques
 * @property {Object} visits - État des visites
 * @property {Object} filters - Filtres disponibles
 * @property {Object} ranks - État des rangs
 * @property {Object} publications - État des publications
 * @property {Object} allPublications - Toutes les publications
 * @property {Object} strengths - Points forts
 * @property {Object} similars - Écoles semblables
 */
const initialState = {
	minDate: undefined,
	maxDate: undefined,
	from: undefined,
	to: undefined,
	loading: true,
	selectedTraining: undefined,
	globalsCount: { visits: 0, likes: 0, impressions: 0, websiteOpenings: 0 },
	trainings: {},
	metrics: {
		selectedMetric: "visits",
		options: [
			{ key: "visits", value: "Visites" },
			{ key: "publications", value: "Publications" },
			{ key: "ranks", value: "Rang sur Hopteo" },
			{ key: "strengths", value: "Points forts / Points faibles" },
			{ key: "similars", value: "Écoles semblables" },
			{ key: "students", value: "Zoom étudiants", forAdmin: true }
		]
	},
	visits: {
		loaded: true
	},
	filters: {
		region: {
			label: "Régions",
			options: [],
			search: "",
			placeholder: "Rechercher une région...",
			key: "region"
		},
		filiere: {
			label: "Filières",
			options: [],
			search: "",
			placeholder: "Rechercher une fillière...",
			key: "filiere"
		},
		localisation: {
			label: "Villes",
			options: [],
			search: "",
			placeholder: "Rechercher une ville...",
			key: "localisation"
		},
		likes: {
			disableSearch: true,
			label: "Likes",
			options: [
				{ label: "Avec likes", selected: false },
				{ label: "Sans likes", selected: false }
			],
			description: "Les visiteurs de vos page peuvent indiquer qu’ils aiment la page via un like.",
			key: "likes"
		},
		ranks: {
			disableSearch: true,
			label: "Rang",
			options: { min: 0, max: 0 },
			current: { min: 0, max: 0 },
			description:
				"Ce filtre correspond au rang des formations dans le classement personnalisé des étudiants.",
			key: "ranks",
			type: "range"
		},
		categories: {
			label: "Catégories",
			options: [],
			search: "",
			placeholder: "Rechercher une catégorie...",
			key: "categories"
		}
	},
	ranks: {
		datasets: {},
		loading: true
	},
	publications: {
		loading: true
	},
	allPublications: {
		content: {},
		loading: true,
		globalCount: 0
	},
	selectedPublication: undefined,
	strengths: {
		rows: [],
		filters: {},
		cardsByTrainings: {},
		loading: true,
		selectedKeyword: undefined
	},
	students: {
		rows: [],
		filters: {},
		cards: {},
		loading: true,
		selectedKeyword: undefined
	},
	similars: {
		closests: { content: {}, loading: true },
		liked: { content: {}, loading: true }
	}
};

/**
 * Création du slice pour les statistiques
 */
export const statsSlice = createSlice({
	name: "stats",
	initialState,
	reducers: {
		/**
		 * Définir les compteurs globaux
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setGlobals: (state, action) => {
			return { ...state, ...action.payload };
		},
		/**
		 * Définir les rangs
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setRanks: (state, action) => {
			return { ...state, ranks: action.payload };
		},
		/**
		 * Définir les publications par formation
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setPublicationsByTraining: (state, action) => {
			const { trainingId, data, globalCount } = action.payload;

			if (!trainingId || trainingId === "undefined" || trainingId === "null") {
				const newData = { ...state.allPublications.content, ...data };
				const keys = Object.keys(state.allPublications.content);

				if (keys.length === 0) {
					const localKey = Object.keys(newData)[0];
					state.selectedPublication = newData[localKey];
				}
				state.allPublications.loading = false;
				state.allPublications.content = newData;
			} else {
				const key = Object.keys(data)[0];
				state.selectedPublication = data[key];
				state.publications[trainingId] = data;
				state.publications.loading = false;
			}
			state.allPublications.globalCount = globalCount;
		},
		/**
		 * Définir l'état de chargement
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setLoaded: (state, action) => {
			state.loading = false;
		},
		/**
		 * Définir la date de début
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setFromDate(state, action) {
			state.loading = true;
			state.from = action.payload;
			state.strengths.loading = true;
		},
		/**
		 * Définir la date de fin
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setToDate(state, action) {
			state.loading = true;
			state.to = action.payload;
			state.strengths.loading = true;
		},
		/**
		 * Définir la sous-métrique sélectionnée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setSelectedSubMetric: (state, action) => {
			state.metrics.selectedMetric = action.payload;
		},
		/**
		 * Définir la métrique globale sélectionnée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setGlobalSelected: (state, action) => {
			const { selectedMetric } = action.payload;

			return {
				...state,
				selectedMetric: selectedMetric
			};
		},
		/**
		 * Définir les options de filtre
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setFilterOptions: (state, action) => {
			const { filterKey, options } = action.payload;
			const filter = state.filters[filterKey];
			if (filter) {
				filter.options = options;
				if (filterKey === "ranks") {
					filter.current = options;
				}
			}
		},
		/**
		 * Définir les options de rang
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setRanksOptions: (state, action) => {
			const { min, max } = action.payload;
			state.filters.ranks.current = { min, max };
		},
		/**
		 * Définir la publication sélectionnée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setSelectedPublication: (state, action) => {
			if (action.payload?.trainingId) {
				state.selectedPublication = action.payload.item;
			} else {
				state.selectedPublication = action.payload.item;
			}
		},
		/**
		 * Définir les points forts
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setStrengths: (state, action) => {
			const { rows, filters, cardsByTrainings } = action.payload;
			state.strengths.rows = rows;
			state.strengths.filters = filters;
			state.strengths.cardsByTrainings = cardsByTrainings;
			state.strengths.loading = false;
		},

		/**
		 * Définir le zoom étudiant
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setStudents: (state, action) => {
			const { rows, filters, cards } = action.payload;
			state.students.rows = rows;
			state.students.filters = filters;
			state.students.cards = cards;
			state.students.loading = false;
		},

		/**
		 * Définir le mot-clé sélectionné
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setSelectedKeyword: (state, action) => {
			state.strengths.selectedKeyword = action.payload;
		},

		/**
		 * Définir le mot-clé sélectionné
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setSelectedKeywordStudents: (state, action) => {
			state.students.selectedKeyword = action.payload;
		},
		/**
		 * Définir la formation sélectionnée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setSelectedTraining: (state, action) => {
			state.selectedTraining = action.payload;
		},
		/**
		 * Définir les écoles semblables
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setSimilars: (state, action) => {
			const { data, type } = action.payload;
			state.similars[type] = { content: data, loading: false };
		},
		/**
		 * Définir les catégories de points forts
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 */
		setStrengthsCategories: (state, action) => {
			state.filters.categories.options = Array.from(action.payload).map(label => ({
				label,
				selected: false
			}));
		}
	}
});

// Export des actions pour utilisation dans l'application
export const statsMethods = statsSlice.actions;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GraphLoader } from "../../../LoadingComponents/GraphLoader";
import BarChart from "../../Graphs/BarChart";
import { Table } from "../../../Table/Table";
import {
	aggregateDataByKeywordsAndTraining,
	getKeywordData,
	getMultilineChartData,
	processStrengthData
} from "./utils";
import statsService from "../../../../services/stats/statsService";
import { statsMethods } from "../../../../models/metrics";
import PieChart from "../../Graphs/Pie/Pie";
import LineChart from "../../Graphs/Line/LineChart";
import { Column } from "../../Graphs/Column/Column";
import { sortDataDescending } from "../Visites/utils";
import MultiLineChart from "../../Graphs/Line/MultiLineChart";
import { trackEvent2 } from "../../../../utils/ga";
import { checkIfPremium, countPages } from "../../../Client/utils";
import { NotPremium } from "../../Graphs/NotPremiums/Pie";
import { Icon } from "../../../Icons/Icon";

export const Strengths = ({ selectedTraining }) => {
	const {
		from,
		to,
		strengths: { rows, loading, cardsByTrainings, selectedKeyword }
	} = useSelector(state => state.stats);
	const service = statsService.useStrengths();
	const filters = useSelector(state => state.stats.filters);

	const search = useSelector(state => state.trainings.content);
	const selectedTrainingData = search.find(training => training.trainingId === selectedTraining);
	const { premiums } = countPages(search);
	const isAdmin = useSelector(state => state.client.admin);
	const isPremium =
		isAdmin ||
		(selectedTraining && checkIfPremium(selectedTrainingData)) ||
		(!selectedTraining && premiums > 0);

	const fetch = async () => {
		await service.mutateAsync({ from, to });
	};
	useEffect(() => {
		if (loading && from && to && isPremium) {
			fetch();
		}
	}, [from, to, loading]);

	const { labels, data, tableRows, totalInteractions } = useMemo(() => {
		if (!rows || rows?.length === 0 || !isPremium) {
			return { labels: [], data: [], tableRows: [], totalInteractions: 0 };
		}
		const dataToTreat = selectedTraining
			? { [selectedTraining]: cardsByTrainings[selectedTraining] }
			: cardsByTrainings;
		const aggregatedData = aggregateDataByKeywordsAndTraining(rows, dataToTreat, filters);

		if (!aggregatedData) {
			return { labels: [], data: [], tableRows: [], totalInteractions: 0 };
		}
		return processStrengthData(aggregatedData, selectedTraining);
	}, [rows, selectedTraining, filters]);

	const columns = ["Mot-clé", "Catégorie", "Likes","Superlikes", "Interactions totales", "Taux d'appréciation"];
	const rowsFormat = ["keyword", "category", "likes","super_likes", "totalInteractions", "percentage"];

	const options = {
		title: tooltipItems => "Catégorie - Mot clé - Likes/Superlikes - Interactions - Taux d'appréciation",
		label: tooltipItem => {
			return `${tooltipItem.label}`;
		}
	};

	const complements = {
		title: "Préférence des étudiants par mots-clés liés à votre établissement",
		titlex: "Catégorie - Mots clé",
		titley: "Taux d'appreciation",
		options: options,
		hiddeX: true
	};

	const dispatch = useDispatch();
	const [onTempo, setOnTempo] = useState(undefined);
	const client = useSelector(state => state.client);

	const handleTempo = type => {
		setOnTempo(type);

		if (!client.admin) {
			trackEvent2("display_in_time_data", "Metrics", "open", "strengths", {
				structureId: client.structureId,
				acronym: client.acronym,
				type: type
			});
		}
	};

	const onRowClick = data => {
		dispatch(statsMethods.setSelectedKeyword(data));
	};

	const { keywordData, filteredRows } = useMemo(() => {
		if (!selectedKeyword || !isPremium) return {};
		const dataToTreat = selectedTraining
			? { selectedTraining: cardsByTrainings[selectedTraining] }
			: cardsByTrainings;
		const result = getKeywordData(rows, selectedKeyword.keyword, dataToTreat, filters, totalInteractions, {
			from,
			to
		});
		if (!result) return { keywordData: {}, filteredRows: [] };
		return result;
	}, [rows, selectedKeyword, cardsByTrainings, filters]);

	return (
		<div
			className={`rank-container strength-container  ${isPremium ? "" : "locked-graph"}`}
			style={{ marginTop: 20, minHeight: 200, position: "relative" }}>
			{loading && isPremium ? (
				<GraphLoader />
			) : isPremium ? (
				<>
					<BarChart labels={labels} data={data} complements={complements} />
					{!selectedKeyword ? (
						<div
							className={"hover"}
							style={{ width: "fit-content", marginTop: 15, transition: "border-color 0.2s" }}>
							<Table
								columns={columns}
								rows={tableRows}
								rowsFormat={rowsFormat}
								onRowClick={onRowClick}
							/>
						</div>
					) : (
						<div style={{ marginTop: 15 }}>
							{onTempo ? (
								<div className="onTemp">
									<h4
										className="row2"
										style={{ cursor: "pointer" }}
										onClick={() => setOnTempo(undefined)}>
										<Icon name={"back"} type={"filled"} size={24} />
										Retour
									</h4>
									<div className="graph" style={{ marginTop: 20 }}>
										{loading && <GraphLoader />}
										{isPremium && (
											<MultiLineChart
												metric={getMultilineChartData(onTempo, filteredRows, {
													from,
													to
												})}
											/>
										)}
									</div>
								</div>
							) : (
								keywordData.hasOwnProperty("region") && (
									<>
										<div className={"row2 onTemp"} style={{ alignItems: "baseline" }}>
											<h4
												className="row2"
												style={{ cursor: "pointer" }}
												onClick={() =>
													dispatch(statsMethods.setSelectedKeyword(undefined))
												}>
												<Icon name={"back"} type={"filled"} size={24} />
												Retour
											</h4>
											<div className={"one-row"}  style={{ width: "fit-content", height: 120 }}>
												<Table
													columns={columns}
													rows={[
														{
															...selectedKeyword,
														}
													]}
													rowsFormat={rowsFormat}
												/>
											</div>
										</div>

										<div className="grid-container">
											<div className="row2" style={{ width: "100%" }}>
												<PieChart
													metric={keywordData?.region}
													loading={loading}
													title={`Régions des étudiants qui aiment : ${selectedKeyword.keyword}`}
													handleTempo={() => handleTempo("region")}
												/>
												<PieChart
													metric={keywordData?.filiere}
													loading={loading}
													title={`Filières des étudiants qui aiment : ${selectedKeyword.keyword}`}
													handleTempo={() => handleTempo("filiere")}
												/>
											</div>
											<div className="line-chart graph">
												{loading && <GraphLoader />}
												{keywordData?.inTime && (
													<LineChart metric={keywordData?.inTime} />
												)}
											</div>
											<Column
												loading={loading}
												label={"Étudiants"}
												title={"Étudiants"}
												metric={sortDataDescending(keywordData?.localisation)}
												handleTempo={() => handleTempo("localisation")}
											/>
										</div>
									</>
								)
							)}
						</div>
					)}
				</>
			) : (
				<NotPremium inTime={false} />
			)}
		</div>
	);
};

import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial pour le slice des leads
 * @type {Object}
 * @property {Object} leads - Liste des leads disponibles
 * @property {Object} unlocked - Liste des leads débloqués
 * @property {Object} exported - Liste des leads exportés
 * @property {Array} columns - Colonnes des données de leads
 * @property {Array} filters - Filtres disponibles pour les leads
 * @property {boolean} loaded - Indicateur de chargement des leads
 * @property {string} selected - Filtre sélectionné
 */
const initialState = {
	leads: { content: [], max: 15, total: 0, loading: false },
	unlocked: { content: [], max: 15, total: 0, loading: false },
	exported: { content: [], max: 15, total: 0, loading: false },
	columns: [
		{ key: "email", value: "Email" },
		{ value: "Filière", key: "filiere" },
		{ value: "Année", key: "studyYear" },
		{ value: "Rang", key: "rank" },
		{ value: "Pages", key: "trainingIds" }
	],
	filters: [
		{ key: "leads", value: "leads disponibles" },
		{ key: "unlocked", value: "leads débloqués" },
		{ key: "exported", value: "leads exportés" }
	],
	loaded: false,
	selected: "leads"
};

/**
 * Création du slice pour les leads
 */
export const leadsSlice = createSlice({
	name: "leads",
	initialState,
	reducers: {
		/**
		 * Définir les leads
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {Object} action.payload - Charge utile contenant les leads
		 */
		setLeads: (state, action) => {
			state.leads = { ...state.leads, ...action.payload.leads };
			state.unlocked = { ...state.unlocked, ...action.payload.unlocked };
			state.exported = { ...state.exported, ...action.payload.exported };
			state.loaded = true;
		},
		/**
		 * Ajouter plus de leads à la liste existante
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.type - Type de leads (leads, unlocked, exported)
		 * @param {Object} action.payload.leads - Leads à ajouter
		 */
		setMore: (state, action) => {
			state[action.payload.type].content = [
				...state[action.payload.type].content,
				...action.payload.leads.content
			];
			state[action.payload.type].total = action.payload.leads.total;
			state[action.payload.type].loading = false;
		},
		/**
		 * Définir l'état de chargement pour un type de leads
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.type - Type de leads (leads, unlocked, exported)
		 * @param {boolean} action.payload.loading - État de chargement
		 */
		setLeadLoading: (state, action) => {
			state[action.payload.type].loading = action.payload.loading;
		},
		/**
		 * Définir le filtre sélectionné
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - Filtre sélectionné
		 */
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		/**
		 * Déplacer des leads vers la liste exportée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {number} action.payload.skip - Nombre de leads à sauter
		 * @param {number} action.payload.max - Nombre maximum de leads à déplacer
		 * @param {string} action.payload.from - Liste d'origine des leads
		 */
		moveToExported(state, action) {
			const { skip, max, from } = action.payload;
			const leadsToMove = state[from].content.slice(skip, skip + max);
			state.exported.content = [...leadsToMove, ...state.exported.content];
			state.exported.total += leadsToMove.length;

			// Retirer les leads de la liste d'origine
			state[from].content = state[from].content.filter(
				(_, index) => index < skip || index >= skip + max
			);
			state[from].total -= leadsToMove.length;
		}
	}
});

/**
 * Actions exportées pour utilisation dans l'application
 */
export const leadsMethods = leadsSlice.actions;

import adminService from "../../../../services/admin/adminService";

export const RemoveTreated = () => {
	const service = adminService.useRemoveTreated();

	const handleClick = async () => {
		try {
			await service.mutateAsync();
		} catch (e) {}
	};
	return (
		<button
			disabled={service.isPending}
			onClick={() => handleClick()}
			style={{
				borderColor: "var(--orange)"
			}}>
			{service.isPending ? "Chargement..." : "Supprimer les demandes traitées de la liste"}
		</button>
	);
};

import { Link, useParams } from "react-router-dom";
import { Icon } from "../components/Icons/Icon";
import appico from "../assets/hopteo_manager.webp";
import ResetForm from "../components/Forms/Formulaires/ResetForm";

const ResetPassword = () => {
	const { token } = useParams();

	return (
		<div className="home-page page-container loginpage auth-page">
			<img src={appico} className={"logo-container"} />
			<div className="left home">
				<main>
					<Link to={"/"}>
						<Icon size={30} name={"arrow-left"} color={"var(--orange)"} />
						Retour à l'accueil
					</Link>

					<h1 className="bigtitle">Réinitialisez votre mot de passe !</h1>
				</main>
			</div>
			<div className="form-container">
				<ResetForm token={token} />
			</div>
		</div>
	);
};

export default ResetPassword;

import React, { useCallback, useEffect, useState } from "react";
import { getCroppedImg } from "../../../../utils/canva";
import Cropper from "react-easy-crop";
import "./crop.css";

export const CropperContainer = ({
	imageSrc,
	setImageSrc,
	handleSubmit,
	handleFileChange,
	cropSize,
	shape = "rect"
}) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setCrop({ x: 0, y: 0 });
		setZoom(1);
		setCroppedAreaPixels(null);
	}, []);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const showCroppedImage = useCallback(async () => {
		try {
			setIsLoading(true);
			const croppedFile = await getCroppedImg(imageSrc, croppedAreaPixels);
			const result = await handleSubmit(croppedFile);
			setImageSrc(null);
		} catch (e) {
			console.error(e);
			setImageSrc(null);
		}
		setIsLoading(false);
	}, [imageSrc, croppedAreaPixels, handleFileChange]);

	const onZoomChange = useCallback(newZoom => {
		if (newZoom >= 0.1 && newZoom <= 3) {
			setZoom(newZoom);
		}
	}, []);
	return (
		<div className="crop-container checkered-background">
			<div className="btn-container">
				<button
					onClick={() => setImageSrc(null)}
					style={{
						cursor: "pointer"
					}}
					disabled={isLoading}>
					Annuler
				</button>
				<button
					onClick={showCroppedImage}
					style={{
						cursor: "pointer"
					}}
					disabled={isLoading}>
					Confirmer
				</button>
			</div>

			<Cropper
				image={imageSrc}
				crop={crop}
				zoom={zoom}
				minZoom={0.1}
				maxZoom={3}
				cropShape={shape}
				aspect={1}
				onCropChange={setCrop}
				onCropComplete={onCropComplete}
				cropSize={cropSize}
				onZoomChange={onZoomChange}
			/>
		</div>
	);
};

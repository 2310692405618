import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial du slice Q&A.
 * @type {Object}
 * @property {Object} displayed - Questions affichées.
 * @property {Array} displayed.content - Liste des questions affichées.
 * @property {number} displayed.total - Nombre total de questions affichées.
 * @property {boolean} displayed.loading - Indicateur de chargement des questions affichées.
 * @property {number} displayed.max - Nombre maximum de questions affichées.
 * @property {Object} pending - Questions en attente.
 * @property {Object} pending.content - Contenu des questions en attente par filtre.
 * @property {Object} pending.totals - Totaux des questions en attente par filtre.
 * @property {boolean} pending.loading - Indicateur de chargement des questions en attente.
 * @property {Array} filters - Liste des filtres disponibles.
 * @property {string} activeFilter - Filtre actif.
 * @property {boolean} loaded - Indicateur de chargement initial.
 */
const initialState = {
	displayed: { content: [], total: 0, loading: true, max: 15 },
	pending: {
		content: {
			Tout: { content: [], total: 0, loading: true },
			Lu: { content: [], total: 0, loading: true },
			"Non lu": { content: [], total: 0, loading: true },
			Archive: { content: [], total: 0, loading: true }
		},
		totals: {
			Tout: 0,
			Lu: 0,
			"Non lu": 0,
			Archivé: 0
		},
		loading: true
	},
	filters: ["Tout", "Lu", "Non lu", "Archive"],
	activeFilter: "Tout",
	loaded: false
};

/**
 * Slice Q&A pour la gestion des questions et réponses.
 */
export const qnaSlice = createSlice({
	name: "qna",
	initialState,
	reducers: {
		/**
		 * Définit les questions affichées.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {Array} action.payload.content - Liste des questions à afficher.
		 * @param {number} action.payload.total - Nombre total de questions.
		 */
		setDisplayed: (state, action) => {
			const { content, total } = action.payload;
			state.displayed = {
				content: [...state.displayed.content, ...content] ?? state.displayed.content,
				total: total ?? state.displayed.total,
				loading: false
			};
		},
		/**
		 * Définit les questions en attente par filtre.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload.filter - Filtre des questions.
		 * @param {Array} action.payload.content - Liste des questions.
		 * @param {Object} action.payload.totals - Totaux des questions.
		 * @param {Array} action.payload.filters - Liste des filtres.
		 */
		setPendingByFilter: (state, action) => {
			const { filter, content, totals, filters } = action.payload;

			if (!state.pending.content[filter]) {
				state.pending.content[filter] = {
					content: [],
					loading: true
				};
			}

			state.pending.content[filter] = {
				content: [...state.pending.content[filter].content, ...content],
				loading: false
			};
			state.pending.totals = totals ?? state.pending.totals;
			state.filters = filters ?? state.filters;
		},
		/**
		 * Définit l'état de chargement pour un filtre spécifique.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload.filter - Filtre des questions.
		 */
		setLoadingForFilter: (state, action) => {
			const { filter } = action.payload;
			state.pending.content[filter].loading = true;
		},
		/**
		 * Définit le filtre actif.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload - Filtre actif.
		 */
		setActiveFilter: (state, action) => {
			if (!state.pending.content[action.payload]) {
				state.pending.content[action.payload] = {
					content: [],
					loading: true
				};
			}
			state.activeFilter = action.payload;
		},
		/**
		 * Ajoute une question.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {Object} action.payload - Question à ajouter.
		 */
		addQuestion: (state, action) => {
			state.displayed.content.unshift(action.payload);
			state.displayed.total += 1;
		},
		/**
		 * Supprime une question.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload.id - ID de la question à supprimer.
		 */
		deleteQuestion: (state, action) => {
			const { id } = action.payload;
			const index = state.displayed.content.findIndex(question => question._id === id);
			if (index !== -1) {
				state.displayed.content.splice(index, 1);
			}
			state.displayed.total -= 1;
		},
		/**
		 * Met à jour une question.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload.id - ID de la question à mettre à jour.
		 * @param {Object} action.payload.updates - Mises à jour de la question.
		 */
		patchQuestion: (state, action) => {
			const { id, updates } = action.payload;
			const index = state.displayed.content.findIndex(question => question._id === id);
			if (index !== -1) {
				state.displayed.content[index] = { ...state.displayed.content[index], ...updates };
			}
		},
		/**
		 * Sélectionne une question.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload.id - ID de la question à sélectionner.
		 * @param {string} action.payload.topic - Sujet de la question.
		 */
		selectQuestion: (state, action) => {
			const { id, topic } = action.payload;
			for (let key in state.pending.content) {
				const index = state.pending.content[key].content.findIndex(question => question._id === id);
				if (index !== -1) {
					const question = state.pending.content[key].content[index];
					state.pending.content[key].content.splice(index, 1);

					if (key === topic) {
						state.pending.totals[key] -= 1;
						state.displayed.total += 1;
						question.selected = true;
						question.firstEdit = true;
						state.displayed.content.unshift(question);
					} else {
						state.pending.totals[key] -= 1;
					}
				}
			}
		},
		/**
		 * Archive une question.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload.id - ID de la question à archiver.
		 * @param {string} action.payload.topic - Sujet de la question.
		 */
		archiveQuestion: (state, action) => {
			const { id, topic } = action.payload;

			let question;
			const index = state.displayed.content.findIndex(question => question._id === id);
			if (index !== -1) {
				question = state.displayed.content[index];
				state.displayed.content.splice(index, 1);
				state.displayed.total -= 1;
			}
			const alreadyInPending = state.pending.content["Archive"]?.content.some(q => q._id === id);
			if (!alreadyInPending && question) {
				question.archived = true;
				state.pending.content["Archive"].content.unshift(question);
			}
		},
		/**
		 * Marque une question comme lue.
		 * @param {Object} state - État actuel.
		 * @param {Object} action - Charge utile de l'action.
		 * @param {string} action.payload.id - ID de la question à marquer comme lue.
		 */
		markAsRead: (state, action) => {
			const { id } = action.payload;
			let questionFound = false;

			for (let key in state.pending.content) {
				const index = state.pending.content[key].content.findIndex(question => question._id === id);
				if (index !== -1) {
					state.pending.content[key].content[index].read = true;
					questionFound = true;
				}
			}

			if (questionFound) {
				const nonLuIndex = state.pending.content["Non lu"].content.findIndex(
					question => question._id === id
				);
				if (nonLuIndex !== -1) {
					const [question] = state.pending.content["Non lu"].content.splice(nonLuIndex, 1);
					state.pending.content["Lu"].content.unshift(question);

					state.pending.totals["Non lu"] -= 1;
					state.pending.totals["Lu"] += 1;
				}
			}
		}
	}
});

export const faqMethods = qnaSlice.actions;

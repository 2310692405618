import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { adminMethods } from "../../models/admin";
import apiCalls from "./apiCalls";
import { toast } from "react-toastify";
import { toastOptions } from "../../Functions";

/**
 * Service pour les opérations administratives.
 */
const adminService = {
	/**
	 * Utilise une mutation pour récupérer les données administratives.
	 * @returns {object} Mutation object
	 */
	useFetch(selectedType) {
		return useInfiniteQuery({
			queryKey: ["admin-clients", selectedType], // Clé de la requête
			queryFn: async ({ pageParam = 0 }) => {
				// Appel à l'API avec pagination
				const result = await apiCalls.fetch({
					skip: pageParam * 15,
					limit: 15,
					type: selectedType
				});
				return result;
			},
			getNextPageParam: (lastPage, pages) => {
				// Logique pour la page suivante
				if (selectedType === "actives") {
					if (lastPage.totalActives > pages.length * 15) {
						return pages.length; // Prochaine page
					} else {
						return undefined; // Fin de pagination
					}
				} else {
					if (lastPage.totalPendings > pages.length * 15) {
						return pages.length; // Prochaine page
					} else {
						return undefined; // Fin de pagination
					}
				}
			},
			staleTime: Infinity
		});
	},

	/**
	 * Utilise une mutation pour créer un nouvel élément administratif.
	 * @returns {object} Mutation object
	 */
	useCreate() {
		const queryClient = useQueryClient(); // Assurez-vous d'importer useQueryClient

		return useMutation({
			mutationFn: apiCalls.create,
			onSuccess: async isAdmin => {
				if (isAdmin) {
					toast.success("Compte admin créer avec succès !", toastOptions);
				} else {
					queryClient.invalidateQueries(["admin-clients"]);
					toast.success("Client ajouté avec succès !", toastOptions);
				}
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les structures.
	 * @returns {object} Mutation object
	 */
	useStructures() {
		return useMutation({
			mutationFn: apiCalls.structures,
			onSuccess: async data => {
				return data;
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les avis.
	 * @returns {object} Mutation object
	 */
	useAvis() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.avis,
			onSuccess: async data => {
				dispatch(adminMethods.setAvis(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les leads.
	 * @returns {object} Mutation object
	 */
	useLeads() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.leads,
			onSuccess: async data => {
				dispatch(adminMethods.setLeads(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour la modération des avis.
	 * @returns {object} Mutation object
	 */
	useModeration() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.moderation,
			onSuccess: async data => {
				dispatch(adminMethods.updateAvisStatus(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer une structure spécifique.
	 * @returns {object} Mutation object
	 */
	useStructure() {
		return useMutation({
			mutationFn: apiCalls.structure,
			onSuccess: async data => {
				if (window.location.pathname === "/") {
					window.location.reload();
				} else {
					window.location.replace("/");
				}
			}
		});
	},

	/**
	 * Utilise une requête pour récupérer les détails d'une structure sélectionnée.
	 * @param {string} selectedStructure - L'identifiant de la structure sélectionnée
	 * @returns {object} Query object
	 */
	useFetchStructureDetails(selectedStructure) {
		return useQuery({
			queryKey: ["structureDetails", selectedStructure],
			queryFn: () => apiCalls.structureDetails({ structureId: selectedStructure }),
			enabled: !!selectedStructure,
			staleTime: Infinity
		});
	},

	/**
	 * Utilise une mutation pour mettre à niveau une formation.
	 * @returns {object} Mutation object
	 */
	useUpgradeTraining() {
		return useMutation({
			mutationFn: apiCalls.upgradeTraining
		});
	},

	/**
	 * Utilise une requête pour récupérer le token.
	 * @returns {object} Query object
	 */
	useToken() {
		return useQuery({
			queryKey: ["capitaineStudyToken"],
			queryFn: apiCalls.fetchToken,
			enabled: (() => {
				const tokenExpiration = localStorage.getItem("capitaineStudyTokenExpiration");
				return !tokenExpiration || new Date() > new Date(tokenExpiration);
			})(),

			cacheTime: Infinity,
			staleTime: Infinity
		});
	},

	/**
	 * Utilise une mutation pour récupérer les structures par leads.
	 * @returns {object} Mutation object
	 */
	useStructuresByLeads() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.structuresByLeads,
			onSuccess: async data => {
				dispatch(adminMethods.setStructuresByLeads(data));
			}
		});
	},

	useRemoveTreated() {
		const dispatch = useDispatch();
		const toTreat = useSelector(state => state.admin.clients.treatedList);
		const queryClient = useQueryClient();
		return useMutation({
			mutationFn: () => apiCalls.removeTreated(toTreat), // Ici, on passe une fonction qui sera appelée plus tard
			onSuccess: async data => {
				// Mettre à jour le cache localement avant de réactualiser les données
				queryClient.setQueryData(["admin-clients", "actives"], oldData => {
					if (!oldData) return oldData; // Si pas de données dans le cache, on ne fait rien

					// Réduire le total des clients en attente (totalPendings) et retirer les éléments traités
					const updatedPages = oldData.pages.map(page => {
						return {
							...page,
							totalPendings: Math.max(0, page.totalPendings - toTreat.length) // Baisser le nombre total des clients en attente
						};
					});

					return {
						...oldData,
						pages: updatedPages
					};
				});

				// Réactualiser les données en invalidant la requête après avoir modifié le cache
				queryClient.invalidateQueries(["admin-clients"]);

				// Nettoyer la liste des éléments traités dans le store Redux
				dispatch(adminMethods.clearTreated());
			},
			onError: async error => {
				toast.error(error.message, toastOptions);
			}
		});
	}
};

export default adminService;

import { trainingsMethods } from "../../models/trainings";
import apiCalls from "./apiCalls";
import { toast } from "react-toastify";
import { toastOptions } from "../../Functions"; // Optionnel, si tu veux utiliser un toast pour les erreurs

/**
 * Logique métier pour la gestion des formations.
 */
const trainingsController = {
	/**
	 * Fonction pour récupérer les données des formations.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 * @returns {Promise<Object>} Les données des formations.
	 * @throws {Error} Lance une erreur si la récupération échoue.
	 */
	async fetchTrainings(dispatch) {
		try {
			const data = await apiCalls.fetch();
			dispatch(trainingsMethods.setTrainings(data));
			return data;
		} catch (error) {
			toast.error("Une erreur s'est produite lors de la récupération des formations", toastOptions);
		}
	},

	/**
	 * Logique métier pour mettre à jour les données d'une formation.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 * @param {Object} data - Les nouvelles données à mettre à jour.
	 */
	patchSuccess(dispatch, data) {
		dispatch(trainingsMethods.updateTrainingField(data));
	},

	/**
	 * Logique métier pour afficher un toast en cas d'erreur de mise à jour.
	 */
	patchError() {
		toast.error("Une erreur s'est produite lors de la mise à jour de la formation", toastOptions);
	},

	/**
	 * Fonction pour récupérer les publications d'une formation.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 * @param {string} trainingId - L'identifiant de la formation.
	 * @returns {Promise<Object>} Les données des publications.
	 * @throws {Error} Lance une erreur si la récupération échoue.
	 */
	async fetchPublications(dispatch, trainingId) {
		const data = await apiCalls.fetchPublications({ trainingId });
		dispatch(trainingsMethods.setPublications(data));
		return data;
	},

	/**
	 * Logique métier pour afficher un toast en cas d'erreur de récupération des publications.
	 * @param {Error} error - L'erreur à afficher.
	 */
	handleFetchPublicationsError(error) {
		toast.error("Une erreur s'est produite lors de la récupération des publications", toastOptions);
	},

	/**
	 * Logique métier pour mettre à jour les données d'une formation avec succès.
	 * @param {Function} dispatch - La fonction de dispatch pour mettre à jour le state.
	 * @param {Object} data - Les nouvelles données à mettre à jour.
	 */
	updateTrainingSuccess(dispatch, data) {
		dispatch(trainingsMethods.updateTrainingField(data));
	}
};

export default trainingsController;

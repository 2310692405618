import axios from "axios";

/**
 * URL de base pour les requêtes API.
 * @type {string}
 */

const URL = "";
/**
 * Client Axios configuré pour les requêtes de type multipart/form-data.
 * @type {AxiosInstance}
 */
const fileClient = axios.create({
	baseURL: URL,
	headers: {
		"Content-Type": "multipart/form-data"
	}
});

// Intercepteur pour ajouter le token d'authentification aux requêtes du fileClient
fileClient.interceptors.request.use(
	/**
	 * Fonction exécutée avant l'envoi de chaque requête.
	 * @param {object} config - Configuration de la requête.
	 * @returns {object} - Configuration de la requête modifiée.
	 */
	async config => {
		const token = localStorage.getItem("token"); // Assurez-vous que getToken retourne le token attendu.
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`; // Ajouter le token aux headers
		}
		return config;
	},
	/**
	 * Fonction exécutée en cas d'erreur lors de la configuration de la requête.
	 * @param {object} error - Erreur rencontrée.
	 * @returns {Promise<Error>} - Promesse rejetée avec l'erreur.
	 */
	error => {
		return Promise.reject(error);
	}
);

/**
 * Client Axios configuré pour les requêtes de type application/json.
 * @type {AxiosInstance}
 */
const apiClient = axios.create({
	baseURL: URL,
	headers: {
		"Content-Type": "application/json"
	}
});

// Intercepteur pour ajouter le token d'authentification aux requêtes du apiClient
apiClient.interceptors.request.use(
	/**
	 * Fonction exécutée avant l'envoi de chaque requête.
	 * @param {object} config - Configuration de la requête.
	 * @returns {object} - Configuration de la requête modifiée.
	 */
	async config => {
		const token = localStorage.getItem("token"); // Assurez-vous que getToken retourne le token attendu.
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`; // Ajouter le token aux headers
		}
		return config;
	},
	/**
	 * Fonction exécutée en cas d'erreur lors de la configuration de la requête.
	 * @param {object} error - Erreur rencontrée.
	 * @returns {Promise<Error>} - Promesse rejetée avec l'erreur.
	 */
	error => {
		return Promise.reject(error);
	}
);

export { fileClient, apiClient };

import { apiClient } from "../clients";
import { avisRoute } from "../../utils/APIRoutes";

/**
 * Récupère les avis depuis l'API.
 *
 * @async
 * @function fetchAvis
 * @param {Object} options - Les options pour la requête.
 * @param {number} [options.skip=0] - Le nombre d'éléments à ignorer.
 * @param {number} [options.max=10] - Le nombre maximum d'éléments à récupérer.
 * @param {string} [options.type] - Le type d'avis à récupérer.
 * @param {string} [options.trainingId] - L'identifiant de la formation.
 * @returns {Promise<Object>} Les données des avis récupérés.
 * @throws {Error} Si une erreur se produit lors de la requête.
 */
const fetchAvis = async ({ skip = 0, max = 10, type, trainingId }) => {
	try {
		const params = new URLSearchParams({ skip, max, type, trainingId }).toString();
		const response = await apiClient.get(`${avisRoute}?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Objet contenant les appels API.
 *
 * @type {Object}
 * @property {function} fetchAvis - Fonction pour récupérer les avis.
 */
const apiCalls = {
	fetchAvis
};

export default apiCalls;

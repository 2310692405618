import { ParamsSelector } from "../components/Params/Selector";
import { useLocation } from "react-router-dom";
import { Account } from "../components/Params/Account";
import Contact from "../components/Params/Contact";
import { Etablissement } from "../components/Params/Etablissement";
import { Bug } from "../components/Params/Bug";
import { Suggestions } from "../components/Params/Suggestions";

export const ParamsPage = () => {
	const location = useLocation();

	const render = () => {
		switch (location.pathname) {
			case "/params/account":
				return <Account />;
			case "/params/contact":
				return <Contact />;
			case "/params/etablissement":
				return <Etablissement />;
			case "/params/bug":
				return <Bug />;
			case "/params/suggestions":
				return <Suggestions />;
		}
	};

	return (
		<div className="fragment acc-frag">
			<div className="fragment_left" style={{ display: "flex", flexDirection: "row" }}>
				<ParamsSelector />
				<div
					style={{
						padding: "20px",
						width: "100%",
						maxHeight: "calc(100vh - 110px)",
						overflow: "scroll"
					}}>
					<div style={{ maxWidth: 750 }}>{render()}</div>
				</div>
			</div>
		</div>
	);
};

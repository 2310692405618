import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { trainingsMethods } from "../../models/trainings";
import { clientMethods } from "../../models/client";
import apiCalls from "./apiCalls";

/**
 * Service de gestion des fichiers.
 */
const filesService = {
	/**
	 * Utilise une mutation pour télécharger une publication.
	 * @returns {Mutation} La mutation de téléchargement.
	 */
	useUpload() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.uploadPublication,
			onSuccess: async data => {
				dispatch(trainingsMethods.addPublicationMethod(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour mettre à jour le bandeau.
	 * @returns {Mutation} La mutation de mise à jour du bandeau.
	 */
	usePatchBandeau() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.patchBandeau,
			onSuccess: async data => {
				dispatch(trainingsMethods.updateTrainingField(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour mettre à jour la photo de profil.
	 * @returns {Mutation} La mutation de mise à jour de la photo de profil.
	 */
	usePatchProfilePicture() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.patchPdp,
			onSuccess: async data => {
				dispatch(clientMethods.setPdp(data));
			}
		});
	}
};

export default filesService;

import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial pour le slice des avis
 * @type {Object}
 * @property {Object} verified - Avis vérifiés par formation
 * @property {number} max - Nombre maximum d'avis
 * @property {Array} filters - Filtres disponibles pour les avis
 * @property {string} selected - Filtre sélectionné
 */
const initialState = {
	verified: {},
	max: 15,
	filters: [{ key: "verified", value: "Avis vérifiés" }],
	selected: "verified"
};

/**
 * Création du slice pour les avis
 */
export const avisSlice = createSlice({
	name: "avis",
	initialState,
	reducers: {
		/**
		 * Début de la récupération des avis
		 * @param {Object} state - État actuel
		 */
		fetchReviewsStart: state => {
			state.loading = true;
		},
		/**
		 * Succès de la récupération des avis
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.trainingId - ID de la formation
		 * @param {Array} action.payload.avis - Liste des avis
		 * @param {number} action.payload.total - Nombre total d'avis
		 */
		fetchReviewsSuccess: (state, action) => {
			const { trainingId, avis, total } = action.payload;

			// Vérifier si le trainingId existe déjà
			if (state.verified[trainingId]) {
				// Ajouter les nouveaux avis aux avis existants
				state.verified[trainingId].avis = [...state.verified[trainingId].avis, ...avis];
				state.verified[trainingId].total = total; // Mettre à jour le total
			} else {
				// Créer un nouveau champ pour le trainingId
				state.verified[trainingId] = {
					avis,
					total,
					loading: false
				};
			}
		},
		/**
		 * Échec de la récupération des avis
		 * @param {Object} state - État actuel
		 */
		fetchReviewsFailure: state => {
			state.loading = false;
		},
		/**
		 * Définir le filtre sélectionné
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - Filtre sélectionné
		 */
		setSelected: (state, action) => {
			state.selected = action.payload;
		}
	}
});

/**
 * Actions exportées pour utilisation dans l'application
 */
export const avisMethods = avisSlice.actions;

import "./Buttons.css";
import adminService from "../../../../services/admin/adminService";
import { CircularLoader } from "../../../LoadingComponents/CircularLoader";
import { Icon } from "../../../Icons/Icon";
import { useEffect, useState } from "react";

export const Buttons = ({ type, identifiant, tokenCapitaineStudy }) => {
	const service = adminService.useModeration();
	const [pendingAction, setPendingAction] = useState(null);
	const [timeoutId, setTimeoutId] = useState(null);
	const [timeLeft, setTimeLeft] = useState(0);

	useEffect(() => {
		let intervalId;
		if (pendingAction) {
			setTimeLeft(3);
			intervalId = setInterval(() => {
				setTimeLeft(prevTime => {
					if (prevTime <= 1) {
						clearInterval(intervalId);
						return 0;
					}
					return prevTime - 1;
				});
			}, 1000);
		}

		return () => clearInterval(intervalId);
	}, [pendingAction]);

	const handlePatch = status => {
		if (!tokenCapitaineStudy) {
			return;
		}
		const id = setTimeout(async () => {
			await service.mutateAsync({
				status: status,
				avisId: identifiant,
				tokenCapitaineStudy: tokenCapitaineStudy
			});
			setPendingAction(null);
		}, 3000);

		setTimeoutId(id);
		setPendingAction(status);
	};

	const cancelPatch = () => {
		clearTimeout(timeoutId);
		setPendingAction(null);
	};

	return (
		<div className={"moderation hover"}>
			{service.isPending ? (
				<div style={{ margin: "auto", padding: 10 }}>
					<CircularLoader width={25} height={25} />
				</div>
			) : (
				<>
					{pendingAction ? (
						<button className={`cancel ${pendingAction}`} onClick={cancelPatch}>
							Cancel :{" " + timeLeft}s
						</button>
					) : (
						type === "pending" && (
							<>
								<button className={"button hover"} onClick={() => handlePatch("verified")}>
									<Icon name={"simple-check"} size={"16"} type={"filled"} />
								</button>
								<button className={"button hover"} onClick={() => handlePatch("refused")}>
									<Icon name={"simple-error"} size={"14"} type={"filled"} />
								</button>
							</>
						)
					)}
				</>
			)}
		</div>
	);
};

import { CircularLoader } from "../LoadingComponents/CircularLoader";
import React from "react";
import { FilterSelector } from "./FilterSelector";
import { Row } from "./Row";

export const Table = ({
	filters,
	fetchMore,
	onSelect,
	selected,
	columns = [],
	rows = [],
	total = 0,
	loading = false,
	rowsFormat,
	onRowClick,
	disabled
}) => {
	return (
		<>
			{filters && (
				<FilterSelector
					setFilter={onSelect}
					filters={filters}
					selected={selected}
					disabled={loading || disabled}
				/>
			)}

			<table className="custom-table">
				<thead>
					<tr>
						{columns.map((column, index) => (
							<th key={index}>{column}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows?.map((row, index) => (
						<Row row={row} key={index} rowsFormat={rowsFormat} onClick={onRowClick} />
					))}
				</tbody>
			</table>

			{loading && (
				<div
					style={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: 10
					}}>
					<CircularLoader height={50} width={50} />
				</div>
			)}

			{fetchMore && rows.length > 0 && rows.length < total && (
				<button onClick={() => fetchMore()} className={"btntab"} disabled={loading}>
					Voir plus
				</button>
			)}

			{rows.length === 0 && (
				<p style={{ width: "100%", padding: 10, textAlign: "center" }}>Pas encore de données.</p>
			)}
		</>
	);
};

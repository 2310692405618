export const Suggestions = () => {
	return (
		<>
			<h1>Une Suggestion ?</h1>
			<p style={{ marginTop: 10 }}>
				Vous avez une idée pour améliorer Hopteo Manager? Expliquez-nous !{" "}
			</p>
			<a
				style={{
					background: "var(--orange)",
					color: "white",
					marginTop: 10,
					textDecoration: "none",
					display: "block",
					width: "fit-content"
				}}
				className={"hover"}
				target={"__blank"}
				href={
					"https://docs.google.com/forms/d/e/1FAIpQLScxrvrC1o_obYuMr7Hf1eCjxwK7w8QYFxYEFpdN7DHFFKNgIg/viewform?usp=sf_link"
				}>
				Remplir le formulaire
			</a>
		</>
	);
};

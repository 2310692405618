import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./style.css";
import clientService from "../../../services/client/clientService";
import { GraphLoader } from "../../LoadingComponents/GraphLoader";

export const ParamField = ({ name, field, value, editable = true }) => {
	const [edit, setEdit] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, errors },
		setValue
	} = useForm({
		defaultValues: { fieldValue: value }
	});

	const service = clientService.usePatch();

	useEffect(() => {
		reset({ fieldValue: value });
	}, [value]);

	// Handle form submission
	const onSubmit = async data => {
		if (data.fieldValue === value) {
			setEdit(false);
			return;
		}

		try {
			// Perform your update logic here, e.g., sending data to a backend
			await service.mutateAsync({ value: data.fieldValue, field: field });
			reset(); // Optionally reset the form to the initial values
			setEdit(false);
		} catch (e) {
			console.log(e);
		}
	};

	const handleEditClick = event => {
		event.preventDefault(); // Just in case to prevent any default form submission
		setEdit(!edit);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="params-field hover"
			style={{ position: "relative" }}>
			{service.isPending && <GraphLoader />}

			<h3>{name}</h3>

			<div className="row" style={{ width: "100%", flexWrap: "nowrap", gap: 20 }}>
				{edit ? (
					<div style={{ width: "100%", position: "relative" }} className={"field"}>
						<input
							{...register("fieldValue", {
								required: "Ce champ ne peut pas être laissé vide." // Specify the error message directly
							})}
							type="text"
						/>
					</div>
				) : (
					<p>{value}</p>
				)}
				{edit ? (
					<div className={"row2"}>
						<button type="button" onClick={handleEditClick}>
							Annuler
						</button>
						<button type="submit" disabled={isSubmitting}>
							Enregistrer
						</button>
					</div>
				) : (
					editable && (
						<button type="button" onClick={handleEditClick}>
							Modifier
						</button>
					)
				)}
			</div>

			{edit && errors.fieldValue && <p className="error-message">{errors.fieldValue.message}</p>}
			{edit && service.error && <p className="error-message">{service.error.message}</p>}
		</form>
	);
};

import ReactGA from "react-ga4";

const TRACKING_ID = "G-JB9NSNDZSZ"; // Remplacez par votre propre ID de suivi
ReactGA.initialize(TRACKING_ID, {});

export const trackEvent = (name, action) => {
	ReactGA.event(name, {
		category: "Client",
		action: action
	});
};

// actions : "click, select, submit, save, change, toggle, edit, delete, close, open..."
// category : Training, Question, Answer, User, Client, Admin, ...
// label : trainingX , questionY, answerZ, userA, clientB, adminC, ...
export const trackEvent2 = (name, category, action, label, properties = {}) => {
	ReactGA.event(name, {
		category: category,
		action: action,
		label: label,
		...properties
	});
};

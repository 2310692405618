import { fileClient } from "../clients";
import { addPublication, editBandeau, editPdp } from "../../utils/APIRoutes";

/**
 * Télécharge une publication.
 * @async
 * @function uploadPublication
 * @param {Object} credentials - Les informations d'identification pour la publication.
 * @returns {Promise<Object>} Les données de la réponse.
 * @throws {Error} Affiche une erreur dans la console en cas d'échec.
 */
const uploadPublication = async credentials => {
	try {
		const response = await fileClient.post(addPublication, credentials);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Modifie le bandeau.
 * @async
 * @function patchBandeau
 * @param {Object} credentials - Les informations d'identification pour le bandeau.
 * @returns {Promise<Object>} Les données de la réponse.
 * @throws {Error} Affiche une erreur dans la console en cas d'échec.
 */
const patchBandeau = async credentials => {
	try {
		const response = await fileClient.post(editBandeau, credentials);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Modifie la photo de profil (PDP).
 * @async
 * @function patchPdp
 * @param {Object} credentials - Les informations d'identification pour la PDP.
 * @returns {Promise<Object>} Les données de la réponse.
 * @throws {Error} Affiche une erreur dans la console en cas d'échec.
 */
const patchPdp = async credentials => {
	try {
		const response = await fileClient.post(editPdp, credentials);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Objet contenant les appels API.
 * @typedef {Object} apiCalls
 * @property {Function} uploadPublication - Fonction pour télécharger une publication.
 * @property {Function} patchBandeau - Fonction pour modifier le bandeau.
 * @property {Function} patchPdp - Fonction pour modifier la photo de profil.
 */
const apiCalls = {
	uploadPublication,
	patchBandeau,
	patchPdp
};

export default apiCalls;

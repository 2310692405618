import { useSelector } from "react-redux";
import { useState } from "react";
import { DataSelector } from "../data/DataSelector";
import { Admission } from "./Admission";

export const Admissions = ({ selectedTraining }) => {
	const trainings = useSelector(state => state.trainings.content);
	const selectedTrainingData = trainings.find(training => training.trainingId === selectedTraining);
	const admission = selectedTrainingData?.admissionObj || {};

	const admissionData = Object.keys(admission).map((key, index) => ({
		key: key, // Utiliser l'index comme clé pour le composant selector
		value: `Année ${key?.replace("A", "")}` // Créer une valeur de type "AnnéeX"
	}));

	const [selectedType, setSelectedType] = useState(admissionData[0]?.key);
	const selectedAdmissionDetails = admission[selectedType];

	return (
		<div className={"admissions"}>
			<DataSelector
				selectedType={selectedType}
				setSelectedType={setSelectedType}
				data={admissionData}
			/>
			<div className={"container"}>
				{selectedAdmissionDetails?.map((detail, index) => (
					<Admission admission={detail} key={selectedType + index} />
				))}
			</div>
		</div>
	);
};

import "./toggle.css";
import { appMethods } from "../../../models/app";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent2 } from "../../../utils/ga";

const Toggle = () => {
	const app = useSelector(state => state.app);

	const dispatch = useDispatch();
	const client = useSelector(state => state.client);

	const handleClick = () => {
		dispatch(appMethods.setIsDark(!app.isDark));

		if (!app.isDark) {
			localStorage.setItem("dark_mode", "enabled");
		} else {
			localStorage.removeItem("dark_mode");
		}

		document.body.classList.toggle("dark_mode");

		if (!client.admin) {
			trackEvent2("toggle_event", "Header", app.isDark ? "off" : "on", "theme", {
				structureId: client.structureId,
				acronym: client.acronym
			});
		}
	};

	return (
		<div className="toggle-switch">
			<label className="switch-label">
				<input
					type="checkbox"
					className="checkbox"
					onChange={handleClick}
					checked={app.isDark}></input>
				<span className="slider"></span>
			</label>
		</div>
	);
};

export default Toggle;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GraphLoader } from "../../../LoadingComponents/GraphLoader";
import BarChart from "../../Graphs/BarChart";
import { Table } from "../../../Table/Table";
import {
	getMultilineChartData,
} from "../Strengths/utils";
import statsService from "../../../../services/stats/statsService";
import { statsMethods } from "../../../../models/metrics";
import PieChart from "../../Graphs/Pie/Pie";
import LineChart from "../../Graphs/Line/LineChart";
import { Column } from "../../Graphs/Column/Column";
import { sortDataDescending } from "../Visites/utils";
import MultiLineChart from "../../Graphs/Line/MultiLineChart";
import { NotPremium } from "../../Graphs/NotPremiums/Pie";
import { Icon } from "../../../Icons/Icon";
import { aggregateDataByKeywords,processStrengthData,getKeywordData } from "./utils";

export const Students = () => {
	const {
		from,
		to,
		students: { rows, loading, cards, selectedKeyword }
	} = useSelector(state => state.stats);
	const service = statsService.useStudents();
	const filters = useSelector(state => state.stats.filters);

	const isPremium = useSelector(state => state.client.admin);

	const dispatch = useDispatch();

	const fetch = async () => {
		await service.mutateAsync({ from, to });
	};

	useEffect(() => {
		if (loading && from && to && isPremium) {
			fetch();
		}
	}, [from, to, loading]);

	const { labels, data, tableRows, totalInteractions } = useMemo(() => {
		if (!rows || rows.length === 0 || !isPremium) {
			return { labels: [], data: [], tableRows: [], totalInteractions: 0 };
		}
		const aggregatedData = aggregateDataByKeywords(rows, cards, filters);

		if (!aggregatedData) {
			return { labels: [], data: [], tableRows: [], totalInteractions: 0 };
		}
		return processStrengthData(aggregatedData);
	}, [rows, cards, filters, isPremium]);


	const columns = ["Mot-clé", "Catégorie", "Likes","Superlikes", "Interactions totales", "Taux d'appréciation"];
	const rowsFormat = ["keyword", "category", "likes","super_likes", "totalInteractions", "percentage"];

	const options = {
		title: tooltipItems => "Catégorie - Mot clé - Likes/Superlikes - Interactions - Taux d'appréciation",
		label: tooltipItem => {
			return `${tooltipItem.label}`;
		}
	};

	const complements = {
		title: "Préférence des étudiants par mots-clés liés à votre établissement",
		titlex: "Catégorie - Mots clé",
		titley: "Taux d'appreciation",
		options: options,
		hiddeX: true
	};

	const [onTempo, setOnTempo] = useState(undefined);

	const handleTempo = type => {
		setOnTempo(type);
	};

	const onRowClick = data => {
		dispatch(statsMethods.setSelectedKeywordStudents(data));
	};

	const { keywordData, filteredRows } = useMemo(() => {
		if (!selectedKeyword || !isPremium) return {};
		const result = getKeywordData(rows, selectedKeyword.keyword, cards, filters, totalInteractions, {
			from,
			to
		});
		if (!result) return { keywordData: {}, filteredRows: [] };
		return result;
	}, [rows, selectedKeyword, cards, filters]);

	return (
		<div
			className={`rank-container strength-container ${isPremium ? "" : "locked-graph"}`}
			style={{ marginTop: 30, minHeight: 200, position: "relative" }}>
			<p style={{ textAlign: "center", marginBottom: 10 }}>
				<span style={{ fontFamily: "Inter-bold", fontSize:16 }}>
					Sur un total de {totalInteractions} interactions (échantillonnées)
				</span>
				<br />
				Cette métrique donne un aperçu global des préférences des étudiants sur Hopteo.
			</p>
			{loading && isPremium ? (
				<GraphLoader />
			) : isPremium ? (
				<>
					<BarChart labels={labels} data={data} complements={complements} />
					{!selectedKeyword ? (
						<div
							className="hover"
							style={{ width: "fit-content", marginTop: 15, transition: "border-color 0.2s" }}>
							<Table
								columns={columns}
								rows={tableRows}
								rowsFormat={rowsFormat}
								onRowClick={onRowClick}
							/>
						</div>
					) : (
						<div style={{ marginTop: 15 }}>
							{onTempo ? (
								<div className="onTemp">
									<h4
										className="row2"
										style={{ cursor: "pointer" }}
										onClick={() => setOnTempo(undefined)}>
										<Icon name="back" type="filled" size={24} />
										Retour
									</h4>
									<div className="graph" style={{ marginTop: 20 }}>
										{loading && <GraphLoader />}
										{isPremium && (
											<MultiLineChart
												metric={getMultilineChartData(onTempo, filteredRows, {
													from,
													to
												})}
											/>
										)}
									</div>
								</div>
							) : (
								keywordData.hasOwnProperty("region") && (
									<>
										<div className="row2 onTemp" style={{ alignItems: "baseline" }}>
											<h4
												className="row2"
												style={{ cursor: "pointer" }}
												onClick={() =>
													dispatch(
														statsMethods.setSelectedKeywordStudents(undefined)
													)
												}>
												<Icon name="back" type="filled" size={24} />
												Retour
											</h4>
											<div
												className={"one-row"}
												style={{ width: "fit-content", height: "fit-content" }}>
												<Table
													columns={columns}
													rows={[
														{
															...selectedKeyword
														}
													]}
													rowsFormat={rowsFormat}
												/>
											</div>
										</div>

										<div className="grid-container">
											<div className="row2" style={{ width: "100%" }}>
												<PieChart
													metric={keywordData?.region}
													loading={loading}
													title={`Régions des étudiants qui aiment : ${selectedKeyword.keyword}`}
													handleTempo={() => handleTempo("region")}
												/>
												<PieChart
													metric={keywordData?.filiere}
													loading={loading}
													title={`Filières des étudiants qui aiment : ${selectedKeyword.keyword}`}
													handleTempo={() => handleTempo("filiere")}
												/>
											</div>
											<div className="line-chart graph">
												{loading && <GraphLoader />}
												{keywordData?.inTime && (
													<LineChart metric={keywordData?.inTime} />
												)}
											</div>
											<Column
												loading={loading}
												label="Étudiants"
												title="Étudiants"
												metric={sortDataDescending(keywordData?.localisation)}
												handleTempo={() => handleTempo("localisation")}
											/>
										</div>
									</>
								)
							)}
						</div>
					)}
				</>
			) : (
				<NotPremium inTime={false} />
			)}
		</div>
	);
};

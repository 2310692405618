import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial pour le slice client
 * @type {Object}
 * @property {string} acronym - Acronyme du client
 * @property {string} email - Email du client
 * @property {string} profilePicture - URL de la photo de profil du client
 * @property {string} description - Description du client
 * @property {boolean} admin - Indicateur si le client est un administrateur
 * @property {string} adminAccount - Compte administrateur associé
 * @property {boolean} logged - Indicateur si le client est connecté
 * @property {boolean} loaded - Indicateur si les données du client sont chargées
 * @property {string|undefined} selectedTrainingPage - Page de formation sélectionnée
 */
const initialState = {
	acronym: "",
	email: "",
	profilePicture: "",
	description: "",
	admin: false,
	adminAccount: "",
	logged: false,
	loaded: false,
	selectedTrainingPage: undefined
};

/**
 * Création du slice pour le client
 */
export const clientSlice = createSlice({
	name: "client",
	initialState,
	reducers: {
		/**
		 * Définir l'état de chargement
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {boolean} action.payload - Indicateur de chargement
		 * @returns {Object} - Nouvel état
		 */
		setIsLoaded: (state, action) => {
			return { ...state, loaded: action.payload };
		},
		/**
		 * Définir les informations du client
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {Object} action.payload - Informations du client
		 * @returns {Object} - Nouvel état
		 */
		setClient: (state, action) => {
			return { ...action.payload, logged: true, loaded: true };
		},
		/**
		 * Mettre à jour un champ spécifique du client
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.field - Champ à mettre à jour
		 * @param {any} action.payload.value - Nouvelle valeur du champ
		 * @returns {Object} - Nouvel état
		 */
		setField: (state, action) => {
			return { ...state, [action.payload.field]: action.payload.value };
		},
		/**
		 * Définir la description du client
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - Nouvelle description
		 * @returns {Object} - Nouvel état
		 */
		setDescription: (state, action) => {
			return { ...state, description: action.payload };
		},
		/**
		 * Définir la photo de profil du client
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload - URL de la nouvelle photo de profil
		 * @returns {Object} - Nouvel état
		 */
		setPdp: (state, action) => {
			return { ...state, profilePicture: action.payload };
		},
		/**
		 * Définir la page de formation sélectionnée
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string|undefined} action.payload - Page de formation sélectionnée
		 * @returns {Object} - Nouvel état
		 */
		setSelectedTrainingPage: (state, action) => {
			return { ...state, selectedTrainingPage: action.payload };
		}
	}
});

/**
 * Actions exportées pour utilisation dans l'application
 */
export const clientMethods = clientSlice.actions;

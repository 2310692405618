import { statsMethods } from "../../models/metrics";

/**
 * Contrôleur pour gérer les statistiques.
 */
const statsController = {
	/**
	 * Gère le succès de la récupération des statistiques globales.
	 * @param {Function} dispatch - La fonction de dispatch.
	 * @param {Object} data - Les données des statistiques globales.
	 */
	fetchGlobalSuccess(dispatch, data) {
		dispatch(statsMethods.setGlobals(data));
		dispatch(statsMethods.setLoaded());
	},

	/**
	 * Gère l'erreur de la récupération des statistiques globales.
	 * @param {Function} dispatch - La fonction de dispatch.
	 */
	fetchGlobalError(dispatch) {
		dispatch(statsMethods.setLoaded());
	},

	/**
	 * Gère le succès de la récupération des rangs.
	 * @param {Function} dispatch - La fonction de dispatch.
	 * @param {Object} data - Les données des rangs.
	 */
	fetchRanksSuccess(dispatch, data) {
		dispatch(statsMethods.setRanks(data));
	},

	/**
	 * Gère le succès de la récupération des publications par formation.
	 * @param {Function} dispatch - La fonction de dispatch.
	 * @param {Object} data - Les données des publications par formation.
	 */
	fetchPublicationSuccess(dispatch, data) {
		dispatch(statsMethods.setPublicationsByTraining(data));
	},

	/**
	 * Gère le succès de la récupération des forces.
	 * @param {Function} dispatch - La fonction de dispatch.
	 * @param {Object} data - Les données des forces.
	 */
	fetchStrengthSuccess(dispatch, data) {
		dispatch(statsMethods.setStrengths(data));
	},

	/**
	 * Gère le succès de la récupération des forces.
	 * @param {Function} dispatch - La fonction de dispatch.
	 * @param {Object} data - Les données des forces.
	 */
	fetchStudentsSuccess(dispatch, data) {
		dispatch(statsMethods.setStudents(data));
	},

	/**
	 * Gère le succès de la récupération des similaires.
	 * @param {Function} dispatch - La fonction de dispatch.
	 * @param {Object} data - Les données des similaires.
	 */
	fetchSimilarsSuccess(dispatch, data) {
		dispatch(statsMethods.setSimilars(data));
	}
};

export default statsController;

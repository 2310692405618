
import moment from "moment";
import { processPieChartData } from "../Visites/utils";
import { getDatesArray,processLabels } from "../Strengths/utils";


export const aggregateDataByKeywords = (rows, cards = {}, filters) => {
	const aggregatedData = {};
	const filteredRows = applyFiltersToRows(rows, filters);

	// Vérifier la validité des rows après filtrage
	if (filteredRows.length === 0) return undefined;

	const selectedCategories = filters.categories.options
		.filter(option => option.selected)
		.map(option => option.label);

	// Parcourir les lignes filtrées et structurer les données
	filteredRows.forEach(row => {
		const cardId = row.dimensionValues[5]?.value;
		const likes = parseInt(row.metricValues[0]?.value);
		const swipeType = row.dimensionValues[4].value;

		// Obtenir la carte correspondante
		const card = cards[cardId];
		if (card) {
			const { cleanSubtopic, cleanMatchList } = card;

			// Vérifier la correspondance avec les catégories sélectionnées
			if (selectedCategories.length > 0 && !selectedCategories.includes(cleanSubtopic)) return;

			// Initialiser les données d'agrégation pour chaque sous-sujet et mot-clé
			if (!aggregatedData[cleanSubtopic]) {
				aggregatedData[cleanSubtopic] = {};
			}
			if (!aggregatedData[cleanSubtopic][cleanMatchList[0]]) {
				aggregatedData[cleanSubtopic][cleanMatchList[0]] = {
					likes: 0,
					dislikes : 0,
					dont_know:0,
					super_likes:0,
				};
			}
			switch (swipeType) {
				case "like":
					aggregatedData[cleanSubtopic][cleanMatchList[0]].likes += likes;
					break;
				case "dislike":
					aggregatedData[cleanSubtopic][cleanMatchList[0]].dislikes += likes;
					break;
				case "dont_know":
					aggregatedData[cleanSubtopic][cleanMatchList[0]].dont_know += likes;
					break;
				case "super_like":
					aggregatedData[cleanSubtopic][cleanMatchList[0]].super_likes += likes;
					break;
			}
		}
	});

	return aggregatedData;
};

export const processStrengthData = (aggregatedData) => {
	let categoryData = {};

	// Vérifie si les données agrégées existent
	if (!aggregatedData || Object.keys(aggregatedData).length === 0) {
		return { labels: [], data: [], tableRows: [], totalLikes: 0 };
	}

	let totalInteractions = 0;

	// Parcours chaque catégorie et chaque mot-clé dans les données agrégées
	Object.keys(aggregatedData)?.forEach(category => {
		if (!aggregatedData[category]) return;
		Object.keys(aggregatedData[category])?.forEach(keyword => {
			const key = `${category} - ${keyword}`;
			const cardData = aggregatedData[category][keyword];

			const totalForKeyword = cardData.likes + cardData.dont_know + cardData.super_likes+  cardData.dislikes;

			if (!categoryData[key]) {
				categoryData[key] = {
					totalInteractions: 0,
					likes: 0,
					super_likes : 0,
					category,
					keyword
				};
			}

			// Ajouter les interactions pour ce mot-clé
			categoryData[key].totalInteractions += totalForKeyword;
			categoryData[key].likes +=  (cardData.super_likes + cardData.likes) ;
			categoryData[key].super_likes +=  cardData.super_likes ;
			totalInteractions += totalForKeyword;
		});
	});

	// Prépare les données pour le graphique
	const labels = [];
	const data = [];
	const tableRows = [];

	// Trie les données par nombre de likes (counts) en ordre décroissant
	const sortedData = Object.entries(categoryData).sort((a, b) => {
		const rateA = (a[1].likes / a[1].totalInteractions) || 0;
		const rateB = (b[1].likes / b[1].totalInteractions) || 0;
		return rateB - rateA;
	});

	sortedData.forEach(([_, detail]) => {
		const percentage = (detail.likes / detail.totalInteractions * 100).toFixed(2);
		labels.push(`${detail.category} - ${detail.keyword} - ${detail.likes} - ${detail.totalInteractions} - ${percentage}%`);
		data.push(percentage);

		tableRows.push({
			keyword: detail.keyword,
			category: detail.category,
			likes: detail.likes,
			super_likes: detail.super_likes,
			totalInteractions: detail.totalInteractions,
			percentage: `${percentage}%`
		});
	});

	return {
		labels,
		data,
		tableRows,
		totalInteractions
	};
};
export const getKeywordData = (rows, keyword, cards, filters, totalLikes, date) => {
	const postFilter = applyFiltersToRows(rows, filters);

	const selectedCategories = filters.categories.options
		.filter(option => option.selected)
		.map(option => option.label);

	const allDates = getDatesArray(moment(date.from), moment(date.to));
	const emptyData = new Array(allDates.length).fill(0);

	// Structure des données pour Chart.js
	const keywordData = {
		region: { labels: [], datasets: [{ data: [] }] },
		filiere: { labels: [], datasets: [{ data: [] }] },
		localisation: { labels: [], datasets: [{ data: [] }] },
		inTime: { labels: allDates, datasets: [{ data: emptyData }] }
	};

	if (postFilter.length === 0) return undefined;

	// Filtrer les lignes en fonction de la correspondance avec le mot-clé dans les métadonnées des cartes
	const filteredRows = postFilter.filter(row => {
		const cardId = row.dimensionValues[5].value; // Extraire le cardId
		const card = cards[cardId]; // Accéder directement à la carte via cardId

		if (!card || !card.cleanMatchList) return false;

		// Appliquer le filtre de catégorie
		if (selectedCategories.length > 0 && !selectedCategories.includes(card.cleanSubtopic)) return false;

		// Vérifie si le mot-clé est dans la liste cleanMatchList de la carte
		return card.cleanMatchList.includes(keyword);
	});

	let totalLikesCount = 0;

	// Agréger les données par région, filière, localisation et date
	filteredRows.forEach(row => {
		const region = row.dimensionValues[0].value === "(not set)" ? "Autres" : row.dimensionValues[0].value;
		const filiere =
			row.dimensionValues[1].value === "(not set)" ? "Autres" : row.dimensionValues[1].value;
		const localisation =
			row.dimensionValues[2].value === "(not set)" ? "Autres" : row.dimensionValues[2].value;

		const date = row.dimensionValues[3].value;
		const count = parseInt(row.metricValues[0].value); // Nombre de likes
		totalLikesCount += count; // Accumuler le total des likes

		// Fonction d'ajout ou de mise à jour des entrées de dataset
		const updateDataset = (category, key, value) => {
			const index = keywordData[category].labels.indexOf(key);

			if (index === -1) {
				keywordData[category].labels.push(key);
				keywordData[category].datasets[0].data.push(value);
			} else {
				keywordData[category].datasets[0].data[index] += value;
			}
		};

		updateDataset("region", region, count);
		updateDataset("filiere", filiere, count);
		updateDataset("localisation", localisation, count);
		updateDataset("inTime", date, count);
	});

	// Préparer les données pour les graphiques de type pie chart
	keywordData.region = processPieChartData(processLabels(keywordData.region));
	keywordData.filiere = processPieChartData(processLabels(keywordData.filiere));

	const studentsCount = totalLikesCount;
	const taux = ((studentsCount / totalLikes) * 100).toFixed(2) + "%";

	return { keywordData, studentsCount, taux, filteredRows };
};



const applyFiltersToRows = (rows, filters) => {
	const selectedRegions = filters.region.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedFilieres = filters.filiere.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLocalisations = filters.localisation.options
		.filter(option => option.selected)
		.map(option => option.label);

	return rows.filter(row => {
		const region = row.dimensionValues[0].value;
		const filiere = row.dimensionValues[1].value;
		const localisation = row.dimensionValues[2].value;

		const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(region);
		const matchesFiliere = selectedFilieres.length === 0 || selectedFilieres.includes(filiere);
		const matchesLocalisation =
			selectedLocalisations.length === 0 || selectedLocalisations.includes(localisation);


		return matchesRegion && matchesFiliere && matchesLocalisation ;
	});
};
import { GraphLoader } from "../../../../LoadingComponents/GraphLoader";
import { Card } from "../Card/Card";
import React, { useEffect } from "react";
import statsService from "../../../../../services/stats/statsService";
import { useSelector } from "react-redux";
import { NotPremium } from "../../../Graphs/NotPremiums/Pie";

export const Closests = ({ selectedTraining, isPremium }) => {
	const service = statsService.useSimilars();
	const { content, loading } = useSelector(state => state.stats.similars.closests);

	const fetch = async () => {
		try {
			await service.mutateAsync("closests");
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (loading && isPremium) {
			fetch();
		}
	}, [isPremium]);

	return (
		<div style={{ position: "relative" }}>
			<h2>Ces formations sont souvent classés proche de vous</h2>
			{!isPremium ? (
				<div className={"locked-graph hover"} style={{ borderRadius: 10 }}>
					<NotPremium inTime={false} />
				</div>
			) : (
				<>
					{service.isPending && loading && <GraphLoader />}
					<div>
						{!service.isPending && content[selectedTraining ?? "all"]?.length === 0 && (
							<p style={{}}>Pas assez de données pour le moment...</p>
						)}

						{content[selectedTraining ?? "all"]?.map((training, index) => (
							<Card key={index} training={training} />
						))}
					</div>
				</>
			)}
		</div>
	);
};

// user Routes
export const loginRoute = `/api/auth/login`;
export const fetchClientData = `/api/auth/client`;
export const contactRoute = `/api/auth/contact`;
export const contactLandingRoute = `/api/auth/landingContact`;
export const editBandeau = `/api/trainings/bandeau`;
export const editPdp = `/api/auth/pdp`;
export const addPublication = "/api/trainings/publication";
export const registerRequestRoute = `/api/auth/register_request`;
export const passwordChangeRoute = `/api/auth/pwd`;
export const resetRequestRoute = `/api/auth/reset_request`;
export const resetPasswordRoute = `/api/auth/reset_password`;
export const qnaRoute = `/api/qna/`;

export const getImage = `/api/files/complete/`;

// Publications routes
export const addFile = `/api/auth/addFile`;

// Metrics Routes
export const metricsRoute = `/api/stats/`;

// Leads Routes
export const leadsRoute = `/api/leads/`;
export const dynamicLeadsRoute = `/api/leads/dynamic`;

// Admin Routes
export const adminRoute = `/api/admin`;

// avis routes
export const avisRoute = `/api/avis`;

// trainings routes
export const trainingsRoute = `/api/trainings/`;

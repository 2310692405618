import clientService from "../../services/client/clientService";
import { useEffect } from "react";
import { appMethods } from "../../models/app";
import { useDispatch } from "react-redux";

export const ClientLoader = () => {
	const { isLoading, isError } = clientService.useFetch(); // Pas besoin de data ici car déjà géré dans le hook
	const dispatch = useDispatch();

	useEffect(() => {
		if (localStorage.getItem("dark_mode") !== undefined && localStorage.getItem("dark_mode") !== null) {
			document.body.classList.add("dark_mode");
			dispatch(appMethods.setIsDark(true));
		}
	}, [dispatch]);

	return <></>; // Peut être adapté en fonction du rendu nécessaire
};

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import clientService from "../../../services/client/clientService";
import "../form.css";
import { AuthInput } from "../Input/AuthInput";
import { Icon } from "../../Icons/Icon";

const Register_Form = () => {
	const schema = yup.object().shape({
		structure: yup.string().required("Le nom de l'école est requis."),
		email: yup.string().email("Format d'email invalide").required("L'adresse email est requise."),
		phone: yup.string(),
		job: yup.string().required("Le poste est requis."),
		message: yup.string()
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
		resolver: yupResolver(schema)
	});

	const { mutateAsync, error, isPending } = clientService.useRegister();

	const onSubmit = async data => {
		try {
			await mutateAsync(data);
			reset();
		} catch (e) {}
	};
	return (
		<div className="login">
			<form onSubmit={handleSubmit(onSubmit)}>
				<Link to={"/"} className="responsive-back">
					<Icon size={30} name={"arrow-left"} color={"var(--orange)"} />
					Retour à l'accueil
				</Link>

				<Controller
					name="structure"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.structure}
							type={"text"}
							label={"Le nom de votre école"}
							required={true}
						/>
					)}
				/>

				<Controller
					name="email"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.email}
							type={"email"}
							label={"Votre adresse e-mail"}
							required={true}
						/>
					)}
				/>

				<Controller
					name="job"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.job}
							type={"text"}
							label={"Votre poste au sein de l'école"}
							required={true}
						/>
					)}
				/>

				<Controller
					name="phone"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.phone}
							type={"phone"}
							label={"Votre numéro de téléphone"}
						/>
					)}
				/>

				<Controller
					name="message"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.message}
							type={"textarea"}
							label={"Message complémentaire..."}
						/>
					)}
				/>

				{error && (
					<p className="error" style={{ position: "relative", marginBottom: 10 }}>
						{error?.message}
					</p>
				)}

				<button type="submit" disabled={isPending}>
					{isPending ? "Chargement..." : "Rejoindre la file"}
				</button>
				<div className="redirect">
					<p>Déjà un compte ?</p>

					<Link to="/login">Se connecter</Link>
				</div>
			</form>
		</div>
	);
};

export default Register_Form;

import { apiClient } from "../clients";
import { dynamicLeadsRoute, leadsRoute } from "../../utils/APIRoutes";

/**
 * Récupère une liste de leads.
 * @async
 * @function get
 * @param {Object} params - Les paramètres de la requête.
 * @param {number} params.max - Le nombre maximum de leads à récupérer.
 * @returns {Promise<Object>} Les données des leads.
 */
const get = async ({ max }) => {
	try {
		const params = new URLSearchParams({ max }).toString();

		const response = await apiClient.get(`${leadsRoute}?${params}`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Récupère plus de leads avec des paramètres supplémentaires.
 * @async
 * @function more
 * @param {Object} params - Les paramètres de la requête.
 * @param {number} params.skip - Le nombre de leads à ignorer.
 * @param {number} params.max - Le nombre maximum de leads à récupérer.
 * @param {string} params.type - Le type de leads à récupérer.
 * @returns {Promise<Object>} Les données des leads.
 */
const more = async ({ skip, max, type }) => {
	try {
		const params = new URLSearchParams({ skip, max, type }).toString();
		const response = await apiClient.get(`${dynamicLeadsRoute}?${params}`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Exporte les leads.
 * @async
 * @function exportRequest
 * @param {Object} data - Les données à exporter.
 * @param {string} data.format - Le format d'exportation.
 * @returns {Promise<Object>} Les données exportées et le format.
 * @throws {Error} Si une erreur se produit lors de l'exportation.
 */
const exportRequest = async data => {
	try {
		const response = await apiClient.post(`${leadsRoute}export`, data, {
			responseType: "blob"
		});
		return { data: response.data, format: data.format };
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Déverrouille les leads.
 * @async
 * @function unlockRequest
 * @returns {Promise<Object>} Les données des leads déverrouillés.
 * @throws {Error} Si une erreur se produit lors du déverrouillage.
 */
const unlockRequest = async () => {
	try {
		const response = await apiClient.get(`${leadsRoute}unlock`);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Objet contenant les appels API pour les leads.
 * @type {Object}
 * @property {function} get - Récupère une liste de leads.
 * @property {function} more - Récupère plus de leads avec des paramètres supplémentaires.
 * @property {function} exportRequest - Exporte les leads.
 * @property {function} unlockRequest - Déverrouille les leads.
 */
const apiCalls = {
	get,
	more,
	exportRequest,
	unlockRequest
};

export default apiCalls;

import { useState } from "react";

export const Images = ({ src, alt = "", styles, onClick }) => {
	const [loading, setLoading] = useState(true);

	return (
		<img
			src={src}
			alt={alt}
			onClick={onClick}
			onLoad={() => setLoading(false)}
			className={`${styles} ${loading ? "loadingbloc" : ""}`}
		/>
	);
};

import { useDispatch, useSelector } from "react-redux";
import { faqMethods } from "../../../models/Q&A";
import { trackEvent2 } from "../../../utils/ga";

export const Filters = () => {
	const filters = useSelector(state => state.qna.filters);

	return (
		<div
			className={"row2"}
			style={{
				paddingBottom: 10,
				maxWidth: "100%",
				width: "fit-content",
				overflowX: "auto", // Ensures horizontal scrolling if needed
				display: "flex",
				flexWrap: "nowrap"
			}}>
			{filters.map((filter, index) => (
				<Filter key={index} filter={filter} />
			))}
		</div>
	);
};

const Filter = ({ filter }) => {
	const dispatch = useDispatch();
	const activeFilter = useSelector(state => state.qna.activeFilter);
	const client = useSelector(state => state.client);

	const onClick = () => {
		dispatch(faqMethods.setActiveFilter(filter));
		if (!client.admin) {
			trackEvent2("select_event", "FAQ", "select", filter, {
				structureId: client.structureId,
				acronym: client.acronym,
				type: "filter"
			});
		}
	};
	return (
		<button
			onClick={() => onClick()}
			className={`${activeFilter === filter ? "selected" : ""}`}
			style={{
				width: "fit-content",
				whiteSpace: "nowrap" // Prevent text from wrapping
			}}>
			{filter}
		</button>
	);
};

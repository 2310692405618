const routeConfig = {
	"/": {
		title: "Hopteo Manager | Accueil",
		keywords:
			"accueil, hopteo, hopteo manager, écoles d’ingénieurs, classe prépa, CPGE, SCEI, CTI, classement, classement d’écoles, classement personnalisé, attirer des étudiants, mettre en avant son école, application mobile, prépa, CPGE, etudiant, télécharger, opteo, page école, visibilité +, visibilité plus, établissement scolaire, gérer, visualiser, prospection d’étudiants, inscription, connexion, offre commerciale, nos offres, vues de la page, lien vers site officiel, personnalisation de la page, statistiques avancées, publications de contenu, promotion, publicité ciblée, annonce, se démarquer, valoriser sa formation, excellence",
		description:
			"Hopteo Manager aide les entreprises et les écoles partenaires à gagner en visibilité sur l'application Hopteo en gérant leur compte et en créant des publicités"
	},

	"/2": {
		title: "Hopteo Manager | Mes pages Hopteo",
		keywords:
			"compte, hopteo manager, hopteo, nos offres, standard, gratuit, vues de votre page, liens vers votre site, page école customisable, statistiques avancées, publicités ciblés, visibilité +, visibilité plus",
		description:
			"Consultez vos informations de contact, les fonctionnalités de votre abonnement standard ou visibilité +, et visualisez en temps réel la page de profil de votre établissement scolaire."
	},

	"/login": {
		title: "Hopteo Manager | Connexion",
		keywords:
			"connexion, accéder à la page hopteo, hopteo manager, école, partenaire, client, école d’ingénieur, hopteo, personnaliser, vues de la page, lien vers site officiel, personnalisation de la page, statistiques avancées, publications de contenu, promotion, publicité ciblée, annonce",
		description:
			"Accédez au compte Hopteo Manager pour être visible auprès des étudiants avec l’offre Visibilité +."
	},
	"/register": {
		title: "Hopteo Manager | Inscription",
		keywords:
			"inscription, demande d’inscription, connexion, hopteo manager, école, partenaire, client, école d’ingénieur, hopteo, vues de la page, lien vers site officiel, personnalisation de la page, statistiques avancées, publications de contenu, promotion, publicité ciblée, annonce",
		description:
			"Réalisez une demande d’inscription pour votre établissement scolaire et personnalisez votre profil visible par les étudiants sur l’appli Hopteo avec l’offre Visibilité +."
	},
	"/public": {
		title: "Hopteo Manager | Ma page hopteo",
		keywords:
			"bandeau, adresse de contact, visibilité par les étudiants, publications, vidéos, photos, présentation de l’établissement, éditer, hopteo, hopteo manager, page hopteo, application hopteo, généraliste, localisation, concours, filière, rang médian, salaire en sortie, options de formation, débouchés, classe prépa, coût annuel, coût de la formation, sports, associations, clubs",
		description:
			"Personnalisez votre profil pour attirer les étudiants en ajoutant votre photo de couverture, votre logo, le lien vers votre site internet, des vidéos et des photos."
	},
	"/statistiques": {
		title: "Hopteo Manager | Statistiques",
		keywords:
			"statistiques, hopteo, hopteo manager, page hopteo, nombre de visites, nombre de favoris, nombre de clics vers la page web de l’école, graphique, analyse, statistiques des cartes, points forts, points faibles, localisation des visiteurs, filières des visiteurs, concurrents, mails envoyés",
		description:
			"Analysez les performances de votre établissement en visualisant le nombre de visites de votre page Hopteo, vos points forts et les vues de vos publications"
	},
	"/avis": {
		title: "Hopteo Manager | Avis",
		keywords:
			"hopteo manager, avis, étudiant, vérifiés, école, établissement scolaire, hopteo, hopteo manager, page hopteo, avis étudiants, avis vérifiés, école, établissement scolaire, visibilité, attractivité",
		description:
			"Consultez les avis des étudiants sur votre établissement scolaire pour améliorer votre visibilité et votre attractivité"
	},
	"/faq": {
		title: "Hopteo Manager | Questions",
		keywords:
			"questionnaire, hopteo manager, répondre, ajouter une question, fréquemment posé, présenter l’école, étudiants, questions d’étudiants, interrogations",
		description:
			"Ajoutez des FAQ sur votre page de profil Hopteo pour éclairer vos futurs étudiants et valoriser votre établissement scolaire"
	},
	"/leads": {
		title: "Hopteo Manager | Leads",
		keywords:
			"leads, qualité, étudiants, contact, formulaire, demande, inscription, contact, étudiant, prospect, école, établissement scolaire, hopteo, hopteo manager, page hopteo, formulaire de contact, formulaire de demande, formulaire d’inscription",
		description:
			"Consultez les demandes de contact des étudiants intéressés par votre établissement scolaire sur Hopteo"
	},
	"/reglages": {
		title: "Hopteo Manager | Réglages",
		keywords:
			"réglages, besoin d’aide, problème, signaler, bug, contacter, mail, coordonnées, politique de confidentialité, à propos d’hopteo",
		description:
			"Modifiez vos réglages, signalez des bugs, contactez-nous et consultez nos politiques de confidentialité."
	},
	"/offres": {
		title: "Hopteo Manager | Offres et abonnements",
		keywords: "offres, abonnements, premium, hopteo, hopteo manager, écoles partenaires, entreprises",
		description:
			"Découvrez les offres et abonnements Hopteo pour les écoles partenaires et les entreprises."
	}
};

export default routeConfig;

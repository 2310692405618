import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import leadsService from "../../../services/leads/leadsService";
import { CircularLoader } from "../../LoadingComponents/CircularLoader";
import { Icon } from "../../Icons/Icon";

const schema = yup.object().shape({
	number: yup.number().required(),
	format: yup.string().required(),
	skip: yup.number().required(),
	updateStatus: yup.boolean().required() // Ajout du champ pour la checkbox
});

export const ExportLeadsBtn = ({ max, styles, type, moveTo, isAdmin = true, structure = "" }) => {
	const [displayForm, setDisplayForm] = useState(false);

	const [maxAmount, setMaxAmount] = useState(0);

	const service = leadsService.useExport();

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		reset
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			number: maxAmount,
			format: "csv",
			skip: 0,
			updateStatus: !isAdmin // Valeur par défaut de la checkbox
		}
	});

	const onSubmit = async data => {
		try {
			if (
				data.number - data.skip > maxAmount ||
				data.number <= data.skip ||
				data.number - data.skip <= 0
			) {
				return;
			}

			const finalType = type;

			const result = await service.mutateAsync({ ...data, type: finalType, structure: structure });

			if (finalType !== "exported" && data.updateStatus) {
				moveTo(data.skip, data.number, finalType);
			}

			reset({
				number: maxAmount,
				format: "csv",
				skip: 0,
				updateStatus: !isAdmin
			});
		} catch (error) {
			console.error("Error exporting leads:", error);
		}

		setDisplayForm(false);
	};

	// Watch the form values to update the button text dynamically
	const numberValue = watch("number");
	const skipValue = watch("skip");
	const formatValue = watch("format");
	useEffect(() => {
		setMaxAmount(max);
		reset({
			number: max,
			format: "csv",
			skip: 0,
			updateStatus: !isAdmin
		});
	}, [max, reset]);

	return (
		<div style={styles} id={"exportbtn"}>
			<div className={"row2"}>
				<button
					onClick={e => {
						e.preventDefault();
						setDisplayForm(!displayForm);
						reset();
					}}
					type={"button"}
					className={"row2"}
					style={{
						borderColor: "var(--orange)",
						color: "var(--orange)"
					}}>
					<Icon name={"export"} size={18} color={"#EC8C27"} />
					{displayForm ? "Annuler" : "Exporter des leads"}
				</button>
				{service.error && <p style={{ color: "red" }}>{service.error?.message}</p>}
			</div>

			{displayForm && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<div style={{ gap: 15, display: "flex", flexDirection: "column" }}>
						<div className={"row2"}>
							<label>Selection de la plage :</label>
							de
							<Controller
								name="skip"
								control={control}
								render={({ field }) => (
									<input
										type="number"
										{...field}
										min={0}
										max={numberValue - 1}
										style={{ borderColor: errors.skip ? "red" : "initial" }}
									/>
								)}
							/>
							à
							<div>
								<Controller
									name="number"
									control={control}
									render={({ field }) => (
										<input
											type="number"
											{...field}
											max={maxAmount}
											min={0}
											style={{ borderColor: errors.number ? "red" : "initial" }}
										/>
									)}
								/>
							</div>
						</div>

						<div className={"row2"}>
							<label>Format :</label>
							<Controller
								name="format"
								control={control}
								render={({ field }) => (
									<select
										{...field}
										className={"hover"}
										style={{
											width: 100,
											borderColor: errors.format ? "red" : "initial"
										}}>
										<option value={"csv"}>.csv</option>
										<option value={"xlsx"}>.xlsx</option>
									</select>
								)}
							/>
						</div>

						{type !== "exported" && isAdmin && (
							<div className={"row2"}>
								<label>Mettre à jour le statut des leads ?</label>
								<Controller
									name="updateStatus"
									control={control}
									render={({ field }) => (
										<input
											type="checkbox"
											{...field}
											style={{ borderColor: errors.updateStatus ? "red" : "initial" }}
										/>
									)}
								/>
							</div>
						)}
					</div>
					<button
						type="submit"
						disabled={service.isPending}
						className={"row2"}
						style={{ marginTop: 20 }}>
						{service.isPending ? (
							<>
								<CircularLoader width={20} height={20} /> Export en cours...{" "}
							</>
						) : (
							<>
								{" "}
								Exporter {numberValue - skipValue} leads au format {formatValue}
							</>
						)}
					</button>
				</form>
			)}
		</div>
	);
};

import { apiClient } from "../clients";
import { qnaRoute } from "../../utils/APIRoutes";

/**
 * Envoie une requête POST pour créer une nouvelle entrée QnA.
 * @param {Object} credentials - Les informations d'identification pour la requête.
 * @returns {Promise<Object>} - Les données de la réponse.
 * @throws {Error} - Si une erreur survient lors de la requête.
 */
const post = async credentials => {
	try {
		const response = await apiClient.post(qnaRoute, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Envoie une requête PATCH pour mettre à jour une entrée QnA.
 * @param {Object} credentials - Les informations d'identification pour la requête.
 * @returns {Promise<Object>} - Les données de la réponse.
 * @throws {Error} - Si une erreur survient lors de la requête.
 */
const patch = async credentials => {
	try {
		const response = await apiClient.post(qnaRoute + "/patch", credentials);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les entrées QnA en attente.
 * @param {Object} params - Les paramètres de la requête.
 * @param {string} params.filter - Le filtre à appliquer.
 * @param {number} params.skip - Le nombre d'entrées à ignorer.
 * @param {number} params.max - Le nombre maximum d'entrées à récupérer.
 * @returns {Promise<Object>} - Les données de la réponse.
 */
const getPending = async ({ filter, skip, max }) => {
	const response = await apiClient.get(`${qnaRoute}pending`, {
		params: { filter, skip, max }
	});
	return response.data;
};

/**
 * Récupère les entrées QnA affichées.
 * @param {Object} params - Les paramètres de la requête.
 * @param {number} params.skip - Le nombre d'entrées à ignorer.
 * @param {number} params.max - Le nombre maximum d'entrées à récupérer.
 * @returns {Promise<Object>} - Les données de la réponse.
 */
const getDisplayed = async ({ skip, max }) => {
	const response = await apiClient.get(`${qnaRoute}displayed`, {
		params: { skip, max }
	});
	return response.data;
};

/**
 * Envoie une requête POST pour supprimer une entrée QnA.
 * @param {Object} credentials - Les informations d'identification pour la requête.
 * @param {string} credentials.questionId - L'ID de la question à supprimer.
 * @returns {Promise<Object>} - Les données de la réponse.
 * @throws {Error} - Si une erreur survient lors de la requête.
 */
const remove = async credentials => {
	try {
		const response = await apiClient.post(`${qnaRoute}delete/${credentials.questionId}`, credentials);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Objet contenant toutes les fonctions d'appel API pour le service QnA.
 */
const apiCalls = {
	post,
	patch,
	getPending,
	getDisplayed,
	remove
};

export default apiCalls;

import "./style.css";
import mines from "../../../pages/Landing/icons/mines.webp";
import ensg from "../../../pages/Landing/icons/ensg.webp";
import estia from "../../../pages/Landing/icons/estia.webp";
import { useRef } from "react";
import { motion, useInView } from "framer-motion";

export const Partners = () => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true, margin: "-50px" });

	return (
		<section id={"section4"} ref={ref}>
			<h1>Ils nous font confiance</h1>

			<div className={"row2"}>
				<motion.div
					initial={{ opacity: 0, scale: 0 }}
					exit={{ opacity: 0, scale: 0 }}
					transition={{ ease: "easeOut", duration: 0.4 }}
					animate={isInView ? { opacity: 1, scale: 1 } : {}}
					className={"card"}>
					<img src={mines} alt={"hopteo manager patner"} />
				</motion.div>

				<motion.div
					initial={{ opacity: 0, scale: 0 }}
					exit={{ opacity: 0, scale: 0 }}
					transition={{ ease: "easeOut", duration: 0.4, delay: 0.2 }}
					animate={isInView ? { opacity: 1, scale: 1 } : {}}
					className={"card"}>
					<img src={ensg} alt={"hopteo manager patner"} />
				</motion.div>

				<motion.div
					initial={{ opacity: 0, scale: 0 }}
					exit={{ opacity: 0, scale: 0 }}
					transition={{ ease: "easeOut", duration: 0.4, delay: 0.4 }}
					animate={isInView ? { opacity: 1, scale: 1 } : {}}
					className={"card"}>
					<img src={estia} alt={"hopteo manager patner"} />
				</motion.div>
			</div>
		</section>
	);
};

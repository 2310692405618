import { Treated } from "../Admin/Treated/Treated";

export const Row = ({ row, rowsFormat, onClick }) => {
	const nativeClick = e => {
		e.stopPropagation();
		if (!onClick) return;
		onClick(row);
	};
	return (
		<tr onClick={e => nativeClick(e)}>
			{rowsFormat?.map((column, index) => (
				<td key={index}>
					{column === "treated" ? <Treated parentId={row._id} /> : row && row[column]}
				</td>
			))}
		</tr>
	);
};

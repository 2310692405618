import "./App.css";
import { Provider } from "react-redux";

import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store } from "./models/store";
import { ClientLoader } from "./components/Client/ClientLoader";
import { HelmetComponent } from "./components/SEO/Helmet";
import { Wrapper } from "./Wrapper";

const queryClient = new QueryClient();

function App() {
	const location = useLocation();
	const appRef = useRef(undefined);

	useEffect(() => {
		if (appRef.current) {
			appRef.current.scrollTo(0, 0);
		}
	}, [location]);

	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<ClientLoader />
				<div className={"App"}>
					<HelmetComponent location={location} />
					<ToastContainer progressClassName="toastProgress" />
					<Wrapper appRef={appRef} />
				</div>
			</Provider>
		</QueryClientProvider>
	);
}

export default App;

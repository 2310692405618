import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import apiCalls from "./apiCalls";
import qnaController from "./qnaController";

/**
 * Service pour gérer les opérations de Q&A.
 */
const qnaService = {
	/**
	 * Utilise une mutation pour obtenir les questions en attente.
	 * @returns {object} - La mutation de react-query.
	 */
	usePending() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.getPending,
			onMutate: ({ filter }) => qnaController.onPendingMutate(dispatch, filter),
			onSuccess: (data, { filter }) => qnaController.onPendingSuccess(dispatch, data, filter)
		});
	},

	/**
	 * Utilise une mutation pour obtenir les questions affichées.
	 * @returns {object} - La mutation de react-query.
	 */
	useDisplayed() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.getDisplayed,
			onSuccess: data => qnaController.onDisplayedSuccess(dispatch, data)
		});
	},

	/**
	 * Utilise une mutation pour créer une nouvelle question.
	 * @returns {object} - La mutation de react-query.
	 */
	useCreate() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.post,
			onSuccess: async data => qnaController.onCreate(dispatch, data)
		});
	},

	/**
	 * Utilise une mutation pour mettre à jour une question existante.
	 * @returns {object} - La mutation de react-query.
	 */
	usePatch() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.patch
		});
	},

	/**
	 * Utilise une mutation pour supprimer une question.
	 * @returns {object} - La mutation de react-query.
	 */
	useDelete() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.remove,
			onSuccess: async data => qnaController.onDelete(dispatch, data)
		});
	}
};

export default qnaService;

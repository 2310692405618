import { CircularLoader } from "./CircularLoader";

export const GraphLoader = () => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)"
			}}>
			<CircularLoader width={60} height={60} />
		</div>
	);
};

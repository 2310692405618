export const cumulRanks = ranks => {
	const cumulativeData = {};
	const allLabels = new Set();
	const allRankValues = [];
	let rankCount = 0; // Initialize a counter for the total number of ranks

	// Collect all unique labels (ranks) and all rank values
	Object.values(ranks.datasets).forEach(dataset => {
		dataset.labels.forEach(label => {
			allLabels.add(label);
		});
		dataset.data.forEach((count, index) => {
			rankCount += count; // Add the count of ranks to the total

			for (let i = 0; i < count; i++) {
				allRankValues.push(parseInt(dataset.labels[index], 10));
			}
		});
	});

	// Initialize cumulative data for each label
	allLabels.forEach(label => {
		cumulativeData[label] = 0;
	});

	// Sum the counts for each label across all trainings
	Object.values(ranks.datasets).forEach(dataset => {
		dataset.labels.forEach((label, index) => {
			cumulativeData[label] += dataset.data[index];
		});
	});

	// Calculate average and median
	const averageRank = Math.round(allRankValues.reduce((acc, rank) => acc + rank, 0) / allRankValues.length);

	const calculateMedian = arr => {
		const sorted = arr.slice().sort((a, b) => a - b);
		const mid = Math.floor(sorted.length / 2);
		return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
	};

	const medianRank = Math.round(calculateMedian(allRankValues));

	return {
		labels: Array.from(allLabels),
		data: Array.from(allLabels).map(label => cumulativeData[label]),
		averageRank,
		medianRank,
		rankCount
	};
};

import { faqMethods } from "../../models/Q&A";

const qnaController = {
	onPendingMutate: (dispatch, filter) => {
		dispatch(faqMethods.setLoadingForFilter({ filter }));
	},
	onPendingSuccess: (dispatch, data, filter) => {
		dispatch(faqMethods.setPendingByFilter({ ...data, filter: filter }));
	},
	onDisplayedSuccess: (dispatch, data) => {
		dispatch(faqMethods.setDisplayed(data));
	},
	onCreate: async (dispatch, data) => {
		dispatch(faqMethods.addQuestion(data));
	},
	onDelete: async (dispatch, data) => {
		dispatch(faqMethods.deleteQuestion({ id: data._id }));
	}
};

export default qnaController;

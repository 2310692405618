import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import apiCalls from "./apiCalls";
import statsController from "./statsController";

/**
 * Service pour gérer les statistiques.
 */
const statsService = {
	/**
	 * Utilise une mutation pour récupérer les statistiques globales.
	 * @returns {object} - La mutation de `react-query` pour récupérer les statistiques globales.
	 */
	useFetchGlobal() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchGlobals,
			onSuccess: async data => statsController.fetchGlobalSuccess(dispatch, data),
			onError: error => statsController.fetchGlobalError(dispatch)
		});
	},

	/**
	 * Utilise une mutation pour récupérer les rangs.
	 * @returns {object} - La mutation de `react-query` pour récupérer les rangs.
	 */
	useRanks() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchRanks,
			onSuccess: async data => statsController.fetchRanksSuccess(dispatch, data)
		});
	},

	/**
	 * Utilise une mutation pour récupérer les publications.
	 * @returns {object} - La mutation de `react-query` pour récupérer les publications.
	 */
	usePublications() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchPublications,
			onSuccess: async data => statsController.fetchPublicationSuccess(dispatch, data)
		});
	},

	/**
	 * Utilise une mutation pour récupérer les forces.
	 * @returns {object} - La mutation de `react-query` pour récupérer les forces.
	 */
	useStrengths() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchStrengths,
			onSuccess: async data => statsController.fetchStrengthSuccess(dispatch, data)
		});
	},

	/**
	 * Utilise une mutation pour récupérer le zoom étudiant.
	 * @returns {object} - La mutation de `react-query` pour récupérer les forces.
	 */
	useStudents() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchStudents,
			onSuccess: async data => statsController.fetchStudentsSuccess(dispatch, data)
		});
	},

	/**
	 * Utilise une mutation pour récupérer les similaires.
	 * @returns {object} - La mutation de `react-query` pour récupérer les similaires.
	 */
	useSimilars() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchSimilars,
			onSuccess: async data => statsController.fetchSimilarsSuccess(dispatch, data)
		});
	}
};

export default statsService;

import { useSelector } from "react-redux";
import { TrainingInRow } from "../components/Client/Trainings/TrainingInRow";
import React, { useState } from "react";
import PricingTable from "../components/Offre/PricingTable";

export const Offres = () => {
	const initialDisplayCount = 3;
	const [displayCount, setDisplayCount] = useState(initialDisplayCount);

	const trainings = useSelector(state => state.trainings.content);

	const showMoreTrainings = () => {
		setDisplayCount(prevCount => prevCount + 3); // Augmenter de 3 chaque fois
	};

	return (
		<div className="fragment etablissement structure" style={{ paddingBottom: 20 }}>
			<div className="fragment_left stats">
				<h2 style={{ fontFamily: "Inter-SemiBold" }}>Abonnements</h2>
				<div className={"trainings-container"} style={{ maxWidth: 750 }}>
					{trainings.slice(0, displayCount).map((training, index) => (
						<TrainingInRow training={training} index={index} key={training.trainingId} />
					))}
				</div>
				{trainings.length > displayCount && (
					<button
						onClick={showMoreTrainings}
						style={{ marginTop: 10, color: "#3784EB" }}
						className={"btntab"}>
						...Voir plus
					</button>
				)}
			</div>
			<h2 style={{ marginTop: 30, marginBottom: 10, fontFamily: "Inter-SemiBold" }}>Nos offres</h2>
			<PricingTable />
		</div>
	);
};

import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { leadsMethods } from "../../models/leads";
import apiCalls from "./apiCalls";

/**
 * Service pour gérer les opérations liées aux leads.
 */
const leadsService = {
	/**
	 * Utilise une mutation pour récupérer les leads.
	 * @returns {object} Mutation object de react-query.
	 */
	useFetch() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.get,
			onSuccess: async data => {
				dispatch(leadsMethods.setLeads(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer plus de leads.
	 * @returns {object} Mutation object de react-query.
	 */
	useFetchMore() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.more,
			onSuccess: async data => {
				dispatch(leadsMethods.setMore(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour exporter les leads.
	 * @returns {object} Mutation object de react-query.
	 */
	useExport() {
		return useMutation({
			mutationFn: apiCalls.exportRequest,
			onSuccess: async ({ data, format }) => {
				const blob = new Blob([data], {
					type:
						format === "csv"
							? "text/csv"
							: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `leads.${format}`);
				document.body.appendChild(link);
				link.click();
			}
		});
	},

	/**
	 * Utilise une mutation pour déverrouiller les leads.
	 * @returns {object} Mutation object de react-query.
	 */
	useUnlock() {
		return useMutation({
			mutationFn: apiCalls.unlockRequest,
			onSuccess: async data => {
				window.location.href = data.url;
			}
		});
	}
};

export default leadsService;

import React, { useEffect } from "react";
import { Structure } from "../components/Client/Structure/Structure";
import { Selector } from "../components/Client/Trainings/selector/Selector";
import { SummaryStats } from "../components/Client/Trainings/summaryStats/SummaryStats";
import { Couverture } from "../components/Client/Trainings/couverture/Couverture";
import { LinkPage } from "../components/Client/Trainings/link/LinkPage";
import { Publications } from "../components/Client/Trainings/Publications/Publications";
import { useDispatch, useSelector } from "react-redux";
import { DisplayData } from "../components/Client/Trainings/data/DisplayData";
import { Admissions } from "../components/Client/Trainings/admissions/Admissions";
import { CircularLoader } from "../components/LoadingComponents/CircularLoader";
import { countPages } from "../components/Client/utils";
import { clientMethods } from "../models/client";
import { UpgradeBtn } from "../components/Stats/Graphs/NotPremiums/UpgradeBtn";

const Account = () => {
	const trainings = useSelector(state => state.trainings);

	const selectedTraining = useSelector(state => state.client.selectedTrainingPage);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!selectedTraining && trainings.content.length > 0) {
			dispatch(clientMethods.setSelectedTrainingPage(trainings.content[0]?.trainingId));
		}
	}, [trainings.loaded, trainings.content]);

	const counts = selectedTraining && countPages(trainings.content || []);

	const setSelectedTraining = trainingId => {
		dispatch(clientMethods.setSelectedTrainingPage(trainingId));
	};

	return (
		<>
			<div className="fragment acc-frag">
				<div className="fragment_left">
					<Structure />
					{selectedTraining && (
						<div
							className={"row2"}
							style={{ alignItems: "center", marginTop: 30, gap: 50, flexWrap: "wrap" }}>
							<h4>
								{counts?.premiums} pages premium, {counts?.normals} pages classiques
							</h4>
							{counts?.normals > 0 && <UpgradeBtn />}
						</div>
					)}

					<div style={{ marginTop: 30 }}>
						{selectedTraining ? (
							<>
								<div className={"row"} style={{ gap: "10px 300px", width: "fit-content" }}>
									<Selector
										selectedTraining={selectedTraining}
										setSelectedTraining={setSelectedTraining}
									/>
									{
										//    <button>+ Ajouter une formation</button>
									}
								</div>
								<div style={{ marginTop: 60, marginBottom: 60 }}>
									<SummaryStats selectedTraining={selectedTraining} />
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										marginTop: 30,
										width: "700px",
										gap: "5px"
									}}>
									<h3>Photo de couverture :</h3>
									{selectedTraining ? (
										<Couverture selectedTraining={selectedTraining} />
									) : (
										<h3>Loading...</h3>
									)}
								</div>
								<div style={{ marginTop: 20 }}>
									{selectedTraining ? (
										<LinkPage selectedTraining={selectedTraining} />
									) : (
										<h3>Loading...</h3>
									)}
								</div>

								<div style={{ marginTop: 30 }}>
									{selectedTraining ? (
										<Publications selectedTraining={selectedTraining} />
									) : (
										<h3>Loading...</h3>
									)}
								</div>

								<div style={{ marginTop: 80 }}>
									<h3
										style={{
											borderBottom: "2px solid var(--orange)",
											paddingBottom: 10
										}}>
										Données affichées sur votre page de formation :
									</h3>
									{selectedTraining ? (
										<DisplayData selectedTraining={selectedTraining} />
									) : (
										<h3>Loading...</h3>
									)}
								</div>

								<div style={{ marginTop: 40 }}>
									<p style={{ fontFamily: "Inter-Bold" }}>Voies d’admissions :</p>
									<div style={{ marginTop: 10 }}>
										{selectedTraining ? (
											<Admissions selectedTraining={selectedTraining} />
										) : (
											<h3>Loading...</h3>
										)}
									</div>
								</div>
							</>
						) : (
							<div
								style={{
									display: "flex",
									width: "100%",
									height: "400px",
									justifyContent: "center",
									alignItems: "center"
								}}>
								<CircularLoader width={80} height={80} />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Account;

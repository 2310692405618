import moment from "moment";

// Main function to get publication data
// Main function to get publication data
export const getPublicationData = (selectedPublication, filters, from, to) => {
	if (!selectedPublication?.metrics) {
		return {
			labels: [],
			datasets: [
				{
					label: "Nombres de vues de la publication",
					data: [],
					borderColor: "rgba(55, 132, 235, 1)",
					backgroundColor: "rgba(55, 132, 235, 0.5)",
					fill: false,
					tension: 0.1
				}
			]
		};
	}

	// Filter metrics based on active filters
	let filteredMetrics = applyFiltersToPublication(selectedPublication.metrics, filters);

	// Filter by the date range
	filteredMetrics = filteredMetrics.filter(metric => {
		const metricDate = moment(metric.date, "YYYYMMDD");
		return (
			metricDate.isSameOrAfter(moment(from, "YYYY-MM-DD")) &&
			metricDate.isSameOrBefore(moment(to, "YYYY-MM-DD"))
		);
	});

	// Create a complete date range from `from` to `to`
	const completeDateRange = generateDateRange(from, to);

	// Map to store counts per date
	const dateToEventCountMap = completeDateRange.reduce((acc, date) => {
		acc[date] = 0; // Initialize all dates with zero count
		return acc;
	}, {});

	// Populate the map with actual event counts
	filteredMetrics.forEach(metric => {
		const formattedDate = moment(metric.date, "YYYYMMDD").format("YYYYMMDD");
		if (formattedDate in dateToEventCountMap) {
			dateToEventCountMap[formattedDate] += metric.eventCount;
		}
	});

	return {
		labels: completeDateRange,
		datasets: [
			{
				label: "Nombres de vues de la publication",
				data: completeDateRange.map(date => dateToEventCountMap[date]),
				borderColor: "rgba(55, 132, 235, 1)",
				backgroundColor: "rgba(55, 132, 235, 0.5)",
				fill: false,
				tension: 0.1
			}
		]
	};
};
export const applyFiltersToPublication = (data, filters) => {
	const selectedRegions = filters.region.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedFilieres = filters.filiere.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLocalisations = filters.localisation.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLikes = filters.likes.options.filter(option => option.selected).map(option => option.label);

	return data.filter(metric => {
		const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(metric.region);
		const matchesFiliere = selectedFilieres.length === 0 || selectedFilieres.includes(metric.filiere);
		const matchesLocalisation =
			selectedLocalisations.length === 0 || selectedLocalisations.includes(metric.localisation);
		const matchesLikes =
			selectedLikes.length === 0 ||
			(selectedLikes.includes("Avec likes") && metric.isLiked) ||
			(selectedLikes.includes("Sans likes") && !metric.isLiked);

		return matchesRegion && matchesFiliere && matchesLocalisation && matchesLikes;
	});
};

const generateDateRange = (start, end) => {
	const startDate = moment(start, "YYYY-MM-DD");
	const endDate = moment(end, "YYYY-MM-DD");
	const dates = [];

	while (startDate.isSameOrBefore(endDate)) {
		dates.push(startDate.format("YYYYMMDD"));
		startDate.add(1, "days");
	}
	return dates;
};

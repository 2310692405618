import React, { useRef, useState } from "react";
import { Images } from "../../Images/Images";
import { getImage } from "../../../utils/APIRoutes";
import defaultpdp from "../../../assets/defaultpdp.png";
import filesService from "../../../services/files/filesService";
import { CropperContainer } from "./Crop/Crop";
import { useSelector } from "react-redux";

export const ProfilePicture = () => {
	const client = useSelector(state => state.client);

	const [imageSrc, setImageSrc] = useState(null);

	const inputRef = useRef(null);
	const { mutateAsync, isPending } = filesService.usePatchProfilePicture();

	const handleSubmit = async file => {
		if (file) {
			const formData = new FormData();
			formData.append("file", file);
			formData.append("token", localStorage.getItem("token"));

			try {
				await mutateAsync(formData);
			} catch (error) {
				console.error("Failed to upload file:", error);
			}
		}
	};

	const triggerFileSelectPopup = () => inputRef.current.click();

	const handleFileChange = event => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();

			reader.addEventListener("load", e => setImageSrc(e.target.result));

			reader.readAsDataURL(event.target.files[0]);
			event.target.value = ""; // Ajoutez cette ligne pour réinitialiser l'élément d'entrée
		}
	};

	return (
		<div>
			<input
				type="file"
				ref={inputRef}
				style={{ display: "none" }}
				accept="image/*"
				onChange={handleFileChange}
			/>
			<div onClick={triggerFileSelectPopup}>
				<Images
					alt={"pdp"}
					src={client?.profilePicture ? getImage + client.profilePicture : defaultpdp}
					styles={"pdp editable"}
				/>
			</div>
			{imageSrc && (
				<CropperContainer
					imageSrc={imageSrc}
					setImageSrc={setImageSrc}
					handleSubmit={handleSubmit}
					handleFileChange={handleFileChange}
					cropSize={{ width: 300, height: 300 }}
					shape={"round"}
				/>
			)}
		</div>
	);
};

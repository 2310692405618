import { apiClient } from "../clients";
import { metricsRoute } from "../../utils/APIRoutes";

/**
 * Récupère les données globales entre deux dates.
 * @param {Object} params - Les paramètres de la requête.
 * @param {string} params.from - La date de début.
 * @param {string} params.to - La date de fin.
 * @returns {Promise<Object>} Les données globales.
 * @throws {Error} Si la requête échoue.
 */
const fetchGlobals = async ({ from, to }) => {
	try {
		const params = new URLSearchParams({ from, to }).toString();
		const response = await apiClient.get(`${metricsRoute}?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les rangs.
 * @returns {Promise<Object>} Les données des rangs.
 * @throws {Error} Si la requête échoue.
 */
const fetchRanks = async () => {
	try {
		const response = await apiClient.get(`${metricsRoute}ranks`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les publications pour un entraînement spécifique entre deux dates.
 * @param {Object} params - Les paramètres de la requête.
 * @param {string} params.trainingId - L'ID de l'entraînement.
 * @param {string} params.from - La date de début.
 * @param {string} params.to - La date de fin.
 * @param {number} params.skip - Le nombre de publications à ignorer.
 * @returns {Promise<Object>} Les données des publications.
 * @throws {Error} Si la requête échoue.
 */
const fetchPublications = async ({ trainingId, from, to, skip }) => {
	try {
		const params = new URLSearchParams({ trainingId, from, to, skip }).toString();
		const response = await apiClient.get(`${metricsRoute}publications?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};


/**
 * Récupère les forces entre deux dates.
 * @param {Object} params - Les paramètres de la requête.
 * @param {string} params.from - La date de début.
 * @param {string} params.to - La date de fin.
 * @returns {Promise<Object>} Les données des forces.
 * @throws {Error} Si la requête échoue.
 */
const fetchStudents= async ({ from, to }) => {
	try {
		const params = new URLSearchParams({ from, to }).toString();
		const response = await apiClient.get(`${metricsRoute}students?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les forces entre deux dates.
 * @param {Object} params - Les paramètres de la requête.
 * @param {string} params.from - La date de début.
 * @param {string} params.to - La date de fin.
 * @returns {Promise<Object>} Les données des forces.
 * @throws {Error} Si la requête échoue.
 */
const fetchStrengths = async ({ from, to }) => {
	try {
		const params = new URLSearchParams({ from, to }).toString();
		const response = await apiClient.get(`${metricsRoute}strengths?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les éléments similaires d'un type spécifique.
 * @param {string} type - Le type d'éléments similaires à récupérer.
 * @returns {Promise<Object>} Les données des éléments similaires.
 * @throws {Error} Si la requête échoue.
 */
const fetchSimilars = async type => {
	try {
		const response = await apiClient.get(`${metricsRoute}similars/${type}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

const apiCalls = {
	fetchGlobals,
	fetchRanks,
	fetchPublications,
	fetchStrengths,
	fetchSimilars,
	fetchStudents
};

export default apiCalls;

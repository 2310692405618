import mockups from "../../../pages/Landing/icons/mockups.svg";
import { Link } from "react-router-dom";
import apple from "../../../pages/Landing/icons/App Store.webp";
import play from "../../../pages/Landing/icons/Play Store.webp";
import "./style.css";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Icon } from "../../Icons/Icon";

export const App = () => {
	const ref = useRef(null);

	const isInView = useInView(ref, { once: true });

	return (
		<section id={"section2"}>
			<h1>L'app Hopteo</h1>
			<p style={{ marginTop: 10 }}>
				Hopteo, c’est l’appli qui propose un classement personnalisé des formations
				<br /> pour les étudiants en fonction de centaines de critères
			</p>

			<div className={"mockups-container"} ref={ref}>
				<Component
					content={
						<>
							<Icon type={"filled"} name={"students"} size={50} />
							<h4>
								+<span>8k</span> étudiants
							</h4>
						</>
					}
				/>

				<Component
					content={
						<>
							<Icon type={"filled"} name={"viewsd"} size={50} />
							<h4>
								+<span>500k</span> pages ouvertes
								<br /> en 6 mois
							</h4>
						</>
					}
				/>

				<img src={mockups} alt={"Mockup Hopteo app"} id={"mockup-2"} />

				<Component
					content={
						<>
							<Icon type={"filled"} name={"profs"} size={50} />
							<h4>
								+<span>150</span> professeurs la
								<br /> recommandent
							</h4>
						</>
					}
				/>

				<Component
					content={
						<>
							<Icon type={"filled"} name={"star2"} size={50} />
							<h4>
								Notée <span>4,7/5</span>
								<br /> sur les stores
							</h4>
						</>
					}
				/>
			</div>
			<div className={"row2"} style={{ margin: "auto", marginTop: 40, gap: 30 }}>
				<Link
					to={
						"https://apps.apple.com/app/apple-store/id6447057343?pt=126116536&ct=Landing%20page%2027/06/2023&mt=8"
					}>
					<img src={apple} style={{ width: "clamp(130px,12vw,200px)" }} />
				</Link>
				<Link to={"https://play.google.com/store/apps/details?id=com.hopteo.hopteoApp"}>
					<img src={play} style={{ width: "clamp(130px,12vw,200px)" }} />
				</Link>
			</div>
			<Link
				to={"https://hopteo.com/"}
				className={"landing-btn register"}
				style={{ display: "block", margin: "auto", marginTop: 20 }}>
				En savoir plus sur l’app
			</Link>
		</section>
	);
};

const Component = ({ content }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { margin: "-80px", once: true });

	return (
		<div ref={ref}>
			<motion.div
				initial={{ opacity: 0, scale: 0 }}
				exit={{ opacity: 0, scale: 0 }}
				transition={{ ease: "easeOut", duration: 0.2 }}
				animate={isInView ? { scale: 1, opacity: 1 } : {}}>
				{content}
			</motion.div>
		</div>
	);
};

import { Images } from "../../../Images/Images";
import "./publication.css";
import React, { useState } from "react";
import trainingsService from "../../../../services/trainings/trainingsService";
import { Icon } from "../../../Icons/Icon";
import { PublicationModal } from "../../../Modals/PubliModal";
import { GraphLoader } from "../../../LoadingComponents/GraphLoader";
import { getImage } from "../../../../utils/APIRoutes";
import moment from "moment";
import { trackEvent2 } from "../../../../utils/ga";
import { useSelector } from "react-redux";

export const Publication = ({ publication, selectedTrainingData, onClick }) => {
	const { mutateAsync, isPending } = trainingsService.usePatch();
	const client = useSelector(state => state.client);

	const handleRemove = async e => {
		e.stopPropagation();
		try {
			await mutateAsync({
				trainingId: selectedTrainingData.trainingId,
				field: "publications",
				value: publication.filename
			});
		} catch (e) {
			console.log(e);
		}

		if (!client.admin) {
			trackEvent2("delete_event", "Publications", "delete", "", {
				structureId: client.structureId,
				acronym: client.acronym,
				trainingId: selectedTrainingData.trainingId
			});
		}
	};

	const [modal, setModal] = useState(false);

	const onCickMore = e => {
		e.stopPropagation();
		setModal(!modal);

		if (!client.admin) {
			trackEvent2("toggle_event", "Publications", modal ? "close" : "open", "options", {
				structureId: client.structureId,
				acronym: client.acronym,
				trainingId: selectedTrainingData.trainingId
			});
		}
	};

	return (
		<div className={"publication hover"} onClick={onClick}>
			<div className={"img-container"}>
				{isPending && <GraphLoader />}
				<Images
					alt={"training"}
					src={!publication.data ? getImage + publication.filename : publication.data}
				/>
			</div>
			<div className={"row hover"} style={{ alignItems: "center" }}>
				<div className={"row2"}>
					<span>{publication.views} vues</span> - <span>{publication.likes} j'aime</span>-{" "}
					<span>{moment(publication.createdAt).fromNow()}</span>
				</div>
				{modal && <PublicationModal onRemove={handleRemove} />}
				<Icon name={"more"} size={20} onClick={onCickMore} />
			</div>
		</div>
	);
};

import { configureStore } from "@reduxjs/toolkit";
import { clientSlice } from "./client";
import { appSlice } from "./app";
import { trainingsSlice } from "./trainings";
import { qnaSlice } from "./Q&A";
import { statsSlice } from "./metrics";
import { leadsSlice } from "./leads";
import { adminSlice } from "./admin";
import { avisSlice } from "./avis";

/**
 * Configuration du store Redux avec les différents slices de l'application.
 * @type {Object}
 * @property {Function} reducer - Combinaison des reducers des différents slices.
 */
export const store = configureStore({
	reducer: {
		client: clientSlice.reducer, // Reducer pour le slice client
		app: appSlice.reducer, // Reducer pour le slice app
		trainings: trainingsSlice.reducer, // Reducer pour le slice trainings
		qna: qnaSlice.reducer, // Reducer pour le slice Q&A
		stats: statsSlice.reducer, // Reducer pour le slice metrics
		leads: leadsSlice.reducer, // Reducer pour le slice leads
		admin: adminSlice.reducer, // Reducer pour le slice admin
		avis: avisSlice.reducer // Reducer pour le slice avis
	}
});

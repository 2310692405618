import { useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import { ArcElement, Chart } from "chart.js";
import { GraphLoader } from "../../../LoadingComponents/GraphLoader";
import { useSelector } from "react-redux";
import { NotPremium } from "../NotPremiums/Pie";

Chart.register(ArcElement);

export const colors = [
	"rgba(116, 51, 198, 1)",
	"rgba(255, 122, 122, 1)",
	"rgba(205, 147, 55, 1)",
	"rgba(205, 50, 110, 1)", // Existing colors
	"rgba(255, 87, 51, 1)",
	"rgba(51, 255, 87, 1)",
	"rgba(51, 87, 255, 1)",
	"rgba(255, 51, 166, 1)", // New colors
	"rgba(166, 51, 255, 1)",
	"rgba(51, 255, 166, 1)",
	"rgba(255, 166, 51, 1)",
	"rgba(51, 166, 255, 1)",
	"rgba(166, 255, 51, 1)",
	"rgba(255, 51, 201, 1)",
	"rgba(51, 201, 255, 1)",
	"rgba(255, 201, 51, 1)",
	"rgba(201, 51, 255, 1)",
	"rgba(51, 255, 201, 1)",
	"rgba(201, 255, 51, 1)",
	"rgba(255, 51, 79, 1)"
];

export const adjustOpacity = (color, alpha) => {
	return color.replace(/[\d\.]+\)$/g, `${alpha})`);
};

function truncateString(str, num) {
	if (!str) {
		return "";
	}
	if (str.length > num) {
		return str.slice(0, num) + "...";
	} else {
		return str;
	}
}

const PieChart = ({ metric, loading, handleTempo, title, isPremium = true }) => {
	const chartRef = useRef(null);
	const [refreshKey, setRefreshKey] = useState(0);
	const isDark = useSelector(state => state.app.isDark);

	const resetHoverState = () => {
		const chart = chartRef.current;
		if (chart) {
			chart.data.datasets[0].backgroundColor = colors; // Reset colors to original
			chart.update();
		}
	};

	const data = {
		labels: metric?.labels,
		datasets: [
			{
				label: metric?.datasets?.label,
				data: metric?.datasets[0]?.data,
				backgroundColor: colors,
				hoverOffset: 5,
				borderColor: colors,
				borderWidth: 2
			}
		]
	};

	const options = {
		responsive: true,
		animation: false,
		plugins: {
			title: {
				display: true,
				text: truncateString(title ?? metric?.datasets[0]?.label, 80),
				font: {
					size: 14,
					color: isDark ? "#ffffff" : "#000000"
				},
				padding: {
					bottom: 10
				},
				align: title ? "start" : "center"
			},
			legend: {
				position: "left",
				labels: {
					color: isDark ? "#ffffff" : "#000000",
					usePointStyle: true,
					padding: 10,
					boxWidth: 8,
					boxHeight: 8
				},
				onHover: (event, legendItem, legend) => {
					const chart = chartRef.current;
					if (chart) {
						chart.data.datasets[0].backgroundColor = chart.data.datasets[0].backgroundColor.map(
							(color, index) =>
								index === legendItem.index
									? adjustOpacity(color, 1)
									: adjustOpacity(color, 0.3)
						);
						chart.update();
					}
				},
				onLeave: resetHoverState
			},
			tooltip: {
				titleFont: {
					size: 14, // Taille du texte du titre
					fontWeight: "semibold"
				},
				bodyFont: {
					size: 14, // Taille du texte du corps
					fontWeight: "bold"
				},

				footerFont: {
					size: 13, // Taille du texte du pied de page
					fontWeight: "normal"
				},
				callbacks: {
					title: function (tooltipItems) {
						return "Détails";
					},
					label: function (tooltipItem) {
						if (tooltipItem.label === "Autres") {
							const lines = []; // Créer un tableau pour les lignes
							lines.push(`Autres :`);
							metric.othersDetails.forEach(item => {
								// Ajouter chaque détail comme une nouvelle ligne dans le tableau
								lines.push(`${item.label}`);
							});
							return lines; // Retourner le tableau, chaque élément sera une nouvelle ligne dans le tooltip
						}
						return tooltipItem.label; // Pour les autres cas, retourne simplement le label
					},
					footer: function (tooltipItems) {
						const tooltipItem = tooltipItems[0];
						const count = tooltipItem.raw;
						return `Pour un total de : ${count}`; // Formatage du footer
					}
				}
			}
		},
		onHover: (event, chartElement) => {
			if (chartElement.length) {
				const chart = chartRef.current;
				const index = chartElement[0].index;
				chart.data.datasets[0].backgroundColor = chart.data.datasets[0].backgroundColor.map(
					(color, i) => (i === index ? adjustOpacity(color, 1) : adjustOpacity(color, 0.3))
				);
				chart.update();
			}
		},
		maintainAspectRatio: false
	};

	useEffect(() => {
		const handleResize = () => setRefreshKey(prevKey => prevKey + 1);
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const activeNavBar = useSelector(state => state.app.activeNavBar);
	const refreshChart = () => {
		setRefreshKey(prevKey => prevKey + 1);
	};
	useEffect(() => {
		setTimeout(() => {
			refreshChart();
			chartRef?.current?.resize();
		}, 300);
	}, [activeNavBar]);

	return (
		<div
			className={`pie-chart graph ${isPremium ? "" : "locked-graph"}`}
			style={{ position: "relative" }}
			onMouseOut={resetHoverState}>
			{!isPremium ? (
				<NotPremium title={truncateString(title ?? metric?.datasets[0]?.label, 80)} />
			) : !loading && isPremium ? (
				<div style={{ height: 360, paddingBottom: 60 }}>
					<Pie ref={chartRef} className="piechart" data={data} options={options} key={refreshKey} />
					<p className={"row2"} onClick={handleTempo}>
						Afficher l’évolution au cours du temps ->
					</p>
				</div>
			) : (
				loading && <GraphLoader />
			)}
		</div>
	);
};

export default PieChart;

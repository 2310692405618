import React, { useEffect, useState } from "react";
import { FilterSelector } from "../components/Table/FilterSelector";
import { useDispatch, useSelector } from "react-redux";
import { avisMethods } from "../models/avis";
import avisService from "../services/avis/avisService";
import { CardAvis } from "../components/Admin/Avis/CardAvis";
import { CircularLoader } from "../components/LoadingComponents/CircularLoader";
import { Selector } from "../components/Client/Trainings/selector/Selector";

export const Avis = () => {
	const trainings = useSelector(state => state.trainings);
	const [selectedTraining, setSelectedTraining] = useState(
		trainings.content.length === 1 ? trainings.content[0]?.trainingId : undefined
	);

	useEffect(() => {
		if (trainings.content?.length > 0) {
			setSelectedTraining(
				trainings.content.length === 1 ? trainings.content[0]?.trainingId : undefined
			);
		}
	}, [trainings]);

	const filters = useSelector(state => state.avis.filters);
	const selected = useSelector(state => state.avis.selected);
	const dispatch = useDispatch();

	const onSelect = value => {
		dispatch(avisMethods.setSelected(value));
	};

	const {
		data: avisData,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	} = avisService.useFetchAvis(selectedTraining, selected);

	const formattedFilters = filters => {
		return filters.map(filter => {
			return {
				key: filter.key,
				value: filter.value + " (" + (avisData?.pages[0]?.total || 0) + ")"
			};
		});
	};

	// Fusionner les pages d'avis
	const allAvis = avisData?.pages?.flatMap(page => page.avis) || [];

	return (
		<div className="fragment" id={"fullpage"}>
			<div className="fragment_left stats" style={{ height: "100%" }}>
				{trainings.content?.length > 1 && (
					<div style={{ marginBottom: 10 }}>
						<Selector
							selectedTraining={selectedTraining}
							setSelectedTraining={setSelectedTraining}
							all={true}
						/>
					</div>
				)}
				<div style={{ paddingBottom: 50 }}>
					{isLoading ? (
						<div
							style={{
								display: "flex",
								width: "100%",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircularLoader width={50} height={50} />
						</div>
					) : (
						<>
							{filters && (
								<FilterSelector
									setFilter={onSelect}
									filters={formattedFilters(filters)}
									selected={selected}
								/>
							)}

							<div className={"avis-container"} style={{ marginTop: 8 }}>
								{allAvis.map((item, index) => {
									return <CardAvis avis={item} key={item._id} />;
								})}
							</div>

							{hasNextPage && (
								<button onClick={fetchNextPage} className={"btntab"}>
									{isFetchingNextPage ? (
										<div
											style={{
												width: "100%",
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}>
											<CircularLoader height={50} width={50} />
										</div>
									) : (
										"Voir plus"
									)}
								</button>
							)}

							{!isLoading && allAvis.length === 0 && <p>Pas d'avis pour le moment.</p>}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

import "./modal.css";
import { Icon } from "../Icons/Icon";

export const PublicationModal = ({ onRemove }) => {
	return (
		<div className={"hover modal"}>
			<div className={"row2"} onClick={e => onRemove(e)}>
				<Icon name={"trash"} size={15} type={"filled"} />
				<p>Supprimer</p>
			</div>
		</div>
	);
};

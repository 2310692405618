import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiCalls from "./apiCall";
import clientController from "./clientController";

/**
 * Service client pour gérer les opérations liées aux clients.
 */
const clientService = {
	/**
	 * Utilise la mutation pour la connexion.
	 * @returns {Mutation} La mutation de connexion.
	 */
	useLogin() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.login,
			onSuccess: async data => clientController.loginSuccess(dispatch, data),
			onError: async error => clientController.loginError(dispatch)
		});
	},

	/**
	 * Utilise la mutation pour récupérer les données du client.
	 * @returns {Mutation} La mutation de récupération.
	 */
	useFetch() {
		const dispatch = useDispatch();
		const navigate = useNavigate();

		return useQuery({
			queryKey: ["fetchClient"],
			queryFn: () => clientController.fetchClient(dispatch, navigate), // Appel de la fonction métier
			refetchOnWindowFocus: false,
			staleTime: Infinity
		});
	},

	/**
	 * Utilise la mutation pour mettre à jour les données du client.
	 * @returns {Mutation} La mutation de mise à jour.
	 */
	usePatch() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.patch,
			onSuccess: async data => clientController.patchSuccess(dispatch, data),
			onError: async error => clientController.patchError(error) // Gestion de l'erreur
		});
	},

	/**
	 * Utilise la mutation pour enregistrer un nouveau client.
	 * @returns {Mutation} La mutation d'enregistrement.
	 */
	useRegister() {
		return useMutation({
			mutationFn: apiCalls.register,
			onSuccess: async error => clientController.registerSuccess()
		});
	},

	/**
	 * Utilise la mutation pour changer le mot de passe du client.
	 * @returns {Mutation} La mutation de changement de mot de passe.
	 */
	useChangePassword() {
		return useMutation({
			mutationFn: apiCalls.changePassword,
			onSuccess: async () => clientController.changePasswordSuccess(),
			onError: async error => clientController.changePasswordError(error)
		});
	},

	/**
	 * Utilise la mutation pour contacter le support client.
	 * @returns {Mutation} La mutation de contact.
	 */
	useContact() {
		return useMutation({
			mutationFn: apiCalls.contact,
			onSuccess: async () => clientController.contactSuccess(),
			onError: async error => clientController.contactError(error)
		});
	},

	useResetRequest() {
		return useMutation({
			mutationFn: apiCalls.resetRequest,
			onSuccess: async () => clientController.resetRequestSuccess()
		});
	},

	useResetPassword() {
		const navigate = useNavigate();
		return useMutation({
			mutationFn: apiCalls.resetPassword,
			onSuccess: async () => clientController.resetPasswordSuccess(navigate)
		});
	}
};

export default clientService;

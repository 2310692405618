import routeConfig from "../../routeConfig";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

export const HelmetComponent = ({ location }) => {
	const logged = useSelector(state => state.client.logged);
	const pathname = logged && location.pathname === "/" ? "/2" : location.pathname;
	return (
		<Helmet>
			<title>{routeConfig[pathname]?.title}</title>
			<meta name="keywords" content={routeConfig[pathname]?.keywords} />
			<meta name="keywords" content={routeConfig[pathname]?.keywords} />
			<meta name="description" content={routeConfig[pathname]?.description} />
		</Helmet>
	);
};

import adminService from "../../../services/admin/adminService";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { adminMethods } from "../../../models/admin";
import { CircularLoader } from "../../LoadingComponents/CircularLoader";

export const StructuresByLeads = () => {
	const dispatch = useDispatch();

	const selectStructure = structure => {
		dispatch(adminMethods.setSelectedStructureLeads(structure));
	};
	const selectedStructure = useSelector(state => state.admin.leads.selectedStructure);

	const structuresByLeads = useSelector(state => state.admin.leads.structuresByLeads);
	const service = adminService.useStructuresByLeads();

	const fetchData = async () => {
		await service.mutateAsync({ skip: structuresByLeads.content.length });
	};

	useEffect(() => {
		if (structuresByLeads.loading) {
			fetchData();
		}
	}, [structuresByLeads.loading]);

	return (
		<div className={"structuresByLeads"}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: 10,
					borderBottom: "1px solid var(--font-color)",
					paddingBottom: 10
				}}>
				<h3>Structure</h3>
				<h3>Leads</h3>
			</div>

			{structuresByLeads?.content.map((structure, index) => {
				return (
					<div
						key={index}
						onClick={() => selectStructure(structure.structureId)}
						style={{
							display: "flex",
							justifyContent: "space-between",

							gap: 20,
							alignItems: "center",
							borderBottom: "1px solid rgba(0,0,0,0.3)",
							cursor: "pointer"
						}}
						className={`structureCell ${selectedStructure === structure.structureId ? "selected" : ""}`}>
						<h4>{structure.structureName}</h4>
						<div>{structure.leadCount}</div>
					</div>
				);
			})}
			{service.isPending ? (
				<CircularLoader height={30} width={30} />
			) : (
				<button
					style={{ width: "100%", marginTop: 20 }}
					disabled={service.isPending}
					onClick={() => fetchData()}>
					Voir plus
				</button>
			)}
		</div>
	);
};

import { createSlice } from "@reduxjs/toolkit";

/**
 * État initial pour le slice des formations
 * @type {Object}
 * @property {Array} content - Liste des formations
 * @property {Array} pending - Liste des formations en attente
 * @property {boolean} loaded - Indicateur de chargement des formations
 */
const initialState = {
	content: [],
	pending: [],
	loaded: false
};

/**
 * Création du slice pour les formations
 */
export const trainingsSlice = createSlice({
	name: "trainings", // Nom du slice
	initialState, // État initial
	reducers: {
		/**
		 * Définir les formations
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {Array} action.payload - Liste des formations
		 */
		setTrainings: (state, action) => {
			state.content = action.payload || [];
			state.loaded = true;
		},
		/**
		 * Mettre à jour un champ spécifique d'une formation
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.id - ID de la formation
		 * @param {string} action.payload.field - Champ à mettre à jour
		 * @param {any} action.payload.value - Nouvelle valeur du champ
		 */
		updateTrainingField: (state, action) => {
			const { id, field, value } = action.payload;
			const trainingIndex = state.content.findIndex(training => training.trainingId === id);
			if (trainingIndex !== -1) {
				if (field === "publications") {
					state.content[trainingIndex].publications = state.content[
						trainingIndex
					].publications.filter(publication => publication.filename !== value);
				} else {
					state.content[trainingIndex][field] = value;
				}
			}
		},
		/**
		 * Définir les publications pour une formation spécifique
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.id - ID de la formation
		 * @param {Array} action.payload.value - Liste des publications
		 */
		setPublications: (state, action) => {
			const { id, value } = action.payload;
			const trainingIndex = state.content.findIndex(training => training.trainingId === id);

			if (trainingIndex !== -1) {
				// Utilisation de immer pour manipuler l'état de manière immuable
				const training = state.content[trainingIndex];

				state.content[trainingIndex] = {
					...training,
					publications: value,
					publicationLoaded: true
				};
			} else {
				console.error(`Training avec l'id ${id} non trouvé.`);
			}
		},
		/**
		 * Ajouter une méthode de publication à une formation
		 * @param {Object} state - État actuel
		 * @param {Object} action - Charge utile de l'action
		 * @param {string} action.payload.id - ID de la formation
		 * @param {string} action.payload.publicationUrl - URL de la publication
		 */
		addPublicationMethod: (state, action) => {
			const { id, publicationUrl } = action.payload;
			const trainingIndex = state.content.findIndex(training => training.trainingId === id);
			if (trainingIndex !== -1) {
				const publications = state.content[trainingIndex].publications || [];
				state.content[trainingIndex].publications = [
					{
						filename: publicationUrl,
						data: null,
						views: 0,
						likes: 0,
						createdAt: new Date()
					},
					...publications
				];
			}
		}
	}
});

// Export des actions pour utilisation dans l'application
export const trainingsMethods = trainingsSlice.actions;

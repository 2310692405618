import "./style.css";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import mockup from "../../../pages/Landing/icons/mockup.svg";
import { Link } from "react-router-dom";

export const Advantages = () => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	return (
		<section id={"section3"}>
			<div className={"row2"} style={{ gap: 80 }} ref={ref}>
				<motion.h1
					initial={{ opacity: 0, x: -100 }}
					transition={{ ease: "easeOut", duration: 0.6 }}
					animate={isInView ? { opacity: 1, x: 0 } : {}}
					style={{ marginLeft: "5%" }}>
					Démarquez-vous des
					<br /> autres établissements
				</motion.h1>
				<motion.div
					initial={{ opacity: 0, x: "100%" }}
					transition={{ ease: "easeOut", duration: 0.6 }}
					animate={isInView ? { opacity: 1, x: 0 } : {}}
					className={"border"}></motion.div>
			</div>
			<div className={"row2 mockuped"}>
				<ul>
					<Component index={1} name={"Prenez le contrôle de votre page de profil"} />
					<Component index={2} name={"Ajoutez un lien vers votre site internet"} />
					<Component index={3} name={"Publiez des photos et des vidéos"} />
					<Component index={4} name={"Ajoutez les questions les plus fréquentes"} />
					<Component
						index={5}
						name={"Mettez en avant les avis d’étudiants vérifiés par notre équipe"}
					/>
				</ul>

				<img src={mockup} />
			</div>
			<Link
				to={"/register"}
				className={"landing-btn register"}
				style={{ display: "block", margin: "auto" }}>
				Demander un rendez-vous
			</Link>
		</section>
	);
};

const Component = ({ index, name }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { margin: "-5%", once: true });

	return (
		<div ref={ref}>
			<motion.li
				initial={{ opacity: 0, x: "-100%" }}
				exit={{ opacity: 0, x: "-100%" }}
				transition={{ ease: "easeOut", duration: 0.6 }}
				animate={isInView ? { opacity: 1, x: 0 } : {}}>
				<span>{index}</span>
				{name}
			</motion.li>
		</div>
	);
};
